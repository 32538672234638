import React, { useEffect, useState } from 'react'
import TotalTransaction from "../webx/transfers-list";
import { TransferList } from '../../services/srvc-transfers-realm';
import { GetUserForm } from '../../services/srvc-utilities';
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local';

export default function UserTransaction({type}) {

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true);
  
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);

      var res = await TransferList({
        data: {
          user: asset.item,

          index:currentPage,
          items: itemsPerPage,
        },
      });
      if (res) {
        setCurrentItems(res?.data?.list);
        setTotalItems(res?.data?.count);
        setLoader(false);
      }

    };
      fetchData();
  }, [currentPage]);



  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  return (
    <div
    className={
      type.text == "transactions"
        ? ""
        : "d-none"
    }
  >
    {currentItems.length == 0 && <div>No transactions found.....</div>}

    {!loader && currentItems.length > 0 &&  (
        <TotalTransaction
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          currentItems={currentItems}
          itemsPerPage={itemsPerPage}
          totalItem={totalItems}
        />
      )}
    </div>
  )
}

