import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const LineChart = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!data) return;

    const ctx = chartRef.current.getContext("2d");

    // Create the line chart
    const myChart = new Chart(chartRef.current, {
      type: "line",
      data: data,
      options: {
        maintainAspectRatio: false,
        responsive: true,
        elements: {
          line: {
            tension: 0.4,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          layout: {
            padding: {
              top: 20,
              bottom: 20,
              left: 20,
              right: 20,
            },
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
        },
      },
    });

    // Define gradients based on dataset status
    data.datasets.forEach((dataset) => {
      let gradient;
      switch (dataset.label) {
        case "Total":
          gradient = ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0, "rgba(187,227,254,0.5)"); // Start color (high)
          gradient.addColorStop(1, "rgba(187,227,254, 0.2)"); // End color (low)

          break;
        case "Failed":
          gradient = ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0, "rgba(210, 43,43, 0.5)");
          gradient.addColorStop(1, "rgba(210, 43, 43, 0.2)");
          break;
        case "Success":
          gradient = ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0, "rgba(0, 128, 0, 0.5)");
          gradient.addColorStop(1, "rgba(0, 128, 0, 0.2)");
          break;
        case "Pending":
          gradient = ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0, "rgba(255, 255, 0, 0.5)");
          gradient.addColorStop(1, "rgba(255, 255, 0, 0.2)");
          break;
        default:
          gradient = ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0, "rgba(0, 0, 0, 0)");
          gradient.addColorStop(1, "rgba(0, 0, 0, 0)");
      }

      // Apply the gradient to the dataset
      dataset.backgroundColor = gradient;
    });

    // Update the chart
    myChart.update();

    // Clean up on component unmount
    return () => {
      myChart.destroy();
    };
  }, [data]);

  return (
    <div className="rounded-xd border p-3" style={{ width: "100%", height: "100%" }}>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default LineChart;
