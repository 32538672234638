import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TotalUser from "../webx/network-user-list";
import { useDispatch, useSelector } from "react-redux";
import {
  ContactList,
  ContactSearchList,
  MemberSearchList,
  MembersList,
} from "../../services/srvc-creator-realm";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { updatePageNetwork } from "../actions/networkActions";

export default function NetworkUserListModule() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const dispatch = useDispatch();
  const networkSearchData = useSelector((state) => state.network);

  const {
    active,
    all,
    deleted,
    searchTerm,
    searchButton,
    userContact,
    teamContact,
  } = networkSearchData;
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const currentPage = useSelector((state) => parseInt(state.network.page, 10)); // Get currentPage from Redux store and parse it to integer
  const [currentItems, setCurrentItems] = useState([]);
  const [loader, setLoader] = useState(true);
  const [totalItems, setTotalItems] = useState("");

  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      var res = await (userContact == true ? ContactList : MembersList)({
        data: {
          team: asset.item,
          search: {
            status: active ? "active" : deleted ? "inactive" : "",
          },
          index: currentPage,
          items: itemsPerPage,
        },
      });
      if(res?.data?.list?.length==0){
        dispatch(updatePageNetwork(1)); // Dispatch action to update page
      }
      if (searchButton == false && res?.data?.list) {
        setCurrentItems(res?.data?.list);
        if (res?.data?.count > 0) {
          setTotalItems(res?.data?.count);
        }
      }

      if (res) {
        setLoader(false);
      }
    };

    if (!searchButton) {
      fetchData();
    }
  }, [
    currentPage,
    active,
    deleted,
    all,
    searchButton,
    userContact,
    teamContact,
  ]);

  useEffect(() => {
    setLoader(true);
    const fetchData = async () => {
      var res = await (userContact == true
        ? ContactSearchList
        : MemberSearchList)({
        data: {
          team: asset.item,
          search: {
            search: searchTerm,
            status: active ? "active" : deleted ? "inactive" : "",
          },
          index: currentPage,
          items: itemsPerPage,
        },
      });
      if(res?.data?.list?.length==0){
        dispatch(updatePageNetwork(1)); // Dispatch action to update page
      }
      if (res?.data) {
        setCurrentItems(res?.data?.list);
        if (res?.data?.count > 0) {
          setTotalItems(res?.data?.count);
        }
      }

      if (res) {
        setLoader(false);
      }
    };
    if (searchButton == true) {
      fetchData();
    }
  }, [searchButton, currentPage]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    dispatch(updatePageNetwork(page)); // Dispatch action to update page
  };
  useEffect(() => {
    navigate(`/team/network?page=${currentPage}`);
  }, [currentPage]);

  if (loader == true) {
    return <p>Please wait ....</p>;
  }
  if (!loader && currentItems.length == 0) {
    return <p>No items found</p>;
  }

  return (
    <div>
      {!loader && currentItems.length > 0 && (
        <TotalUser
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          currentItems={currentItems}
          itemsPerPage={itemsPerPage}
          totalItem={totalItems}
          userContact={userContact}
          teamContact={teamContact}
        />
      )}
    </div>
  );
}
