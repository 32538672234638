import React, { useEffect } from "react";
import Jazzicon from "react-jazzicon/dist/Jazzicon";
import { Link } from "react-router-dom";

const TotalUser = ({
  currentPage,
  totalPages,
  handlePageChange,
  currentItems,
  itemsPerPage,
  totalItem,
  userContact,
}) => {
  useEffect(() => {
    handlePageChange(currentPage);
  }, []);

  return (
    <>
      {currentItems && (
        <div className="border rounded  border-none mb-4">
          {currentItems.map((item, index) => (
            <div key={item.item}>
              <Link
                to={`/team/network/${userContact ? "contact" : "member"}/${
                  item?.user
                }`}
                className=" text-decoration-none"
              >
                <div className="bg-white p-lg-4 p-2">
                  <div className="d-flex gap-3">
                    <Jazzicon />
                    <div className="flex-grow-1">
                      <div className="d-flex justify-content-between align-items-center">
                        <p
                          className="m-0 text-primary"
                          style={{ fontWeight: "bold" }}
                        >
                          {item?.meta?.name || ""}
                        </p>
                        <div className=" top-0 end-0">
                          <div className="d-flex align-items-center gap-2">
                            <div className="d-flex align-items-center gap-2">
                              <p className="m-0">Status</p>
                              <div
                                className={`${
                                  item?.actv === true
                                    ? "bg-success"
                                    : item?.actv === false
                                    ? "bg-danger"
                                    : "bg-warning"
                                } rounded-circle`}
                                style={{
                                  width: "10px",
                                  height: "10px",
                                }}
                              ></div>
                            </div>
                            <i className="bx bx-chevron-right bx-sm"></i>
                          </div>
                        </div>
                      </div>
                      <p className="m-0 text-small">{item?.meta?.mail || ""}</p>
                    </div>
                  </div>
                </div>
              </Link>
              <hr className="m-0" />
            </div>
          ))}
        </div>
      )}
      <nav aria-label="Page navigation example">
        <ul
          className="pagination justify-content-between align-items-center
          "
        >
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <p
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              href="#"
              tabIndex="-1"
              aria-disabled="true"
            >
              <i className="bx bx-chevron-left"></i>
            </p>
          </li>

          <p className="m-0">
            {totalItem == 0
              ? totalItem
              : currentPage * itemsPerPage + 1 - itemsPerPage}{" "}
            -{" "}
            {currentPage * itemsPerPage > totalItem
              ? totalItem
              : currentPage * itemsPerPage}{" "}
            of {totalItem}
          </p>
          <li
            className={`page-item align-items-end ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <p
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              href="#"
            >
              <i className="bx bx-chevron-right"></i>
            </p>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default TotalUser;
