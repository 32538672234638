import React from "react";

export default function TransfersCreateBulkTransfer() {
  return (
    <div className="container">
      <div
        className="form-container"
        style={{
          margin: "50px auto",
          backgroundColor: " #ffffff",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: " 0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h4 className="text-center">Create Bulk Transfers</h4>
        <div className="form-group my-3">
          <label htmlFor="username">Username:</label>
          <input
            type="file"
            className="form-control mt-2"
            id="file"
            placeholder="Choose File"
            style={{ width: "100%" }}
          />
        </div>
       
        <button
          type="button"
          className="btn btn-primary rounded-xx"
         
        >
          Save
        </button>
      </div>
    </div>
  );
}
