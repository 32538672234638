// main
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xv";

import WebbIcon from "../content/webx/webb-icon";
import WebbHeader from "../content/webz/webb-header-navs-xv";
import UserAvatar from "../content/webx/user-avatar";

import WebbDividerMedium from "../content/webx/webb-divider-md";

import { GetUserForm } from "../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../services/srvc-auth-local";

import TransferUserDetail from "../content/transfers/transfer-detail";
import ContactUserDetail from "../content/network/network-contact-detail";
import NetworkActionsModule from "../content/network/network-actions";
import NetworkMemberActionsModule from "../content/network/network-member-actions";
import NetworkContactActionsModule from "../content/network/network-contact-actions";
import WebbIconBack from "../content/webx/webb-icon-back";
import WebbDateTimeMedium from "../content/webx/webb-datetime";

export default function ContactDetailView() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const navigate = useNavigate();

  const metadata = {
    name: "Contact Details",
    banner: {
      link: "https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900",
    },
  };

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: "xtra", show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`/${asset.role}/home`}>
                <WebbIcon
                  data={{ color: "text-color-main", size: "text-icon-sm" }}
                />
              </Link>
            </>
          ),
          data: (
            <>
              {" "}
              <WebbHeader />{" "}
            </>
          ),
          footer: (
            <>
              <UserAvatar />
            </>
          ),
          mobile: (
            <>
              <div className="cursor" onClick={() => navigate(-1)}>
                <WebbIconBack
                  data={{ color: "text-color-tone", size: "text-icon-md" }}
                />
              </div>
            </>
          ),
        }}
        sidebar={{
          header: (
            <>
              <p className="text-normal text-bold m-0">{metadata.name}</p>
            </>
          ),
          data: <></>,
          footer: <><WebbDateTimeMedium /></>,
        }}
        content={{
          header: (
            <>
              <div className="d-flex align-items-center">
                <div
                  className="cursor d-none d-md-block"
                  onClick={() => navigate(-1)}
                >
                  <WebbIconBack
                    data={{ color: "text-color-tone", size: "text-icon-md" }}
                  />
                </div>
                <p className="m-0 pb-1">Overview</p>
              </div>{" "}
            </>
          ),
          data: (
            <>
              <ContactUserDetail />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          ),
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: <>{<NetworkContactActionsModule />}</>,
          footer: <></>,
          mobileActions: (
            <>
              <NetworkContactActionsModule />
            </>
          ),
        }}
      ></ContentFormat>
    </>
  );
}
