// user account
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import { UserMedia } from "../webx/user-media";
import UserAccount from "./user-accounts";
import UserTeam from "./user-teams";
import UserTransaction from "./user-transactions";
import UserOverviews from "./user-overview";
import UserDocument from "./user-documents";
import { AccountsBaseBalance } from "../../services/srvc-accounts-realm";

export default function UserInfocardModule(props) {
  const { item } = useParams();
  const type = props.type;

  const navigate = useNavigate();
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [loader, setLoader] = useState(true)
 
  const [media, setMedia] = useState([]);

  const [documents, setDocuments] = useState([]);
  const [bal, setBal] = useState()
  
  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        var res = await AccountsBaseBalance({data:{user: asset.item}})
        console.log(res)
        if(res.stat) setBal(res?.data?.balance)
        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);



  return (
    <div className="my-2">
      <UserOverviews
        type={type}
        balance = {bal}
      />

      <UserAccount type={type} item={item} />

      <UserTransaction
        type={type}
      />
      <UserTeam type={type} />

      <UserDocument
        type={type}
        documents={documents}
        item={item}
      />
    </div>
  );
}
