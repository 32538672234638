import React from "react";
import { Link } from "react-router-dom";
export default function AssetsCard({ currentItems, currentPage,
  totalPages,
  handlePageChange,
  itemsPerPage,
  totalItem}) {
   
  return (
    <>
      {currentItems && (
      <div className="row row-cols-1 row-cols-md-3 g-2"
         >
          {currentItems.map((item, index) => (
            <Link to={`/team/assets/${item?.item ||""} `}>
              <div
                key={item.item}
                className="bg-white border rounded position-relative mb-3"
                style={{ marginBottom: "20px", height:"410px"}}
              >

                <div  style={{
                    width: "100%",
                    height: "200px",
                    borderTopLeftRadius: "0.375rem",
                    borderTopRightRadius: "0.375rem",
                    objectFit:"cover"
                  }}>
                <img
                  src={item?.media?.link ||""}
                  className="border img-fluid"
                  style={{
                    width: "100%",
                    height: "200px",
                    borderTopLeftRadius: "0.375rem",
                    borderTopRightRadius: "0.375rem",
                    objectFit:"cover"
                  }}
                  alt="img"
                />
                </div>
                <p className="px-4 m-0 mt-4"  
                 style={{ display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  WebkitLineClamp: 1, }}
                >{item?.creator?.name||""}</p>
                <p
                  className="px-4 fw-bold"
                  style={{ fontSize: "20px", color: "slateblue",height:"30px",display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  WebkitLineClamp: 2, }}
                >
                  {item?.meta?.name||"No data found...."}
                </p>
                <p
                  className="px-4 m-0 mb-2"
                  style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    WebkitLineClamp: 2,
                    height:"45px"
                  }}
                >
                  {item?.meta?.memo||"No data found...."}
                </p>
                <p className="px-4" style={{ marginBottom: "60px" }}>
                  {" "}
                  {item?.active?"":"COMING SOON!"}
                  
                </p>
                <div className="d-flex justify-content-between position-absolute bottom-0 w-100 mt-4 mb-3  p-4 pb-0">
                  <div className="d-flex gap-4 ">
                    {" "}
                    <div className="d-flex gap-2 align-items-center">
                      <i className="bx bxs-like"></i>
                      <p className="m-0">{item?.webb?.like||"0"}</p>
                    </div>
                    <div className="d-flex gap-2 align-items-center">
                      <i className="bx bx-low-vision"></i>
                      <p className="m-0">{item?.webb?.view||"0"}</p>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex gap-2 align-items-center">
                      <i className="bx bxs-user"></i>
                      <p className="m-0">{item?.webb?.share||"0"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
        <nav aria-label="Page navigation example">
        <ul
          className="pagination justify-content-between align-items-center
          "
        >
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <p
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              href="#"
              tabIndex="-1"
              aria-disabled="true"
            >
              <i className="bx bx-chevron-left"></i>
            </p>
          </li>

          <p className="m-0">
            {totalItem == 0
              ? totalItem
              : currentPage * itemsPerPage + 1 - itemsPerPage}{" "}
            -{" "}
            {currentPage * itemsPerPage > totalItem
              ? totalItem
              : currentPage * itemsPerPage}{" "}
            of {totalItem}
          </p>
          <li
            className={`page-item align-items-end ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <p
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              href="#"
            >
              <i className="bx bx-chevron-right"></i>
            </p>
          </li>
        </ul>
      </nav>
    </>
  );
}
