// main
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xv";

import WebbIcon from "../content/webx/webb-icon";
import WebbHeader from "../content/webz/webb-header-navs-xv";
import UserAvatar from "../content/webx/user-avatar";

import WebbDividerMedium from "../content/webx/webb-divider-md";

import { GetUserForm } from "../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../services/srvc-auth-local";

import UserActionsModule from "../content/user/user-actions";
import NetworkUserDetail from "../content/network/network-detail";
import UserActionModule from "../content/network/network-details-action";
import { useState } from "react";
import NetworkOptionModule from "../content/network/network-options";

export default function NetworkDetailView() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [ refresh, setRefresh] = useState(false)
  const [detailselected, setDetailSelected] = useState(
    { 
      "name": "Over View",
      "text": "overview", 
      "icon": "bx bxs-user-detail",
      "avtr": "",
      "link": "network/overview",
      "user": ["user", "team"],
      "live": true,
      "actv": true
    }
  )

  const metadata = {
    name: "Network Details",
    banner: {
      link: "https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900",
    },
  };

  const refreshPage = async()=>{
    setRefresh(!refresh)
  }

  const handleDetailSelected = async(item)=>{
    setDetailSelected(item)
  }

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: "xtra", show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`/${asset.role}/home`}>
                <WebbIcon
                  data={{ color: "text-color-main", size: "text-icon-sm" }}
                />
              </Link>
            </>
          ),
          data: (
            <>
              {" "}
              <WebbHeader />{" "}
            </>
          ),
          footer: (
            <>
              <UserAvatar />
            </>
          ),
        }}
        sidebar={{
          header: (
            <>
              <p className="text-normal text-bold m-0">{metadata.name}</p>
              <WebbDividerMedium />
              <NetworkOptionModule handleSelect= {handleDetailSelected} />
            </>
          ),
          data: <></>,
          footer: <>...</>,
        }}
        content={{
          header: (
            <>
              <p className="m-0">Overview</p>
            </>
          ),
          data: (
            <>
              <NetworkUserDetail type = {detailselected} refresh={refresh} />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          ),
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: <>
            <UserActionModule handleRefresh = {refreshPage} /> 
          </>,
          footer: <></>,
        }}
      ></ContentFormat>
    </>
  );
}
