import React from 'react'

export default function UserContacts({ type, contact }) {
  return (
    <div className={type.text === "contacts" ? "" : "d-none"}>
      <h5>Contacts</h5>
      <div className={contact.length === 0 ? "" : "d-none"}>No Contacts Found</div>
    </div>
  )
}

