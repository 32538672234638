// routes
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/authcontext";

// views - main
import Main from "../views.xz/main-xz";

// views - home
import HomeUser from "../views.home/home-user";
import HomeTeam from "../views.home/home-team";

// views - assets
import Assets from "../views.assets/assets";

// views - transfers
import Transfers from "../views.transfers/transfers";
import TransfersDetail from "../views.transfers/transfers-detail";

// views - network
import Network from "../views.network/network";
import NetworkAddTeams from "../views.network/network-add-team"
// views - user
import UserDetails from "../views.user/user-details";
import TeamDetails from "../views.user/team-details";

// views - onboard
import UserOnboardName from "../views.onboard/user-onboard-name";

// views - auth
import AuthMailCode from "../views.auth/auth-mail-code";
import AuthNext from "../views.auth/auth-next";
import AuthSessionX from "../views.auth/auth-session-x";
import NetworkDetailView from "../views.network/network-details";
import NetworkAddView from "../views.network/network-add-members";
import NetworkAddUsers from "../views.network/network-add-users";
import AssetAddView from "../views.assets/assets-create-assest";
import NetworkAddMembers from "../views.network/network-add-members";
import TransfersAddTransfer from "../views.transfers/transfers-create-transfer";
import TransfersCreateBulkTransfers from "../views.transfers/transfers-create-bulk";
import NetworkAddBulkUsers from "../views.network/network-add-bulk-user"
import AccountList from "../views.account/accounts";
import TransfersSatement from "../views.transfers/transfers-statement";
import AssetDetailView from "../views.assets/asset-detail";
import ContractsView from "../views.contracts/contracts";
import ContractsSendView from "../views.contracts/contracts-send";
import ContractsInfoView from "../views.contracts/contracts-info";
import ContractsEditMembersView from "../views.contracts/contracts-edit-mmbr";
import ContractsEditFileView from "../views.contracts/contracts-edit-file";
import ContractDetails from "../views.contracts/contracts-details";
import ContractsAddInputView from "../views.contracts/contracts-add-input";
import AssetTransactioinDetailView from "../views.assets/assets-transaction-detail";
import AssetsTransfersSatement from "../views.transfers/views-statement";
import AssetsUnitsCreate from "../views.assets/assets-create-units";
import AssetsAddMedia from "../views.assets/asset-add-media";
import AssetsAddDocuments from "../views.assets/asset-add-documents";
import NetworkAddCreators from "../views.network/network-add-team";
import TeamOnboard from "../views.onboard/team-onboard-name";
import NetworkAddContacts from "../views.network/network-add-contact";
import ContactDetailView from "../views.network/network-contact-detail";
import MemberDetailView from "../views.network/network-member-detail";
import UserOnboardHold from "../views.onboard/user-onboard-hold";
import NetworkAddBulkContact from "../views.network/network-add-bulk-user";
import TransfersAccountCredit from "../views.transfers/transfers-account-credit";
import TransfersAccountDebit from "../views.transfers/transfers-account-debit";
import UnitDetailView from "../views.assets/asset-unit-detail";
import ContractAddFiles from "../views.contracts/contracts-add-file";
import Rooms from "../views.rooms/rooms";
const routes = [
  { route: "/", content: <Main />, auth: false },

  // user
  { route: "/user/home", content: <HomeUser />, auth: false },

  // home
  { route: "/team/home", content: <HomeTeam />, auth: true },

  // assets
  { route: "/team/assets", content: <Assets />, auth: false },
  {
    route: "/team/assets/:item",
    content: <AssetDetailView />,
    auth: false,
  }
,  
  { route: "/team/assets/create", content: <AssetAddView />, auth: false },
  { route: "/team/onboard", content: <TeamOnboard />, auth: false },

  { route: "/team/assets/transfers/:item",content: <AssetTransactioinDetailView />,auth: false},
  { route: "team/assets/transfers/statement/:item",content: <AssetsTransfersSatement />,auth: false},
  { route: "team/create/unit/:item",content: <AssetsUnitsCreate />,auth: false},
  { route: "team/upload/media/:item",content: <AssetsAddMedia />,auth: false},
  { route: "team/upload/documents/:item",content: <AssetsAddDocuments />,auth: false},
  { route: "team/assets/unit/:item",content: <UnitDetailView/>,auth: false},




  // network
  { route: "/team/network", content: <Network />, auth: false },
  {
    route: "/team/network/:accounttype/:item",
    content: <NetworkDetailView />,
    auth: false,
  }
,  
  { route: "/team/network/adduser", content: <NetworkAddUsers />, auth: false },
  { route: "/team/network/addcontact", content: <NetworkAddContacts/>, auth: false },
  { route: "/team/network/contact/:item",content: <ContactDetailView />,auth: false},
  { route: "/team/network/member/:item",content: <MemberDetailView />,auth: false},

  
  { route: "/team/network/add/bulkcontact", content: <NetworkAddBulkContact/> , auth: false },

  { route: "/team/members/add", content: <NetworkAddMembers />, auth: false },
  { route: "team/network/addcreator", content: <NetworkAddCreators/>  , auth: false },
  

  // transfers
  { route: "/team/transfers", content: <Transfers />, auth: false },
  {route: "/team/transfers/create",content: <TransfersAddTransfer />,auth: false},
  {route: "/team/transfers/create",content: <TransfersAddTransfer />,auth: false},
  {route: "/team/transfers/account/credit",content: <TransfersAccountCredit />,auth: false},
  {route: "/team/transfers/account/debit",content: <TransfersAccountDebit />,auth: false},
  
  
  { route: "/team/transfers/create/bulk", content: <TransfersCreateBulkTransfers />, auth: false,},
  { route: "/team/transfers/:item",content: <TransfersDetail />,auth: false},
  { route: "/team/transfers/statement",content: <TransfersSatement />,auth: false},
  
  //Contracts
  { route: '/team/contracts', content: <ContractsView />, auth: false },
  { route: '/team/contracts/new/info', content: <ContractsInfoView/>, auth: false },
  { route: '/team/contracts/details/:id', content: <ContractDetails />, auth: false },
  { route: '/team/contracts/edit/mmbr/:id', content: <ContractsEditMembersView />, auth: false },
  { route: '/team/contracts/edit/file/:id', content: <ContractsEditFileView />, auth: false },
  { route: '/team/contracts/input/fields/:id/:tmid', content: <ContractsAddInputView />, auth: false },
  { route: '/team/contracts/send/:id', content: <ContractsSendView />, auth: false },
  { route: '/team/contracts/add/file/:id', content: <ContractAddFiles />, auth: false },

   //Rooms
   { route: "/team/rooms", content: <Rooms />, auth: false },
  
  
  // accounts
  { route: "/team/accounts/list", content: <AccountList />, auth: false },

  // user
  { route: "/user/account", content: <UserDetails />, auth: false },
  { route: "/team/account", content: <TeamDetails />, auth: false },

  // documents

  // onboard
  { route: "/user/onboard", content: <UserOnboardName />, auth: false },
  { route: "/user/onboard/hold", content: <UserOnboardHold />, auth: false },


  // auth
  { route: "/auth", content: <AuthMailCode />, auth: false },
  { route: "/auth/next", content: <AuthNext />, auth: false },
  { route: "/auth/x", content: <AuthSessionX />, auth: false },
];

export default function RouteX() {
  const { user } = useAuth();

  return (
    <Routes>
      {routes.map((item, i) =>
        item.auth ? (
          <Route
            key={i}
            path={item.route}
            element={!user ? <Navigate to="/" replace /> : item.content}
          />
        ) : (
          <Route key={i} path={item.route} element={item.content} />
        )
      )}
    </Routes>
  );
}
