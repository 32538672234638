

import React from "react";

export function UserChats({ type, chats }) {
  return (
    <div className={type.text == "chats" ? "" : "d-none"}>
          <h5>Conversation</h5>
          <div className={chats.length==0 ? "" :"d-none"}>No Chats Found</div>
      </div>
  );
}
