// import { GetRoomNotification } from "@/services/srvc-realm-watch";
// import React, { useEffect, useState } from "react";
// import { useRouter } from "next/router";
// import styles from "@/content/chathistory/history.module.css";

// const Chathistory = (props) => {
//   const { dismissOffcanvas, setShow } = props;
//   const [asset, setAsset] = useState();
//   const [roomlist, setRoomList] = useState([]);
//   const [refresh, setRefresh] = useState(false);
//   const [selected, setSelected] = useState(props?.room || null);

//   const router = useRouter();
//   const { pathname } = router;

// // console.log("PPPPPPPPPPP",pathname)
//   useEffect(() => {
//     const user = JSON.parse(localStorage.getItem("indx"));
//     // console.log(asset)
//     const team = JSON.parse(localStorage.getItem("bznx"));
//     setAsset(user ? user : team);
//     if (user == null && team == null) {
//       router.push("/auth");
//     }
//   }, []);

//   useEffect(() => {
//     if (asset) {
//       const fetchdata = async () => {
//         const res = await fetch("/api/user-chatroom-list", {
//           body: JSON.stringify({
//             data: { user: asset?.item || "" },
//             srvc: "******",
//           }),
//           headers: { "Content-Type": "application/json" },
//           method: "POST",
//         });
//         var result = JSON.parse(await res.json());
//         // console.log(result)
//         if (result.stat) {
//           setRoomList(result.data.list);
//         }
//       };

//       fetchdata();
//     }
//   }, [asset, refresh]);

//   useEffect(() => {
//     const fetchupdate = async () => {
//       var res = await GetRoomNotification();
//       // console.log(res)
//       setRefresh(!refresh);
//       props.handleRefresh(!refresh);
//     };
//     fetchupdate();
//   }, [refresh]);

//   const handleRoomSelection = async (item) => {
//     setSelected(item);
//     props.selectroom(item);
//   };
//   console.log("SetSelected:-",selected)

//   const ResetRoom = () => {
//     props.handleRoomReset();
//       setShow(true);
//     // router.push("/user/room");
//   };

//   return (
//     <div className="mt-1">
//       {/* <h3 className="text-sm mb-2" style={{ fontSize: "15px" }}>
//         Chat History
//       </h3> */}
//       {roomlist.length > 0 ? (
//         ""
//       ) : (
//         <>
//           <button
//             onClick={() => ResetRoom()}
//             type="button"
//             className="rounded-md border w-100 border-none bg-white d-flex justify-content-start align-items-center cursor"
//             {...(dismissOffcanvas ? { "data-bs-dismiss": "offcanvas" } : {})}
//             style={{ backgroundColor: "#f5f5f5", outline: "none" }}
//           >
//             <i
//               className="bx bx-plus"
//               style={{ fontSize: "20px", marginRight: "5px" }}
//             ></i>
//             New Chat
//           </button>
//           <hr />
//         </>
//       )}
//       {roomlist &&
//         roomlist.length > 0 &&
//         roomlist.map((item, index) => {
//           return (
//             <div
//               key={index}
//               onClick={() => handleRoomSelection(item)}
//               className="hilite rounded cursor p-2 d-flex align-items-center justify-content-between"
//               {...(dismissOffcanvas ? { "data-bs-dismiss": "offcanvas" } : {})}
//             >
//               <div className="d-flex align-items-center">
//                 <i
//                   className="bx bx-comment me-1 mt-1"
//                   style={{ fontSize: "16px", color: "gray" }}
//                 />
//                 <span
//                   style={{
//                     overflow: "hidden",
//                     whiteSpace: "nowrap",
//                     textOverflow: "ellipsis",
//                     // maxWidth: "190px",
//                   }}
//                   className={`me-2 ${styles.textWidth}`}
//                 >
//                   {item.lastMessage}
//                 </span>
//               </div>
//             </div>
//           );
//         })}
//     </div>
//   );
// };

// export default Chathistory;

// import { GetRoomNotification } from "../../services/srvc-realm-watch";
// import React, { useEffect, useState } from "react";
// // import { useRouter } from "next/router";
// import { useNavigate } from "react-router-dom";
// // import styles from "@/content/chathistory/history.module.css"

// const Chathistory = (props) => {
//   const { dismissOffcanvas, setShow } = props;
//   const [asset, setAsset] = useState();
//   const [roomlist, setRoomList] = useState([]);
//   const [refresh, setRefresh] = useState(false);
//   const [selected, setSelected] = useState(props?.room || null);
//   const [loading, setLoading] = useState(true); // Add loading state

//   const router = useNavigate();
//   const { pathname } = router;

//   useEffect(() => {
//     const user = JSON.parse(localStorage.getItem("indx"));
//     const team = JSON.parse(localStorage.getItem("bznx"));
//     setAsset(user ? user : team);
//     if (user == null && team == null) {
//       router("/auth");
//     }
//   }, []);

//   useEffect(() => {
//     if (asset) {
//       const fetchdata = async () => {
//         setLoading(true); // Set loading to true before fetching data
//         const res = await fetch("api/user-chatroom-list", {
//           body: JSON.stringify({
//             data: { user: asset?.item || "" },
//             srvc: "******",
//           }),
//           headers: { "Content-Type": "application/json" },
//           method: "POST",
//         });
//         console.log("Result chat list", res)
//         var result = JSON.parse(await res.json());
//         // console.log(result)
//         if (result.stat) {
//           setRoomList(result.data.list);
//         }
//         setLoading(false); // Set loading to false after data is fetched
//       };

//       fetchdata();
//     }
//   }, [asset, refresh]);

//   useEffect(() => {
//     const fetchupdate = async () => {
//       var res = await GetRoomNotification();
//       setRefresh(!refresh);
//       props.handleRefresh(!refresh);
//     };
//     fetchupdate();
//   }, [refresh]);

//   const handleRoomSelection = async (item) => {
//     setSelected(item);
//     props.selectroom(item);
//   };

//   const ResetRoom = () => {
//     props.handleRoomReset();
//     setShow(true);
//   };

//   return (
//     <div className="mt-1">
//       {loading ? (
//         <div>Loading...</div> // Display loader when loading
//       ) : roomlist.length === 0 ? (
//         <>
//           <button
//             onClick={() => ResetRoom()}
//             type="button"
//             className="rounded-md border w-100 border-none bg-white d-flex justify-content-start align-items-center cursor"
//             {...(dismissOffcanvas ? { "data-bs-dismiss": "offcanvas" } : {})}
//             style={{ backgroundColor: "#f5f5f5", outline: "none" }}
//           >
//             <i
//               className="bx bx-plus"
//               style={{ fontSize: "20px", marginRight: "5px" }}
//             ></i>
//             New Chat
//           </button>
//           <hr />
//         </>
//       ) : (
//         roomlist.map((item, index) => (
//           <div
//             key={index}
//             onClick={() => handleRoomSelection(item)}
//             className="hilite rounded cursor p-2 d-flex align-items-center justify-content-between"
//             {...(dismissOffcanvas ? { "data-bs-dismiss": "offcanvas" } : {})}
//           >
//             <div className="d-flex align-items-center">
//               <i
//                 className="bx bx-comment me-1 mt-1"
//                 style={{ fontSize: "16px", color: "gray" }}
//               />
//               <span
//                 style={{
//                   overflow: "hidden",
//                   whiteSpace: "nowrap",
//                   textOverflow: "ellipsis",
//                 }}
//                 // className={`me-2 ${styles.textWidth}`}
//               >
//                 {item.lastMessage}
//               </span>
//             </div>
//           </div>
//         ))
//       )}
//     </div>
//   );
// };

// export default Chathistory;

// --------------------------------------------------------------------------------

// -----------------------------------
import {GetLiveMessages} from "../../services/srvc-realm-watch"
import { GetRoomNotification } from "../../services/srvc-realm-watch";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchRoomList } from "../../services/srvc-chat-realm";
import Jazzicon from "react-jazzicon/dist/Jazzicon";
import './list.css'

const ChatList = (props) => {
  const { dismissOffcanvas, setShow } = props;
  const [asset, setAsset] = useState();
  const [roomlist, setRoomList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState(props?.room || null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("indx"));
    const team = JSON.parse(localStorage.getItem("bznx"));
    setAsset(user || team);
    if (!user && !team) {
      navigate("/auth");
    }
  }, [navigate]);

  useEffect(() => {
    if (asset) {
      const fetchdata = async () => {
        setLoading(true);
        try {
          const result = await fetchRoomList({
            data: { user: asset?.item || "" },
          });
          // console.log("RESULT---290", result);
          if (result.stat) {
            setRoomList(result.data.list);
          } else {
            console.error("API returned an error:", result.error);
          }
        } catch (error) {
          console.error("Error fetching message list:", error);
        }
        setLoading(false);
      };

      fetchdata();
    }
  }, [asset, refresh]);

  useEffect(() => {
    const fetchupdate = async () => {
      try {
        await GetRoomNotification();
        setRefresh(!refresh);
        // props.handleRefresh(!refresh);
      } catch (error) {
        console.error("Error fetching room notification:", error);
      }
    };
    fetchupdate();
  }, [refresh, props.refresh, selected]);

  const handleRoomSelection = async (item) => {
    setSelected(item);
    props.selectroom(item);
  };

  const ResetRoom = () => {
    props.handleRoomReset();
    setShow(true);
  };

  return (
    <div className="mt-1">
      {loading ? (
        <div>Loading...</div>
      ) : roomlist.length === 0 ? (
        <>
          <button
            onClick={() => ResetRoom()}
            type="button"
            className="rounded-md border w-100 border-none bg-white d-flex justify-content-start align-items-center cursor"
            {...(dismissOffcanvas ? { "data-bs-dismiss": "offcanvas" } : {})}
            style={{ backgroundColor: "#f5f5f5", outline: "none" }}
          >
            <i
              className="bx bx-plus"
              style={{ fontSize: "20px", marginRight: "5px" }}
            ></i>
            New Chat
          </button>
          <hr />
        </>
      ) : (
        roomlist.map((item, index) => (
          <>
            <div
              key={index}
              onClick={() => handleRoomSelection(item)}
              className="hilite rounded cursor d-flex align-items-center justify-content-between mb-1 py-1"
              {...(dismissOffcanvas ? { "data-bs-dismiss": "offcanvas" } : {})}
            >
              <div className="d-md-flex align-items-center justify-content-between d-none w-100">
                <div className=" d-flex align-items-center">
                  <Jazzicon diameter={35} />
                  <div className="d-flex flex-column gap-0">
                    <span
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        fontSize: "13px",
                      }}
                      className={`mx-2 textWidth text-bold`}
                    >
                      {item.name}
                    </span>
                    <span
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        // fontSize: "16px",
                      }}
                      className={`mx-2 text-mini textWidth`}
                    >
                      {item.lastMessage}
                    </span>
                  </div>
                </div>
                <span
                  className="d-flex justify-content-center text-mini align-items-center text-white rounded-circle"
                  style={{
                    height: "18px",
                    width: "18px",
                    backgroundColor: "#5FD2D7",
                  }}
                >
                  2
                </span>
              </div>
              <div className="d-md-flex align-items-center justify-content-between d-md-none w-100">
                <div className=" d-flex align-items-center">
                  <Jazzicon diameter={35} />
                  <div className="d-flex flex-column gap-0">
                    <span
                      // style={{
                      //   overflow: "hidden",
                      //   whiteSpace: "nowrap",
                      //   textOverflow: "ellipsis",
                      //   fontSize: "13px",
                      // }}
                      className={`mx-2 text-bold`}
                    >
                      {item.name}
                    </span>
                    <span
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        // fontSize: "16px",
                      }}
                      className={`mx-2 text-mini textWidth`}
                    >
                      {item.lastMessage}
                    </span>
                  </div>
                </div>
                {/* <span
                  className="d-flex justify-content-center text-mini align-items-center text-white rounded-circle"
                  style={{
                    height: "18px",
                    width: "18px",
                    backgroundColor: "#25d366",
                  }}
                >
                  2
                </span> */}
              </div>
            </div>
            <hr
              className={`my-2 ${
                index === roomlist.length - 1 ? "d-none" : ""
              }`}
            />
          </>
        ))
      )}
    </div>
  );
};

export default ChatList;
