// action select

export default function IconSelectCard(props) {

    const data = props.data;
    const sort = props.sort;

    // console.log(data, sort)
  
    const handleCallback = () => {
      props.item(data.code);
    }
  
    return (
    <>
        <div 
          className={` ${sort===data.code ? 'bg-warning': 'bg-white hilite'} `}
          style={{cursor:'pointer'}}
          onClick={()=> handleCallback()} >
            
          <div className={`d-flex border p-3 mb-3 ${data.actv?'':'text-muted'}`}
            style={{pointerEvents:'all'}}
          >
            <div className="p-1 text-center rounded-circle back-color-lite" style={{height:'2.4rem', width:'2.4rem'}}>
              <i className={`m-0 text-color-dark ${data.icon}`}  
                  style={{fontSize:"1.35rem"}}>
              </i>
            </div>
  
            <div className="ps-3 pe-3">
              <p className="text-color-main m-0 p-0">{data.name}</p>
              <p className={`small text-dark m-0 p-0 ${data.actv?'':'text-muted'}`}>{data.text}</p>
            </div>
  
            <div className="ms-auto py-2">
              {/* <i className={`m-0 p-0 text-tone bi-chevron-right`}></i> */}
            </div>
          </div>
        </div>
  
    </>
    )
  }