import React from "react";
import { useState, useEffect } from "react";
import { UserDocumentsList } from "../../services/srvc-transfers-realm";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";

export default function UserDocument({ type }) {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const [loader, setLoader] = useState(true);
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);

      var res = await UserDocumentsList({
        data: {
          user: asset.item,
        },
      });
      setCurrentItems(res?.data?.list);
      setLoader(false);
    };
    fetchData();
  }, []);

  return (
    <div className={type.text === "documents" ? "" : "d-none"}>
      <div className={currentItems.length > 0 ? "" : "d-none"}>
        <div className="border rounded  border-none mb-4">
          {currentItems.map((item, index) =>
            item?.file?.length == 0 ? (
              " "
            ) : (
              <div key={item?.item}>
                <a
                  href={item?.file?.link || ""}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="d-flex justify-content-between bg-white p-4 ">
                    <div className="d-flex gap-3">
                      <div>
                        <p
                          className="m-0 text-primary "
                          style={{ fontWeight: "bold" }}
                        >
                          {item?.meta?.name || ""}
                        </p>
                        {item?.file?.link
                          ? new Date(item.created).toLocaleString()
                          : ""}
                        {item?.file?.link ? (
                          " "
                        ) : (
                          <p className="text-danger m-0">
                            Document not uploaded
                          </p>
                        )}
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center
              gap-2"
                    >
                      <div className=" d-flex  align-items-center gap-2">
                        {item?.file?.link ? (
                          <>
                            {" "}
                            <p className="m-0">
                              {" "}
                              {item?.status === true
                                ? "Verified"
                                : "Not-Verified"}
                            </p>
                            <div
                              className={`${
                                item?.status === true
                                  ? "bg-success"
                                  : "bg-danger"
                              }`}
                              style={{
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                              }}
                            ></div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <i className="bx bx-chevron-right bx-sm"></i>
                    </div>
                  </div>
                </a>

                <hr className="m-0" />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}
