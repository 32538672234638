// content
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import WebbDividerSmall from "../webx/webb-divider-sm";
import {
  ContactStatusGet,
  ContactStatusSet,
  CreatorRemoveContact,
  CreatorRemoveMember,
} from "../../services/srvc-creator-realm";

const listactions =
  require("../../data.static/data-network-contact-actions.json").data;

export default function NetworkContactActionsModule() {
  const navigate = useNavigate();

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const { item } = useParams();
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState();

  const [status, SetStatus] = useState();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedAssetToDelete, setSelectedAssetToDelete] = useState(null);

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const result = listactions.filter((x) => x.user.includes(asset.role));
        setData(result);

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      var res = await ContactStatusGet({
        data: {
          team: asset.item,
          user: item,
        },
      });
      console.log(res, "res");
      if (res) {
        SetStatus(res.data.status);
      }
    };
    fetchData();
  }, []);

  const SetData = async (status) => {
    var res = await ContactStatusSet({
      data: {
        team: asset.item,
        user: item,
        status:status?"activate":"deactivate"
      },
    });
  };


  const deleteData = async () => {
    var res = await CreatorRemoveContact({
      data: {
        team: asset.item,
        user: item,
      },
      srvc: "******",
    });
    if (res.stat) {
      alert(`${res.memo}`);
      navigate(`/team/network`);
    }

    setLoader(false);
    setShowDeletePopup(false);
  };

  const handleClick = (items) => {
    // props.handleSelect(item);
    if (items.name == "Remove Contact") {
      setSelectedAssetToDelete(item); // store the selected asset to delete
      setShowDeletePopup(true); // Open the delete confirmation modal
    } else {
      if (items.live) navigate(`/${asset.role}/${items.link}/${item}`);
    }
  };

  const handleCheckboxChange = (key) => {
    if (key === "active") {
      SetStatus(!status);
      SetData(!status)
    }
  };

  const confirmDelete = () => {
    deleteData();
  };

  const cancelDelete = () => {
    setShowDeletePopup(false);
  };

  return (
    <>
      <div className="back-color-wite p-1 rounded-xd">
        {data &&
          data.map((item, i) =>
            item.actv ? (
              <div
                className={`d-flex p-2 align-middle 
          ${item.live ? "cursor hidark rounded-wd" : "text-color-tint"}`}
                style={{ height: "2.4rem" }}
                key={i}
                onClick={() => handleClick(item)}
              >
                <div className="">
                  <i
                    className={`m-0 p-0 ${item.icon} `}
                    style={{ fontSize: "1.5rem" }}
                  ></i>
                </div>
                <div className="ms-2">
                  <p className={`m-0 p-0 text-nowrap`}>
                    <span className="text-small align-middle">{item.name}</span>
                  </p>
                </div>
                <div className="ms-auto ">
                  <i
                    className={`m-0 p-0 bx bx-chevron-right`}
                    style={{ fontSize: "1.5rem" }}
                  ></i>
                </div>
              </div>
            ) : (
              ""
            )
          )}
      </div>
      <WebbDividerSmall />

      <div className="back-color-wite p-2 px-3 rounded-xd d-none">
        <div className="">
          <div className="d-flex form-check form-switch m-0 p-0 my-2">
            <div className="">
              <p className="m-0 p-0 fw-bold">Active</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={status}
                onChange={() => handleCheckboxChange("active")}
              ></input>
            </div>
          </div>
        </div>
      </div>

      {showDeletePopup && (
        <div
          className="popup"
          style={{
            height: "100%",
            width: "100%",
            position: "fixed",
            top: "0px",
            left: "0px",
            backdropFilter: "brightness(0.5)",
          }}
        >
          <div
            className="popup-content p-4 border rounded"
            style={{
              position: "absolute",
              top: "30%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
            }}
          >
            <h3 className=" text-center">Confirm Deletion</h3>
            <p>Are you sure you want to delete this contact?</p>
            <div className="popup-buttons  d-flex gap-3 justify-content-between">
              <button
                className="btn btn-primary rounded-xx text-small"
                onClick={cancelDelete}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger rounded-xx text-small"
                onClick={confirmDelete}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
