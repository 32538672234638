import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserDetail } from "../../services/data-users-realm";
import AssetsUnitList from "../assets/assets-unit-list";
import AssetGallerySection from "../assets/asset-gallery-section";
import AssetDocumentsSection from "../assets/asset-documents-section";
import AssetsTransactionList from "../assets/assets-transaction-list";
import AssetUserListModule from "../assets/assets-user-list";
import { CreatorRemoveMember, MemberDetail } from "../../services/srvc-creator-realm";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import WebbDividerSmall from "../webx/webb-divider-sm";

export default function MemberUserDetail() {
  const { item } = useParams();
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const navigate = useNavigate();


  const [loader, setLoader] = useState(true);
  const [currentItems, setCurrentItems] = useState([]);
  const [activeTab, setActiveTab] = useState("transactionList");

  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      var res = await MemberDetail({
        data: {
          user: item,
        },
        srvc: "******",
      });
      setCurrentItems(res.data);
      setLoader(false);
    };
    fetchData();
  }, [item]);

  
  



  const renderTabContent = () => {
    switch (activeTab) {
    
      case "unitList":
        return <p>Unitlist</p>;
      case "transactionList":
        return <p>Transaction </p>;
      default:
        return null;
    }
  };
  

  if (loader) {
    return <p>Please wait ....</p>;
  }
  if (!loader && currentItems.length === 0) {
    return <p>No items found</p>;
  }

  return (
    <>
      <div className=" my-2 ">
        <div className="bg-white my-2  p-3 border rounded">
          <div className="d-flex  gap-4  justify-content-between">
            <p>Name</p>
            <p
              style={{
                minWidth: "50px",
                marginLeft: "40px",
                wordWrap: "break-word",
              }}
            >
              {currentItems?.name || ""}
            </p>
          </div>

          <div className="d-flex  gap-4  justify-content-between">
            <p>Email</p>
            <p
              style={{
                minWidth: "50px",
                marginLeft: "40px",
                wordWrap: "break-word",
              }}
            >
              {currentItems?.mail || ""}
            </p>
          </div>{" "}

          <div className="d-flex  gap-4  justify-content-between">
            <p>Mobile</p>
            <p
              style={{
                minWidth: "50px",
                marginLeft: "40px",
                wordWrap: "break-word",
              }}
            >
              {currentItems?.mobile || ""}
            </p>
          </div>
          <div className="d-flex  gap-4  justify-content-between">
            <p>Date</p>
            <p
              style={{
                minWidth: "50px",
                marginLeft: "40px",
                wordWrap: "break-word",
              }}
            >
              {new Date(currentItems?.created).toDateString()}
            </p>
          </div>
        </div>
      </div>
    
      <WebbDividerSmall/>

      <div className="d-none">
        <ul className="nav nav-tabs">
        <li className="nav-item">
            <button
              className={`nav-link fw-bold ${
                activeTab === "transactionList" && "active"
              }`}
              onClick={() => setActiveTab("transactionList")}
            >
              Transfers
            </button>
          </li>

          <li className="nav-item">
            <button
              className={`nav-link ${
                activeTab === "unitList" && "active"
              } fw-bold`}
              onClick={() => setActiveTab("unitList")}
            >
              Units
            </button>
          </li>
         
        </ul>
        <div className="tab-content mt-4">{renderTabContent()}</div>
      </div>
    </>
  );
}
