import axios from "axios";
import services from "../data.services/data-services-main.json";


const base = services.data.find((x) => x.code === "transfers.assets").link;

export const AssetsTransactions = async (item) => {
    const basx = base + "/transfers/assets/list";
    const head = {
      "Content-Type": "application/json",
      Authorization: "*******",
      //  process.env.REACT_APP_WEBB_SITE_CLNT
    };
    const datx = {
      data: item.data,
      srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
      // process.env.REACT_APP_WEBB_SITE_SRVC
    };
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head });
      return { code: result.status, ...result.data };
    } catch (error) {
      return { code: error.response.status, ...error.response.data };
    }
  };


  export const AssetsUnitsTransactionsList = async (item) => {
    const basx = base + "/transfers/assets/list/unit";
    const head = {
      "Content-Type": "application/json",
      Authorization: "*******",
      //  process.env.REACT_APP_WEBB_SITE_CLNT
    };
    const datx = {
      data: item.data,
      srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
      // process.env.REACT_APP_WEBB_SITE_SRVC
    };
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head });
      return { code: result.status, ...result.data };
    } catch (error) {
      return { code: error.response.status, ...error.response.data };
    }
  };

  export const AssetsTransfersSubmit = async (item) => {
    const basx = base + "/transfers/assets/submit";
    const head = {  
      "Content-Type": "application/json",
      Authorization: "*******",
      //  process.env.REACT_APP_WEBB_SITE_CLNT
    };
    const datx = {
      data: item.data,
      srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
      // process.env.REACT_APP_WEBB_SITE_SRVC
    };
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head });
      return { code: result.status, ...result.data };
    } catch (error) {
      return { code: error.response.status, ...error.response.data };
    }
  };

  export const AssetsTransfersDecline = async (item) => {
    const basx = base + "/transfers/assets/decline";
    const head = {  
      "Content-Type": "application/json",
      Authorization: "*******",
      //  process.env.REACT_APP_WEBB_SITE_CLNT
    };
    const datx = {
      data: item.data,
      srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
      // process.env.REACT_APP_WEBB_SITE_SRVC
    };
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head });
      return { code: result.status, ...result.data };
    } catch (error) {
      return { code: error.response.status, ...error.response.data };
    }
  };

  export const AssetsTransactionsSearch = async (item) => {
    const basx = base + "/transfers/assets/search";
    const head = {
      "Content-Type": "application/json",
      Authorization: "*******",
      //  process.env.REACT_APP_WEBB_SITE_CLNT
    };
    const datx = {
      data: item.data,
      srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
      // process.env.REACT_APP_WEBB_SITE_SRVC
    };
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head });
      return { code: result.status, ...result.data };
    } catch (error) {
      return { code: error.response.status, ...error.response.data };
    }
  };

  export const AssetsTransactionsDetail = async (item) => {
    const basx = base + "/transfers/assets/details";
    const head = {
      "Content-Type": "application/json",
      Authorization: "*******",
      //  process.env.REACT_APP_WEBB_SITE_CLNT
    };
    const datx = {
      data: item.data,
      srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
      // process.env.REACT_APP_WEBB_SITE_SRVC
    };
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head });
      return { code: result.status, ...result.data };
    } catch (error) {
      return { code: error.response.status, ...error.response.data };
    }
  };
  export const AssetsUserList = async (item) => {
    const basx = base + "/assets/users";
    const head = {
      "Content-Type": "application/json",
      Authorization: "*******",
      //  process.env.REACT_APP_WEBB_SITE_CLNT
    };
    const datx = {
      data: item.data,
      srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
      // process.env.REACT_APP_WEBB_SITE_SRVC
    };
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head });
      return { code: result.status, ...result.data };
    } catch (error) {
      return { code: error.response.status, ...error.response.data };
    }
  };
  export const AssetsUserUnitsList = async (item) => {
    const basx = base + "/assets/users/units";
  
    const head = {
      "Content-Type": "application/json",
      Authorization: "*******",
      //  process.env.REACT_APP_WEBB_SITE_CLNT
    };
    const datx = {
      data: item.data,
      srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
      // process.env.REACT_APP_WEBB_SITE_SRVC
    };
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head });
      return { code: result.status, ...result.data };
    } catch (error) {
      return { code: error.response.status, ...error.response.data };
    }
  };
  export const AssetsUnitUserList = async (item) => {
    const basx = base + "/units/users";
    const head = {
      "Content-Type": "application/json",
      Authorization: "*******",
      //  process.env.REACT_APP_WEBB_SITE_CLNT
    };
    const datx = {
      data: item.data,
      srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
      // process.env.REACT_APP_WEBB_SITE_SRVC
    };
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head });
      return { code: result.status, ...result.data };
    } catch (error) {
      return { code: error.response.status, ...error.response.data };
    }
  };
  export const CreatorsUsersTransfers = async (item) => {
    
    const basx = base + '/transfers/assets/list/users';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      return { code: result.status, ...result.data }
    } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }
