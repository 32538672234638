// main
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xv";

import WebbIcon from "../content/webx/webb-icon";
import WebbHeader from "../content/webz/webb-header-navs-xv";
import UserAvatar from "../content/webx/user-avatar";

import WebbFooterMobile from "../content/webz/webb-footer-mobile";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";
import WebbLoaderSmall from "../content/webx/webb-loader-sm";

import { GetUserForm } from "../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../services/srvc-auth-local";

import TeamInfocardHomeModule from "../content/user/team-infocard-home";

import TeamStatisticsModule from "../content/statistics/team-activiti-statistics";
import TeamTransferStatisticsModule from "../content/statistics/team-transfers-statistics";
import UserActionsModule from "../content/user/user-actions";
import DashboardModule from "../content/statistics/dashboard";

import TeamAssetStatisticsModule from "../content/statistics/team-assets-statistcs";
import TeamAssetUnitsStatisticsModule from "../content/statistics/team-assets-units-statistic";
import MenubarFormat from "../content/webz/menubar-format-xm";
import WebbDateTimeMedium from "../content/webx/webb-datetime";
export default function HomeTeam() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const metadata = {
    name: "Home",
    banner: {
      link: "https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900",
    },
  };

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: "xtra", show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`/${asset.role}/home`}>
                <WebbIcon
                  data={{ color: "text-color-main", size: "text-icon-sm" }}
                />
              </Link>
            </>
          ),
          data: (
            <>
              {" "}
              <WebbHeader />{" "}
            </>
          ),
          footer: (
            <>
              <UserAvatar />
            </>
          ),
          mobile: (
            <>
              <MenubarFormat />
            </>
          ),
        }}
        sidebar={{
          header: (
            <>
              <p className="text-normal text-bold m-0">{metadata.name}</p>
            </>
          ),
          data: (
            <>
              <TeamInfocardHomeModule />
            </>
          ),
          footer: (
            <>
              {" "}
              <WebbDateTimeMedium />
            </>
          ),
          
        }}
        content={{
          header: (
            <>
              <p className="m-0">Overview</p>
            </>
          ),
          data: (
            <>
              {/* <h2 className="text-normal text-color-next mb-2">
                Users Activity
              </h2>
              <TeamStatisticsModule />

              <WebbDividerMedium /> */}
              <h2 className="text-normal text-color-next mb-2">
                Transfers Activity
              </h2>
              <TeamTransferStatisticsModule />
              <WebbDividerMedium />
              <h2 className="text-normal text-color-next mb-2">
                Assets Activity
              </h2>
              <TeamAssetStatisticsModule />
              <WebbDividerMedium />
              <h2 className="text-normal text-color-next mb-2">
                Units Activity
              </h2>
              <TeamAssetUnitsStatisticsModule />
              <div className="d-none d-md-block">
                <WebbDividerSmall />
              </div>{" "}
              {/* <h2 className="text-normal text-color-next mb-2">
                Category Statistics
              </h2> */}
              <div className="d-flex justify-content-center">
                <DashboardModule />
              </div>
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          ),
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: (
            <>
              <UserActionsModule />
            </>
          ),
          footer: <></>,
        }}
      ></ContentFormat>
    </>
  );
}
