import React from 'react'

export default function UserOverview({ type, currentItems, currentState }) {
  return (
    <div
    className={
      type.text == "overview"
        ? "bg-white my-2  p-3 border rounded"
        : "d-none"
    }
  >
    <div className="d-flex  justify-content-between">
      <p>{currentItems?.name}</p>
      <p
        style={{
          minWidth: "137px",
          wordWrap: "break-word",
          texWrap: "wrap",
          margiLeft: "92px",
        }}
      >
        {currentItems?.usid}
      </p>
    </div>
    <div className="d-flex  justify-content-between">
      {" "}
      <p
        style={{
          minWidth: "157px",
          wordWrap: "break-word",
          texWrap: "wrap",
          marginRight: "92px",
        }}
      >
        {currentItems?.mail}{" "}
      </p>
      {/* <p>{currentItems.mobile}</p> */}
      <p>*******</p>
    </div>
    <div className="d-flex  justify-content-between">
      {" "}
      <p>Balance</p>
      <p>*****</p>
    </div>
    <div className="d-flex  justify-content-between">
      {" "}
      <p>Account</p>
      <div className=" d-flex gap-2">
        <div className="d-flex  gap-2 form-check form-switch m-0 p-0 mb-2">
          {/* <p className="m-0 p-0">Active</p> */}
          {/* <input
            className="form-check-input"
            type="checkbox"
            value=""
            style={{ height: "1.2rem", width: "2rem", margin: "0px" }}
            checked={currentState}
            onChange={() => handleCheckboxChange()}
          ></input> */}
        </div>
        <p className="d-flex align-items-center gap-1">
          <div
            className={`${
              currentState === true
                ? "bg-success"
                : currentState === false
                ? "bg-danger"
                : "bg-warning"
            }`}
            style={{
              height: "10px",
              width: "10px",
              borderRadius: "50%",
            }}
          ></div>
          {currentState === true
            ? "Active"
            : currentState === false
            ? "Inactive"
            : "Pending"}
        </p>
      </div>
    </div>
  </div>  )
}

