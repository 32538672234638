import { useState, useEffect } from "react";
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbIcon from "../webx/webb-icon";
import { Link, useNavigate } from "react-router-dom";
import { GetUserForm, NumberFormat } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { AccountsBaseBalance } from "../../services/srvc-accounts-realm";
import WebbHeaderNavs from "./webb-header-navs-xv";

const media =
  "https://img.freepik.com/free-vector/abstract-glowing-particles-background-blue-red-color_1017-26575.jpg?w=900";

export default function MenubarFormat() {
  const navigate = useNavigate();
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [loader, setLoader] = useState(true);

  const [balance, setBalance] = useState({
    number: "000000",
    ticker: "******",
  });
  const [data, setData] = useState();

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        var res = await AccountsBaseBalance({ data: { user: asset.item } });
        // console.log(res);
        if (res.stat) setBalance(res?.data?.balance);

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);
  const [showSidebar, setShowSidebar] = useState(false);
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <>
        <div
          style={{ fontSize: "30px" }}
          onClick={toggleSidebar}
          className="btn bi bi-list btn-lg p-0"
          type="button"
        ></div>
      <div
        className={`offcanvas offcanvas-start ${showSidebar ? "show" : ""}`}
        tabIndex="-1"
        style={{
          visibility: showSidebar ? "visible" : "hidden",
          transition: "visibility 0.3s, transform 0.3s ease-in-out",
          width: "85vw",
        }}
      >
        {/* sidebar close  button*/}
        <div className="offcanvas-header pb-0">
          <div className="">
            <Link to={`/${asset.role}/home`}>
              <WebbIcon
                data={{ color: "text-color-main", size: "text-icon-sm" }}
              />
            </Link>
          </div>
          <button className="btn-close" onClick={toggleSidebar}></button>
        </div>

        {showSidebar && (
          <div className="offcanvas-body d-flex flex-column justify-content-between">
            {/* header */}
            <div className="d-lg-none d-flex flex-column">
              <div className="w-100">
                <div
                  className="rounded-xd text-color-wite"
                  style={{
                    backgroundImage: `url(${media})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundColor: "rgba(0, 0, 0, 0.75)",
                    // minHeight: '100vh'
                    // height:'100vh'
                  }}
                >
                  <div
                    className="rounded-xd p-3"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.33)" }}
                  >
                    <div className="" style={{}}>
                      <div
                        className=""
                        style={{ height: "2.5rem", width: "2.5rem" }}
                      >
                        {/* <Jazzicon diameter={33} seed={jsNumberForAddress(asset.item ??= Date.now().toString())} />  */}
                        <WebbIcon
                          data={{
                            color: "text-color-wite",
                            size: "text-icon-sm",
                          }}
                        />
                      </div>
                    </div>

                    <p className="text-bold text-sm m-0">
                      {data?.account?.number || "9999 0000 0000 0456"}
                    </p>

                    <p className="text-small text-sm text-bold m-0">
                      {asset?.name || "******"}
                    </p>
                    <p className="text-small text-sm m-0">
                      {asset?.mail || "******"}
                    </p>
                    <p className="text-small m-0">
                      Status: {asset?.active ? "Active" : "Inactive"}
                    </p>
                  </div>
                </div>

                <div className="back-color-wite px-3 py-1 rounded-xd border mt-2 d-flex justify-content-between">
                  <div className="">
                    <p className="text-small m-0">Account Balance</p>
                    <p className="m-0">
                      <span className="text-lead text-bold">
                        {NumberFormat(
                          balance?.number / 1000000 || "0",
                          "",
                          "2"
                        )}
                      </span>
                      <span className="ms-1"></span>
                      <span className="text-small">
                        {balance?.ticker || "***"}
                      </span>
                    </p>
                  </div>
                  <Link to={"/team/transfers/account/credit"}>
                    <div className="d-flex flex-column align-items-center">
                      <i
                        className={"bx bx-plus-circle"}
                        style={{ fontSize: "1.8rem" }}
                      ></i>
                      <p className="m-0 text-small">Add Funds</p>
                    </div>
                  </Link>
                </div>
                <WebbDividerSmall />

                {/* header data */}
              </div>
              <div className="w-100"><WebbHeaderNavs /></div>
            </div>

            <Link to={"/auth/x"}>
              <div className="d-flex align-items-center p-2 pb-2">
                <i
                  className="bx bx-log-out-circle"
                  style={{ fontSize: "1.65rem", marginRight: "10px" }}
                ></i>
                <p className="m-0 text-md"> Logout</p>
              </div>
            </Link>
          </div>
        )}
      </div>
    </>
  );
}
