// content
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import FormNeeded from "../webx/form-needed";
import WebbDividerSmall from "../webx/webb-divider-sm";
import {
  AssetDelete,
  AssetsBook,
  AssetsDetail,
  AssetsLive,
  AssetsScan,
  AssetsShow,
} from "../../services/srvc-assets-realm";
import DropdownFormat from "../webz/dropdown-format-xm";

const listactions = require("../../data.static/data-asset-details.json").data;

export default function AssetOptionModule(props) {
  const navigate = useNavigate();

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const { item } = useParams();
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState();

  const [stat, SetStat] = useState({
    show: false,
    scan: false,
    live: false,
    book: false,
  });
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedAssetToDelete, setSelectedAssetToDelete] = useState(null);

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const result = listactions.filter((x) => x.user.includes(asset.role));
        setData(result);

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);

  useEffect(() => {
    setLoader(true);
    const fetchData = async () => {
      var res = await AssetsDetail({
        data: {
          item: item,
        },
      });
      SetStat(res.data.stat);
      setLoader(false);
    };

    fetchData();
  }, []);

  const setShow = async (status) => {
    var res = await AssetsShow({
      data: {
        item: item,
        status: status,
      },
    });
  };

  const setLive = async (status) => {
    var res = await AssetsLive({
      data: {
        item: item,
        status: status,
      },
    });
  };

  const setBook = async (status) => {
    var res = await AssetsBook({
      data: {
        item: item,
        status: status,
      },
    });
  };

  const setScan = async (status) => {
    var res = await AssetsScan({
      data: {
        item: item,
        status: status,
      },
    });
  };

  const deleteData = async () => {
    // var res = await AssetDelete({
    //   data: {
    //     item: item,
    //   },

    // });
    // if(res.stat){
    //   navigate(`/team/assets`)
    // }
    alert("Data deleted");
    setShowDeletePopup(false);
  };

  const handleClick = (items) => {
    // props.handleSelect(item);
    if (items.name == "Delete Assets") {
      setSelectedAssetToDelete(item); // store the selected asset to delete
      setShowDeletePopup(true); // Open the delete confirmation modal
    } else {
      if (items.live) navigate(`/${asset.role}/${items.link}/${item}`);
    }
  };

  const handleCheckboxChange = (key) => {
    let newData;

    if (key === "show") {
      newData = { ...stat, show: !stat.show };
      setShow(!stat.show);
    } else if (key === "scan") {
      newData = { ...stat, scan: !stat.scan };
      setScan(!stat.scan);
    } else if (key === "live") {
      newData = { ...stat, live: !stat.live };
      setLive(!stat.live);
    } else if (key === "book") {
      newData = { ...stat, book: !stat.book };
      setBook(!stat.book);
    }

    SetStat(newData);
  };

  const confirmDelete = () => {
    deleteData();
  };

  const cancelDelete = () => {
    setShowDeletePopup(false);
  };
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="back-color-wite p-1 rounded-xd d-flex flex-md-column" style={{overflowX:"auto", scrollbarWidth: "thin"}}>
        {data &&
          data.map((item, i) =>
            item.actv ? (
              <div
                className={`d-flex p-2 align-middle 
          ${item.live ? "cursor hidark rounded-wd" : "text-color-tint"}`}
                style={{ height: "2.4rem" }}
                key={i}
                onClick={() => handleClick(item)}
              >
                <div className="">
                  <i
                    className={`m-0 p-0 ${item.icon} `}
                    style={{ fontSize: "1.5rem" }}
                  ></i>
                </div>
                <div className="ms-2">
                  <p className={`m-0 p-0 text-nowrap`}>
                    <span className="text-small align-middle">{item.name}</span>
                  </p>
                </div>
                <div className="ms-auto d-none d-md-block">
                  <i
                    className={`m-0 p-0 bx bx-chevron-right`}
                    style={{ fontSize: "1.5rem" }}
                  ></i>
                </div>
              </div>
            ) : (
              ""
            )
          )}
      </div>
      <div className="d-none d-md-block">
        <WebbDividerSmall />
      </div>{" "}
      <div
        className="btn m-0 px-2 p-0 d-flex align-items-center d-md-none justify-content-end"
        onClick={() => setIsOpen(!isOpen)}
      >
        Filters
        <i
          className={`bx ${isOpen ? "bx-chevron-up" : "bx-chevron-down"} ps-2`}
        ></i>
      </div>
      <DropdownFormat isOpen={isOpen}>
        <div className="back-color-wite p-2 px-3 rounded-xd">
          <div className="">
            {/* <label className="form-label text-small">
              Live <FormNeeded />
            </label> */}
            <div className="d-flex form-check form-switch m-0 p-0 mb-2">
              <div className="">
                <p className="m-0 p-0">Live</p>
              </div>
              <div className="ms-auto">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  style={{ height: "1.2rem", width: "2rem" }}
                  checked={stat.live}
                  onChange={() => handleCheckboxChange("live")}
                ></input>
              </div>
            </div>

            <div className="d-flex form-check form-switch m-0 p-0 mb-2">
              <div className="">
                <p className="m-0 p-0">Show</p>
              </div>
              <div className="ms-auto">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  style={{ height: "1.2rem", width: "2rem" }}
                  checked={stat.show}
                  onChange={() => handleCheckboxChange("show")}
                ></input>
              </div>
            </div>

            <div className="d-flex form-check form-switch m-0 p-0 mb-2">
              <div className="">
                <p className="m-0 p-0">Enable Scan</p>
              </div>
              <div className="ms-auto">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  style={{ height: "1.2rem", width: "2rem" }}
                  checked={stat.scan}
                  onChange={() => handleCheckboxChange("scan")}
                ></input>
              </div>
            </div>

            <div className="d-flex form-check form-switch m-0 p-0 mb-2">
              <div className="">
                <p className="m-0 p-0">Book</p>
              </div>
              <div className="ms-auto">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  style={{ height: "1.2rem", width: "2rem" }}
                  checked={stat.book}
                  onChange={() => handleCheckboxChange("book")}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </DropdownFormat>
      {showDeletePopup && (
        <div
          className="popup"
          style={{
            height: "100%",
            width: "100%",
            position: "fixed",
            top: "0px",
            left: "0px",
            backdropFilter: "brightness(0.5)",
          }}
        >
          <div
            className="popup-content p-4 border rounded"
            style={{
              position: "absolute",
              top: "30%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
            }}
          >
            <h3 className=" text-center">Confirm Deletion</h3>
            <p>Are you sure you want to delete this asset?</p>
            <div className="popup-buttons  d-flex gap-3 justify-content-between">
              <button
                className="btn btn-primary rounded-xx text-small"
                onClick={cancelDelete}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger rounded-xx text-small"
                onClick={confirmDelete}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
