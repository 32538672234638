import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  AssetDocumentDelete,
  AssetDocumentList,
} from "../../services/srvc-assets-realm";

export default function AssetDocumentSection() {
  const { item } = useParams();
  const [loader, setLoader] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
const[refresh,setRefresh]=useState(true)
  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      var res = await AssetDocumentList({
        data: {
          asset: item,
        },
      });

      if (res.data) {
        setCurrentItems(res?.data?.list);
      }
      setLoader(false);
    };

    fetchData();
  }, [refresh]);

  const deleteDocuments = async (item) => {
    var res = await AssetDocumentDelete({
      data: {
        item: item,
      },
    });

    if (res) {
      setRefresh(!refresh)      
      alert(`${res.memo}`);

    }
  };

  const handleDownload = async (link, name) => {
    try {
      const response = await fetch(link);
      const blob = await response.blob();
      const type = blob.type;

      const url = window.URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = name;
      anchor.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  const handleDelete = async (item) => {
    // alert(`deleted ${item}`)
    deleteDocuments(item);
  };

  if (loader == true) {
    return <p>Please wait ....</p>;
  }
  if (!loader && currentItems.length == 0) {
    return <p className="border rounded p-4">No documents found....</p>;
  }

  return (
    <div className="  border p-4  ">
      <p className="fw-bold m-0">Documents</p>
      <hr />
      {currentItems.map((item, index) => (
        <>
          <div key={index} className="d-flex justify-content-between my-3 mb-4">
            <p className="m-0">{item?.name || item?.enid} </p>
            <div className="d-flex gap-2">
              <i
                className="bx bxs-download bx-sm"
                onClick={() => handleDownload(item?.media?.link, item?.name)}
                style={{ cursor: "pointer" }}
              ></i>
              <i
                class="bx bx-x-circle bx-sm"
                style={{ color: "#f00d0d", cursor: "pointer" }}
                onClick={() => handleDelete(item?.item)}
              ></i>
            </div>
          </div>
        </>
      ))}
    </div>
  );
}
