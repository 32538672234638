import React, { useState, useRef } from "react";
import { toBase64 } from "../../services/srvc-utilities";

const basemedia = require("../../data.media/filesave.jpg");

const listMime = [
  { mime: "image/png", name: "IMAGE" },
  { mime: "image/jpg", name: "IMAGE" },
  { mime: "image/jpeg", name: "IMAGE" },
  { mime: "application/pdf", name: "PDF" },
  { mime: "video/mp4", name: "VIDEO" }, // Add support for video/mp4
];

export default function FileCreateCX(props) {
  const { accept } = props;
  const [link, setLink] = useState(basemedia);
  const [name, setName] = useState();
  const [file, setFile] = useState();
  const [size, setSize] = useState();
  const [mime, setMime] = useState();
  const [done, setDone] = useState(0);

  let inputFile = useRef(null);

  const handleFile = async (e) => {
    const { files } = e.target;
    if (files && files.length) {
      const selectedFile = files[0];
      const fileSizeInKB = selectedFile.size / 1024;
      console.log(files[0].type,"type")
      if (files[0].type == "image/jpeg" ||files[0].type == "image/png" || files[0].type == "image/jpg") {
        if (fileSizeInKB <= 500) {
          setDone(0);
          setName(files[0].name);
          setSize(files[0].size);
          handleLink(files[0]);
          handleMime(files[0]);
          handleSave(files[0]);
          setDone(100);
        } else {
          alert("Please select a file smaller than 500 KB.");
          // Clear the file input field to allow the user to select a new file
          e.target.value = null;
        }
      } else {
        setDone(0);
        setName(files[0].name);
        setSize(files[0].size);
        handleLink(files[0]);
        handleMime(files[0]);
        handleSave(files[0]);
        setDone(100);
      }
    }
  };

  const handleLink = (file) => {
    setLink(URL.createObjectURL(file));
  };

  const handleMime = (item) => {
    setMime(item.type);
  };

  const handleSave = async (item) => {
    const filedata = await toBase64(item);
    props.media({ file: filedata, type: item.type,name:item.name });

    // Here you can perform any further processing or saving logic
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  return (
    <>
      <div className={`back-color-wite border rounded-xd`}>
        <div
          className={`${props.size} cursor p-3`}
          style={{ zIndex: "1000" }}
          onClick={() => onButtonClick()}
        >
          <p className="m-0 p-0 d-none">{name || "select file"}</p>{" "}
          <p className="text-small text-color-tone m-0 p-0 d-none">
            {" "}
            <span>
              {mime ? listMime.find((x) => x.mime === mime).name : ""}{" "}
            </span>
            <span>{" - "}</span>{" "}
            <span>
              {size ? (size / 1024).toFixed(0) : "******"} {size ? "KB" : ""}{" "}
            </span>{" "}
          </p>
          <p className="m-0 d-none">{file || "Click here to upload file"}</p>
          <div className="mb-2 d-none"></div>{" "}
          <p className="text-color-none m-0 mb-1">
            {" "}
            <span>
              {" "}
              <i
                className={`bx bxs-circle small text-color-${
                  done == 100
                    ? "success"
                    : done < 100 && done > 0
                    ? "warning"
                    : "danger"
                }`}
              ></i>
            </span>
            <span className="ms-1">Select Document...</span>
          </p>
          <div className="mb-3"></div>
          {(accept.includes("image") || accept.includes("video")) && (
            <>
              {mime ? (
                <>
                  {mime && mime.startsWith("image/") && (
                    <div className="media-standard">
                      <img
                        className="w-100 rounded-xd"
                        src={link}
                        alt="Uploaded file preview"
                      />
                    </div>
                  )}
                  {mime && mime.startsWith("video/") && (
                    <video
                      className="w-100"
                      style={{ height: "300px" }}
                      controls
                    >
                      <source src={link} type={mime} />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </>
              ) : (
                <div className="media-standard"></div>
              )}
            </>
          )}
          <div>
            {accept.includes("pdf") && (
              <iframe
                className="w-100 rounded-xd"
                onClick={() => onButtonClick()}
                src={link}
                title="PDF Preview"
                style={{ pointerEvents: "none" }} // Disable pointer events to make iframe clickable
              />
            )}
          </div>{" "}
        </div>
      </div>

      <div className="mb-3">
        <input
          type="file"
          accept={accept}
          id="file"
          ref={inputFile}
          onChange={handleFile}
          style={{ display: "none" }}
        />
      </div>
    </>
  );
}
