// // main
// import { useState } from "react";
// import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";

// import ContentFormat from "../content/webz/content-format-xv";
// import WebbHeader from "../content/webz/webb-header-navs-xv";

// import WebbIcon from "../content/webx/webb-icon";
// import UserAvatar from "../content/webx/user-avatar";
// import WebbDateTime from "../content/webx/webb-datetime";

// import WebbDividerMedium from "../content/webx/webb-divider-md";
// import WebbDividerSmall from "../content/webx/webb-divider-sm";
// import HeaderSectionDark from "../content/webx/head-section-dark";

// import { GetLocalBusiness, GetLocalUser } from "../services/srvc-auth-local";
// import { GetUserForm } from "../services/srvc-utilities";

// export default function Rooms() {
//   const usxx = GetUserForm();
//   const usrx = GetLocalUser();
//   const temx = GetLocalBusiness();
//   const asset = usxx === "user" ? usrx : temx;

//   const metadata = {
//     name: "Network",
//     banner: {
//       link: "https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900",
//     },
//   };

//   return (
//     <>
//       <Helmet>
//         <title>
//           {metadata.name}
//           {" • "}
//           {process.env.REACT_APP_WEBB_SITE_NAME}
//           {" • "}
//           {process.env.REACT_APP_WEBB_SITE_LINE}
//         </title>
//         <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
//       </Helmet>

//       <ContentFormat
//         name={metadata.name}
//         media={{ size: "xtra", show: false, data: <></> }}
//         header={{
//           header: (
//             <>
//               <Link to={`/${asset.role}/home`}>
//                 <WebbIcon
//                   data={{ color: "text-color-main", size: "text-icon-md" }}
//                 />
//               </Link>
//             </>
//           ),
//           data: (
//             <>
//               {" "}
//               <WebbHeader />{" "}
//             </>
//           ),
//           footer: (
//             <>
//               {" "}
//               <UserAvatar />{" "}
//             </>
//           ),
//         }}
//         sidebar={{
//           header: (
//             <>
//               {" "}
//               <p className="text-normal text-bold m-0">{metadata.name}</p>{" "}
//             </>
//           ),
//           data: (
//             <>
//               <p>Search</p>
//             </>
//           ),
//           footer: (
//             <>
//               <WebbDateTime />
//             </>
//           ),
//         }}
//         content={{
//           header: (
//             <>
//               {" "}
//               <p className="m-0">Rooms</p>{" "}
//             </>
//           ),
//           data: (
//             <>
//               <HeaderSectionDark data={{ name: "Rooms" }} />
//             </>
//           ),
//         }}
//         actionbar={{
//           header: <>Your Actions</>,
//           data: <></>,
//           footer: <></>,
//         }}
//       />
//     </>
//   );
// }

// ------------------------------------------------------------------------------
//main (Four Column Layout)

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ContentFormat from "../content/webz/content-format-xv";
import "./room.css";
import WebbIcon from "../content/webx/webb-icon";
import WebbHeader from "../content/webz/webb-header-navs-xv";
import UserAvatar from "../content/webx/user-avatar";
import WebbDateTimeMedium from "../content/webx/webb-datetime";
import WebbDividerSmall from "../content/webx/webb-divider-sm";
import { useEffect, useState } from "react";
import Inputbox from "../content/rooms/rooms-inputBox";
import { useNavigate } from "react-router-dom";
import WebbFooterMobile from "../content/webz/webb-footer-mobile";
// import "./room.css";
// import UserInfocardMobileModule from "../content/user/user-infocard-mobile";
// import UserAccountBalance from "../content/user/user-account-balance";
import WebbIconBack from "../content/webx/webb-icon-back";
import MenubarFormat from "../content/webz/menubar-format-xm";
import ChatList from "../content/rooms/rooms-chatList";
import Chatbot from "../content/rooms/rooms-chatBox";

const metadata = {
  name: "Rooms",
  banner: {
    link: "https://img.freepik.com/free-vector/abstract-banner-with-world-map_1048-12232.jpg?&w=996",
  },
};

export default function Rooms() {
  const [user, setUser] = useState(null);
  const [roomselected, setRoomSelected] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [show, setShow] = useState(false);
  const router = useNavigate();
  useEffect(() => {
    const asset = JSON.parse(localStorage.getItem("indx"));
    setUser(asset);
  }, []);

  const handleRoomSelect = async (item) => {
    setRoomSelected(item);
    setRefresh(!refresh);
    setShow(true);
  };

  const handleRefresh = async (item) => {
    setRefresh(!refresh);
  };

  const handleNewRoomSelected = async (item) => {
    const res = await fetch("/api/room-details", {
      body: JSON.stringify({ data: { item: item }, srvc: "******" }),
      headers: { "Content-Type": "application/json" },
      method: "POST",
    });
    var result = JSON.parse(await res.json());
    if (result.stat) {
      setTimeout(() => {
        setRoomSelected(result.data);
      }, 2000);
    }
  };

  const ResetRoomSelection = async () => {
    setRoomSelected(null);
  };

  // const asset = GetUserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  useEffect(() => {
    const asset = JSON.parse(localStorage.getItem("indx"));
    const bznx = JSON.parse(localStorage.getItem("bznx"));
    setUser(asset ? asset : bznx);
  }, [refresh]);

  const AccountLogout = async () => {
    setRefresh(!refresh);
    router("/auth/x");
  };

  return (
    <>
      <Helmet>
        {" "}
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />{" "}
      </Helmet>
      <ContentFormat
        name={metadata.name}
        media={{ size: "xtra", show: false, data: <></> }}
        header={{
          header: (
            <>
              <WebbIcon
                data={{ color: "text-color-main", size: "text-icon-md" }}
              />
            </>
          ),
          data: (
            <>
              <WebbHeader />
            </>
          ),
          footer: (
            <>
              <UserAvatar />
            </>
          ),
          mobile: (
            <>
              {!show ? (
                <MenubarFormat />
              ) : (
                <div
                  className="cursor d-md-none d-block pt-2"
                  onClick={() => {
                    setShow(!show);
                    setRoomSelected(null);
                  }}
                >
                  <WebbIconBack
                    data={{
                      color: "text-color-tone",
                      size: "text-icon-sm",
                    }}
                  />
                </div>
              )}
            </>
          ),
        }}
        sidebar={{
          header: (
            <>
              <p className="text-normal text-bold m-0">{metadata.name}</p>
            </>
          ),
          data: (
            <>
              {/* {!show ? (
                <Chathistory
                  selectroom={handleRoomSelect}
                  handleRefresh={handleRefresh}
                  handleRoomReset={ResetRoomSelection}
                  setShow={setShow}
                />
              ) : (
                ""
              )} */}
              <div className="d-none d-md-block">
                <ChatList
                  selectroom={handleRoomSelect}
                  handleRefresh={handleRefresh}
                  handleRoomReset={ResetRoomSelection}
                  setShow={setShow}
                />
              </div>
              <div className="d-none d-md-block">
                <WebbDividerSmall />
              </div>
            </>
          ),
          footer: (
            <>
              {/* <div className="text-small"> */}
              <WebbDateTimeMedium />
              {/* </div> */}
            </>
          ),
        }}
        content={{
          header: (
            <>
              {!show ? (
                <p className="d-none d-md-block">
                  {roomselected?.name || metadata.name}
                </p>
              ) : (
                <p className="">{roomselected?.name || metadata.name}</p>
              )}
            </>
          ),
          data: (
            <div
              className="d-flex flex-column justify-content-between position-relative mainBox p-1"
              style={{ height: "90vh" }}
            >
              <div className={`first-component d-lg-none d-block`}>
                {!show ? (
                  <ChatList
                    selectroom={handleRoomSelect}
                    handleRefresh={handleRefresh}
                    handleRoomReset={ResetRoomSelection}
                    refresh={refresh}
                    // dismissOffcanvas={true}
                  />
                ) : (
                  ""
                )}
              </div>
              <Chatbot
                room={roomselected}
                refresh={refresh}
                handleRefresh={handleRefresh}
              />
              {show ? (
                <Inputbox
                  room={roomselected}
                  refresh={refresh}
                  newroomselect={handleNewRoomSelected}
                  handleRefresh={handleRefresh}
                />
              ) : (
                ""
              )}
            </div>
          ),
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: <>{/* 4th section */}</>,
          footer: <></>,
        }}
      ></ContentFormat>
    </>
  );
}
