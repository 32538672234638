import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AssetsUserUnitsList } from "../../services/srvc-transfers-assets-realm.js";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import { TokensList } from "../../services/srvc-tokens-realm.js";

export default function ContactUnitList() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const { item } = useParams();
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [totalItem, setTotalItems] = useState("");

  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      var res = await TokensList({
        data: {
          user: item,
          index: currentPage,
          items: itemsPerPage,
          creator: asset.item,
        },
        srvc: "8783533",
      });

      console.log(res);
      setCurrentItems(res?.data.list);
      setTotalItems(res?.data.count);
      setLoader(false);
    };

    fetchData();
  }, [currentPage]);

  const totalPages = Math.ceil(totalItem / itemsPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loader == true) {
    return <p>Please wait ....</p>;
  }

  return (
    <>
      <div className="">
        {currentItems.length > 0 ? (
          <>
            <div className="border rounded  border-none mb-4">
              {currentItems.map((item, index) => (
                <div key={item?.item}>
                 
                    <div className="d-flex justify-content-between bg-white p-4 ">
                      <div>

                        <p
                          className="m-0 text-primary"
                          style={{ fontWeight: "bold" }}
                        >
                          {item?.unit?.name || ""}
                        </p>

                        <div className="d-flex gap-2">
                          <p className="m-0 " style={{ fontSize: "11px" }}>
                            {item?.unit?.number || ""}
                          </p>
                         
                        </div>
                      </div>
                      <div>

                        <p
                          className="m-0 text-primary"
                          style={{ fontWeight: "bold" }}
                        >
                          {item?.sale?.nmbr || ""}
                        </p>

                        <div className="d-flex gap-2">
                          <p className="m-0 " style={{ fontSize: "11px" }}>
                            {item?.sale?.tick || ""}
                          </p>
                          
                        </div>
                       
                      </div>
                    </div>
                  <hr className="m-0" />
                </div>
              ))}
            </div>
            <nav aria-label="Page navigation example">
              <ul
                className="pagination justify-content-between align-items-center
          "
              >
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <p
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                    href="#"
                  >
                    <i className="bx bx-chevron-left"></i>
                  </p>
                </li>

                <p className="m-0">
                  {totalItem == 0
                    ? totalItem
                    : currentPage * itemsPerPage + 1 - itemsPerPage}{" "}
                  -{" "}
                  {currentPage * itemsPerPage > totalItem
                    ? totalItem
                    : currentPage * itemsPerPage}{" "}
                  of {totalItem}
                </p>

                <li
                  className={`page-item align-items-end ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <p
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                    href="#"
                  >
                    <i className="bx bx-chevron-right"></i>
                  </p>
                </li>
              </ul>
            </nav>
          </>
        ) : (
          <div className="border rounded p-4 "> No Units found.....</div>
        )}
      </div>
    </>
  );
}
