import React, { useEffect } from "react";
import { Link } from "react-router-dom";
const TotalMemberTransaction = ({
  currentPage,
  totalPages,
  handlePageChange,
  currentItems,
  itemsPerPage,
  totalItem,
}) => {
  useEffect(() => {
    handlePageChange(currentPage);
  }, []);
  return (
    <>
      <div className="border rounded  border-none mb-4">
        {currentItems.map((item, index) => (
          <div key={item?.item}>
            
              <div className="d-flex justify-content-between bg-white p-4 ">
                <div>
                  <p
                    className="m-0 text-primary"
                    style={{ fontWeight: "bold" }}
                  >
                    {item?.credit?.name || ""}
                  </p>
                  <p className="m-0">
                    {item?.meta?.memo || ""}
                   
                  </p>
                  
                  <div className="d-flex gap-2">
                    <p className="m-0 " style={{ fontSize: "11px" }}>
                      {item?.credit?.mail || ""}
                    </p>
                    <p style={{ fontSize: "11px", margin: "0" }}>
                    {/* {new Date(item?.crts).toDateString() || ""} */}
                    {new Date(item?.created).toDateString() || ""}

                  </p>
                  </div>
                </div>
                <div>
                  <div>
                    <div className="d-flex  gap-3 align-items-center mb-2">
                      <div className="d-flex gap-1">
                        {/* <p className="m-0" style={{ fontWeight: "bold" }}>
                          {item?.sale?.amount == 0
                            ? "0.00"
                            : item?.sale?.amount / 1000000 || ""}{" "}
                        </p>
                        <p className="m-0">{item?.sale?.ticker || ""}</p> */}
                         <p className="m-0" style={{ fontWeight: "bold" }}>
                          {item?.amount?.number == 0
                            ? "0.00"
                            : item?.amount?.number / 1000000 || ""}{" "}
                        </p>
                        <p className="m-0">{item?.amount?.ticker || ""}</p>
                      </div>
                      <div
                        className={`${
                          item.status == 6 || item.status == "6"
                            ? "bg-success"
                            : item?.status == 3 ||
                              item?.status == "3" ||
                              item?.status == 4 ||
                              item?.status == "4" ||
                              item?.status == 7 ||
                              item?.status == "7" ||
                              item?.status == 8 ||
                              item?.status == "8" ||
                              item?.status == 9 ||
                              item?.status == "9"
                            ? "bg-danger"
                            : "bg-warning"
                        }`}
                        style={{
                          height: "10px",
                          width: "10px",
                          borderRadius: "50%",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            <hr className="m-0" />
          </div>
        ))}
      </div>
      <nav aria-label="Page navigation example">
        <ul
          className="pagination justify-content-between align-items-center
          "
        >
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <p
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              href="#"
            >
              <i className="bx bx-chevron-left"></i>
            </p>
          </li>

          <p className="m-0">
            {totalItem == 0
              ? totalItem
              : currentPage * itemsPerPage + 1 - itemsPerPage}{" "}
            -{" "}
            {currentPage * itemsPerPage > totalItem
              ? totalItem
              : currentPage * itemsPerPage}{" "}
            of {totalItem}
          </p>

          <li
            className={`page-item align-items-end ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <p
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              href="#"
            >
              <i className="bx bx-chevron-right"></i>
            </p>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default TotalMemberTransaction;
