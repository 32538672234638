import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xx";
import WebbHeader from "../content/webz/webb-header-xx";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";
import NetworkAddCreator from "../content/network/network-add-team";

export default function NetworkAddCreators() {
  const [selectedButton, setSelectedButton] = useState("");

  const metadata = {
    name: `Add ${selectedButton}`,
    banner: {
      link: "https://img.freepik.com/premium-photo/metaverse-city-cyberpunk-concept-3d-render_84831-1036.jpg?w=900",
    },
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const handleButtonSelect = (value) => {
    setSelectedButton(value);
  };

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        header={{
          size: "medium",
          show: true,
          data: (
            <>
              <WebbHeader
                data={{ home: "/home", name: metadata.name, link: "home" }}
              />
            </>
          ),
        }}
        media={{
          size: "xtra",
          show: false,
          link: metadata.banner.link,
          data: <></>,
        }}
        content={{
          size: "medium",
          show: true,
          data: (
            <>
              <div className="container">
                <NetworkAddCreator onButtonSelect={handleButtonSelect} />

                <WebbDividerMedium />

                <WebbDividerMedium />
                <WebbDividerMedium />
                <WebbDividerMedium />
                <WebbDividerMedium />
                <WebbDividerMedium />
                <WebbDividerMedium />
              </div>
            </>
          ),
        }}
        footer={{
          size: "medium",
          data: (
            <>
              <div className=""></div>
            </>
          ),
        }}
      ></ContentFormat>
    </>
  );
}
