// main
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ContentFormat from "../content/webz/content-format-xv";

import BusinessDetailModule from "../content/creator/bznx-details";

import BusinessFundsTransfersModule from "../content/transfers/bznx-funds-transferlist";

import AccountStatus from "../content/webx/account-stat";
import DocumentStatus from "../content/webx/document-stat";

import WebbLoaderMedium from "../content/webx/webb-loader-md";
import ContractSearchModule from "../content/contracts/contract-search";
import ContractActionsModule from "../content/contracts/contract-action";
import ContractListModule from "../content/contracts/contract-lists";

import WebbIcon from "../content/webx/webb-icon";
import WebbHeader from "../content/webz/webb-header-navs-xv";
import UserAvatar from "../content/webx/user-avatar";

import WebbFooterMobile from "../content/webz/webb-footer-mobile";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";
import WebbLoaderSmall from "../content/webx/webb-loader-sm";

import { GetUserForm } from "../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../services/srvc-auth-local";
import MenubarFormat from "../content/webz/menubar-format-xm";
import WebbDateTimeMedium from "../content/webx/webb-datetime";

export default function Assets() {
  const { id } = useParams();

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const metadata = {
    name: "Contracts",
    banner: {
      link: "https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900",
    },
  };

  const listTabs = [
    { name: "Contracts", code: "contracts" },
    // {name: 'E-Stamp', code: 'estm'}
  ];

  var user = localStorage.getItem("auth");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState(null);

  const [sort, setSort] = useState("contracts");
  const [search, setSearch] = useState();

  const handleSearch = async (item) => {
    // console.log('srch: ', sort)
    setSearch(item);
  };

  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        setLoading(true);

        setLoading(false);
      };
      fetchData();
    } else {
      navigate("/");
    }
  }, [id]);

  //if(loading) {return(<div className={loading ? '' : 'd-none'}>Please wait...</div>)}

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: "xtra", show: true, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`/${asset.role}/home`}>
                <WebbIcon
                  data={{ color: "text-color-main", size: "text-icon-sm" }}
                />
              </Link>
            </>
          ),
          data: (
            <>
              {" "}
              <WebbHeader />{" "}
            </>
          ),
          footer: (
            <>
              <UserAvatar />
            </>
          ),
          mobile: (
            <>
              <MenubarFormat />
            </>
          ),
        }}
        sidebar={{
          header: (
            <>
              <p className="text-normal text-bold m-0">{metadata.name}</p>
            </>
          ),
          data: (
            <>
              <ContractSearchModule />
            </>
          ),
          footer: (
            <>
              {" "}
              <WebbDateTimeMedium />
            </>
          ),
        }}
        content={{
          header: (
            <>
              <p className="m-0">Contracts List</p>
            </>
          ),
          data: (
            <>
              <h2 className="text-normal text-color-next mx-3 mb-2 d-none">
                Assets
              </h2>

              <ContractListModule />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          ),
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: (
            <>
              <ContractActionsModule />
            </>
          ),
          footer: <></>,
        }}
      ></ContentFormat>
    </>
  );
}
