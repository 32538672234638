import React, { useState, useEffect } from "react";
import {
  TeamUserAccountCreate,
  TeamUserAccountList,
  TeamUserMemberAdd,
  UserAccountCreate,
} from "../../services/srvc-users-realm";
import { CreatorAccountCreate } from "../../services/srvc-creator-realm";
import { Link, useNavigate } from "react-router-dom";

export default function NetworkAddUser({ onButtonSelect }) {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [srvc, setSrvc] = useState("");
  const [mobile, setMobile] = useState("");
  const [selectedButton, setSelectedButton] = useState("");
  const [team, setTeam] = useState("");
  const [member, setmember] = useState("");
  const [teamSrvc, setTeamSrvc] = useState("");
  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleMobileChange = (e) => setMobile(e.target.value);
  const handleSrvcChange = (e) => setSrvc(e.target.value);

  const fetchData = async () => {
    var res = await (selectedButton === "User"
      ? UserAccountCreate
      : TeamUserAccountCreate)({
      data: {
        name: username,
        mail: email,
        mobile: mobile,
      },
      srvc: srvc,
    });
    if (res.stat) {
      alert("Account Created");
      navigate("/team/home");
    } else {
      alert(res.memo || `Something went wrong..`);
    }
    setUsername("");
    setEmail("");
    setMobile("");
    setSrvc("");
  };

  const fetchDataMemberList = async () => {
    var res = await TeamUserAccountList({
      data: {
        name: username,
        mail: email,
        mobile: mobile,
      },
      srvc: srvc,
    });
    if (res) {
      setTeam(res.data.list);
    }
  };

  const fetchMemberData = async () => {
    var res = await TeamUserMemberAdd({
      data: {
        user: {
          name: username,
          mail: email,
          mobile: mobile,
        },
        team: member,

        srvc: teamSrvc,
      },
      srvc: srvc,
    });
    if (res.stat) {
      alert("Member Added");
      navigate("/team/home");
    } else {
      alert(res.memo || `Something went wrong..`);
    }
  };

  useEffect(() => {
    fetchDataMemberList();
  }, []);

  const handleSubmit = () => {
    if (!username || !email || !mobile || !srvc) {
      alert("All fields are required");
      return;
    }
    fetchData();
    console.log("Submitted:", {
      username,
      email,
      mobile,
      srvc,
      selectedButton,
    });
  };

  const handleMemberSubmit = () => {
    if (!username || !email || !mobile) {
      alert("All fields are required");
      return;
    }
    fetchMemberData();
  
  };

  const handleButtonClick = (value) => {
    setSelectedButton(value);
    onButtonSelect(value);
  };
  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidMobile = (mobile) => {
    // Regular expression for mobile number validation
    const mobileRegex = /^\d{10}$/;
    return mobileRegex.test(mobile);
  };

  const handleBack = () => {
    setSelectedButton("");
    onButtonSelect("");
  };

  return (
    <>
      <div
        className={`${selectedButton ? "d-none" : ""} form-container p-3`}
        style={{
          margin: "50px auto",
          backgroundColor: "#ffffff",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="container border p-4">
          <p className="fw-bold">Select your action.....</p>
          <div>
            <button
              className={`(${selectedButton} === "User" ? "selected" : "" ) btn border hidark rounded-xx text-small  text-small mx-2`}
              onClick={() => handleButtonClick("User")}
            >
              User
            </button>
            <button
              className={`(${selectedButton} === "Team" ? "selected" : "" )  btn border hidark  rounded-xx text-small  text-small mx-2`}
              onClick={() => handleButtonClick("Team")}
            >
              Team
            </button>
            <button
              className={`(${selectedButton} === "Member" ? "selected" : "" ) btn border hidark  rounded-xx text-small  text-small mx-2`}
              onClick={() => handleButtonClick("Member")}
            >
              Add Member
            </button>
          </div>
        </div>
      </div>

      <div
        className={`${selectedButton == "User" ? "user" : "d-none"} container`}
      >
        <div
          className="form-container p-3"
          style={{
            margin: "50px auto",
            backgroundColor: "#ffffff",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="form-group my-3">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              className="form-control mt-2"
              id="username"
              placeholder="Enter your username"
              style={{ width: "100%" }}
              value={username}
              onChange={handleUsernameChange}
              required
            />
          </div>
          <div className="form-group my-3">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              className="form-control mt-2"
              id="email"
              placeholder="Enter your email"
              style={{ width: "100%" }}
              value={email}
              onChange={handleEmailChange}
              required
            />
            {email !== "" && !isValidEmail(email) && (
              <div className="mt-2 text-small text-danger">
                Please enter a valid email address.
              </div>
            )}
          </div>
          <div className="form-group my-3">
            <label htmlFor="mobile">Mobile:</label>
            <input
              type="number"
              className="form-control mt-2"
              id="mobile"
              placeholder="Enter your mobile"
              style={{ width: "100%" }}
              value={mobile}
              onChange={handleMobileChange}
              required
            />
            {mobile !== "" && !isValidMobile(mobile) && (
      <div className="mt-2 text-small text-danger">
        Please enter a valid 10-digit mobile number.
      </div>
    )}
          </div>

          <div className="form-group my-3">
            <label htmlFor="srvc">Srvc:</label>
            <input
              type="text"
              className="form-control mt-2"
              id="srvc"
              placeholder="Srvc"
              style={{ width: "100%" }}
              value={srvc}
              onChange={handleSrvcChange}
              required
            />
          </div>
          <div className="form-group my-3 d-flex justify-content-end"></div>
          <div className="d-flex mt-3">
            <div className="me-auto">
              <button
                type="button"
                className="btn btn-primary rounded-xx text-small  text-small"
                onClick={handleBack}
              >
                Back
              </button>
            </div>
            <div className="text-end">
              <button
                type="button"
                className="btn btn-primary rounded-xx text-small  text-small"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${selectedButton == "Team" ? "Team" : "d-none"} container`}
      >
        <div
          className="form-container p-3"
          style={{
            margin: "50px auto",
            backgroundColor: "#ffffff",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="form-group my-3">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              className="form-control mt-2"
              id="username"
              placeholder="Enter your username"
              style={{ width: "100%" }}
              value={username}
              onChange={handleUsernameChange}
              required
            />
          </div>
          <div className="form-group my-3">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              className="form-control mt-2"
              id="email"
              placeholder="Enter your email"
              style={{ width: "100%" }}
              value={email}
              onChange={handleEmailChange}
              required
            />
            {email !== "" && !isValidEmail(email) && (
              <div className="mt-2 text-small text-danger">
                Please enter a valid email address.
              </div>
            )}
          </div>
          <div className="form-group my-3">
            <label htmlFor="mobile">Mobile:</label>
            <input
              type="number"
              className="form-control mt-2"
              id="mobile"
              placeholder="Enter your mobile"
              style={{ width: "100%" }}
              value={mobile}
              onChange={handleMobileChange}
              required
            />
            {mobile !== "" && !isValidMobile(mobile) && (
      <div className="mt-2 text-small text-danger">
        Please enter a valid 10-digit mobile number.
      </div>
    )}
          </div>

          <div className="form-group my-3">
            <label htmlFor="srvc">Srvc:</label>
            <input
              type="text"
              className="form-control mt-2"
              id="srvc"
              placeholder="Srvc"
              style={{ width: "100%" }}
              value={srvc}
              onChange={handleSrvcChange}
              required
            />
          </div>
          <div className="d-flex mt-3">
            <div className="me-auto">
              <button
                type="button"
                className="btn btn-primary rounded-xx text-small  text-small"
                onClick={handleBack}
              >
                Back
              </button>
            </div>
            <div className="text-end">
              <button
                type="button"
                className="btn btn-primary rounded-xx text-small  text-small"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          selectedButton == "Member" ? "Member" : "d-none"
        } container`}
      >
        <div
          className="form-container p-3"
          style={{
            margin: "50px auto",
            backgroundColor: "#ffffff",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <label>Select team in which member is to be added:</label>

          {team ? (
            <select
              value={member}
              onChange={(e) => {
                setmember(e.target.value);
                setTeamSrvc(
                  team.find((item) => item.item === e.target.value)?.srvc || ""
                );
                // Add other fields here
              }}
              className="form-select mt-2"
            >
              <option className="text-small" value="">
                Select Team
              </option>
              {team.map((item, index) => (
                <option className="text-small" value={item.item} key={index}>
                  {item.name}
                </option>
              ))}
            </select>
          ) : (
            ""
          )}

          <div className="form-group my-3">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              className="form-control mt-2"
              id="username"
              placeholder="Enter your username"
              style={{ width: "100%" }}
              value={username}
              onChange={handleUsernameChange}
              required
            />
          </div>
          <div className="form-group my-3">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              className="form-control mt-2"
              id="email"
              placeholder="Enter your email"
              style={{ width: "100%" }}
              value={email}
              onChange={handleEmailChange}
              required
            />
            {email !== "" && !isValidEmail(email) && (
              <div className="mt-2 text-small text-danger">
                Please enter a valid email address.
              </div>
            )}
          </div>
          <div className="form-group my-3">
            <label htmlFor="mobile">Mobile:</label>
            <input
              type="number"
              className="form-control mt-2"
              id="mobile"
              placeholder="Enter your mobile"
              style={{ width: "100%" }}
              value={mobile}
              onChange={handleMobileChange}
              required
            />
            {mobile !== "" && !isValidMobile(mobile) && (
      <div className="mt-2 text-small text-danger">
        Please enter a valid 10-digit mobile number.
      </div>
    )}
          </div>

          <div className="d-flex mt-3">
            <div className="me-auto">
              <button
                type="button"
                className="btn btn-primary rounded-xx text-small  text-small"
                onClick={handleBack}
              >
                Back
              </button>
            </div>
            <div className="text-end">
              <button
                type="button"
                className="btn btn-primary rounded-xx text-small  text-small"
                onClick={handleMemberSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
