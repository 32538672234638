
import React from "react";
import TotalTransaction from "../webx/transfers-list";

export function UserTransfers({ type, transfers, currentPage, totalPages, handlePageChange, itemsPerPage, totalItems }) {
  return (
    <div className={type.text === "transfers" ? "" : "d-none"}>
      <h5>Transfers</h5>
      <div className={transfers?.list?.length === 0 ? "" : "d-none"}>No Transfers Found</div>
      <div className={transfers?.list?.length > 0 ? "" : "d-none"}>
        <TotalTransaction
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          currentItems={transfers?.list}
          itemsPerPage={itemsPerPage}
          totalItem={totalItems}
        />
      </div>
    </div>
  );
}
