// init
import axios from 'axios'


import services from "../data.services/data-services-main.json";


const base = services.data.find((x) => x.code === "transfers.funds").link;



// const base = 'https://ap-southeast-1.aws.data.mongodb-api.com/app/tokenize-transfers-hethm/endpoint'




export const GetTransfersFundSummary = async (item) => {

    const basx = base + '/dashboard/summary'
    const head = {
      'Content-Type': 'application/json',
      'Authorization': process.env.REACT_APP_WEBB_SITE_CLNT
    }
    
    const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  
    var result
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { code: result.status, ...result.data }
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error.response.data }
    }
  
  }

  export const TransfersList = async (item) => {

    const basx = base + '/transfers/funds/list'
    const head = {
      'Content-Type': 'application/json',
      'Authorization': process.env.REACT_APP_WEBB_SITE_CLNT
    }
    
    const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  
    var result
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { code: result.status, ...result.data }
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error.response.data }
    }
  
  }

  export const TransfersAccountCredit = async (item) => {
  
    const basx = base + '/transfers/funds/account/credit';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { code: result.status, ...result.data }
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error.response.data }
    }
  }


  export const TransfersAccountDebit = async (item) => {
  
    const basx = base + '/transfers/funds/account/debit';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result
    try {
      result = await axios.post(basx, datx, { headers: head })
      return { code: result.status, ...result.data }
    } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }

  export const TransfersCreate = async (item) => {
  
    const basx = base + '/transfers/funds/create';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result
    try {
      result = await axios.post(basx, datx, { headers: head })
      return { code: result.status, ...result.data }
    } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }

  export const TransfersFundsStatus = async (item) => {
  
    const basx = base + '/transfers/funds/status';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result
    try {
      result = await axios.post(basx, datx, { headers: head })
      return { code: result.status, ...result.data }
    } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }