// contracts
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import WebbLoader from "../webx/webb-loader-sm";
import FormNeeded from "../webx/form-needed";
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbModuleInfo from "../webx/webb-module-info";
import { CreateContract } from "../../services/srvc-contracts-realm";

import { GetUserForm, toBase64 } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import FileSaveFirebase from "../../services/filesave-fbsx";
import ContractsEditMembersModule from "./contract-edit-mmbr";
import { AssetsList, AssetsUnitLists } from "../../services/srvc-assets-realm";

const list = require("../../data.static/optn-documents-form.json").data.filter(
  (item) => item.actv
);

export default function ContractsNewInfoModule() {
  const navigate = useNavigate();
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  // const auth = JSON.parse(localStorage.getItem("auth"));

  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState(false);
  const [text, setText] = useState("");

  const [balance, setBalance] = useState(1000);

  const [assets, setAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [unit, setUnit] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [show, setShow] = useState(false);

  const [data, setData] = useState({
    name: "",
    memo: "",
    nmbr: "24" + Date.now().toString(),
    form: "",
    stts: new Date().toISOString().substring(0, 10),
    ents: new Date().toISOString().substring(0, 10),
  });

  const [base64, setBase64] = useState();
  const [link, setLink] = useState();

  // form validation
  useEffect(() => {
    setForm(false);
    if (data.form !== "") setForm(true);
  }, [data]);

  useEffect(() => {
    const fetchdata = async () => {};
    // fetch creators contact details

    fetchdata();
  }, []);

  //assets
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await AssetsList({
          data: {
            owner: asset.item,
          },
        });

        if (res?.data?.list) {
          setAssets(res.data.list);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // console.log("contract item", assets);

  const handleAssetSelect = (item) => {
    setSelectedAsset(item);
    setShow(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedAsset) {
        try {
          var res = await AssetsUnitLists({
            data: {
              asset: selectedAsset.item,
            },
          });
          if (res?.data?.list) {
            setUnit(res.data.list);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchData();
  }, [selectedAsset]);

  const handleChange = async (key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  };

  const handleSubmit = async () => {
    // e.preventDefault();
    setLoading(true);

    var datx = {
      name: list.find((item) => item.nmbr === data.form).name,
      memo:
        data.memo != ""
          ? data.memo
          : list.find((item) => item.nmbr === data.form).name,
      name: data.name,
      assx: {name: selectedAsset.meta.name, item: selectedAsset.item, memo:selectedAsset.meta.memo, nmbr:selectedAsset?.nmbr || ""},
      unit: {name: selectedUnit.meta.name, item: selectedUnit.item, memo:selectedUnit.meta.memo,nmbr:selectedUnit.number},
      form: "document",
      sort: "",
      file: [
        {
          link: "",
          base: base64,
          enid: "",
          actv: true,
          mime: "application/pdf",
        },
      ],
      nmbr: data.nmbr,
      stts: Date.parse(new Date(data.stts)),
      ents: Date.parse(new Date(data.ents + "T23:59:59.000")),
      user: { name: asset.name, mail: asset.mail, item: asset.item },
    };

    // console.log("datx", datx);
    var res = await CreateContract({ data: datx, srvc: "" });
    if (res.stat == true) {
     navigate(`/team/contracts/details/${res.data.docn}`);
    }
  };

  const SetFileData = async (data) => {
    var str1 = await toBase64(data);
    console.log(str1);
    setBase64(str1);
    //handleChange("file", base64);
  };

  const SetFileLink = async (link) => {
    // console.log (link);
    setLink(link);
    // handleChange("link", link);
  };

  if (loading) {
    return (
      <>
        {" "}
        <WebbLoader />{" "}
      </>
    );
  }

  return (
    <>
      {/* info */}
      <WebbDividerSmall />
      <WebbModuleInfo data={{ info: "Please enter Document Details" }} />
      <WebbDividerSmall />

      {/* data */}
      <div>
        <div className="">
          <div className="form-group mb-3">
            <label className="form-label small">
              Document Type <FormNeeded />
            </label>
            <select
              className="form-select rounded-none"
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={data.form}
              onChange={({ target }) => handleChange("form", target.value)}
            >
              <option value="">Select Document Type</option>
              {list.map((item, i) => (
                <option key={i} value={item.nmbr}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group mb-3 ">
            <label className="form-label small">
              Document Name <FormNeeded />
            </label>
            <input
              type="text"
              className="form-control rounded-none"
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={data.name}
              onChange={({ target }) => {
                handleChange("name", target.value);
              }}
              placeholder="Document Name"
            ></input>
          </div>

          {/* dropdowns start */}
          <div className="form-group mb-3">
            <label className="form-label small">
              Choose Asset <FormNeeded />
            </label>
            <select
              className="form-select rounded-none"
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={selectedAsset ? selectedAsset.item : ""}
              onChange={({ target }) => {
                const selectedAsset = assets.find(
                  (item) => item.item === target.value
                );
                handleAssetSelect(selectedAsset);
              }}
            >
              <option value="">Select Asset</option>
              {assets.map((item, i) => (
                <option key={i} value={item.item}>
                  {item?.meta?.name}
                </option>
              ))}
            </select>
          </div>

          {show && (
            <div className="form-group mb-3">
              <label className="form-label small">
                Choose Unit <FormNeeded />
              </label>
                <select
                  className="form-select rounded-none"
                  style={{ fontSize: "0.9rem", height: "2.7rem" }}
                  value={unit ? unit.item : ""}
                  onChange={({ target }) =>
                    setSelectedUnit(unit.find((item) => item.item === target.value))
                  }>
                  <option value="">Select Unit</option>
                  {unit.map((item, i) => (
                    <option key={i} value={item.item}>
                      {item?.meta?.name || "No data found...."}
                    </option>
                  ))}
                </select>
            </div>
          )}

          {/* dropdowns end */}

          <div className="mb-3">
            <label className="form-label small">
              File Attachment (PDF) <span className="text-danger">*</span>
            </label>
            <FileSaveFirebase name={""} link={SetFileLink} file={SetFileData} />
          </div>

          <div className="form-group mb-3">
            <label className="form-label small">
              Description <FormNeeded />
            </label>
            <textarea
              className="form-control rounded-none"
              style={{ fontSize: "0.9rem" }}
              rows="3"
              value={data.memo}
              onChange={({ target }) => {
                handleChange("memo", target.value);
              }}
              placeholder="Description"
            ></textarea>
          </div>

          {/* <ContractsEditMembersModule /> */}

          <div className="form-group mb-3 d-none">
            <label className="form-label small">
              File / Reference Number (Internal)
            </label>
            <input
              type="text"
              className="form-control rounded-none"
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={data.nmbr}
              onChange={({ target }) => {
                handleChange("nmbr", target.value);
              }}
              placeholder="DOC123456"
            ></input>
          </div>

          <div className="form-group mb-3 d-none">
            <label className="form-label small">Start Date</label>
            <input
              type="date"
              className="form-control rounded-none"
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={data.stts}
              onChange={({ target }) => {
                handleChange("stts", target.value);
              }}
              placeholder=""
            ></input>
          </div>

          <div className="form-group mb-3 d-none ">
            <label className="form-label small">End Date</label>
            <input
              type="date"
              className="form-control rounded-none"
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={data.ents}
              onChange={({ target }) => {
                handleChange("ents", target.value);
              }}
              placeholder=""
            ></input>
          </div>
        </div>

        <div className="mb-3">
          <p className="small text-danger">
            {text}
            <span className="text-white">{"."}</span>
          </p>
        </div>

        <div className="">
          <div className="d-grid">
            <button
              onClick={() => handleSubmit()}
              className={`btn height-md btn-primary back-color-main rounded-none w-100`}
              disabled={!base64 || !data.form || !data.name}
            >
              <small>Save & Continue</small>
            </button>
          </div>
        </div>
      </div>

      <WebbDividerMedium />
      <WebbDividerMedium />
    </>
  );
}
