import React, { useEffect, useState } from "react";
import Jazzicon from "react-jazzicon/dist/Jazzicon";
import { Link, useParams } from "react-router-dom";
import { AssetsUserList } from "../../services/srvc-transfers-assets-realm.js";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { TokensAssetsUsersList } from "../../services/srvc-tokens-realm.js";

export default function AssetUserListModule() {
  const { item } = useParams();
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [loader, setLoader] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      var res = await TokensAssetsUsersList({
        data: {
          asset: item,
        },
      });
      setCurrentItems(
        res?.data.list.sort((a, b) => b.balance.number - a.balance.number)
      );
      setLoader(false);
    };

    fetchData();
  }, []);

  if (loader == true) {
    return (
      <p className="mt-4 d-flex justify-content-center">Please wait ....</p>
    );
  }
  return (
    <>
      {currentItems.length > 0 ? (
        <div className="border rounded  border-none mb-4">
          {currentItems.map((item, index) => (
            <div key={item.item}>
              <div className="d-flex justify-content-between flex-column flex-md-row bg-white p-4 ">
                <div className="d-flex gap-3">
                  <Jazzicon />
                  <div>
                    <p
                      className="m-0 text-primary "
                      style={{ fontWeight: "bold" }}
                    >
                      {item?.user?.name || ""}
                    </p>
                    <p className="m-0 text-small">{item?.user?.mail || ""}</p>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center
                gap-2"
                >
                  <div className=" d-flex flex-column  align-items-center">
                    {/* <p className="m-0">Status</p> */}
                    <p className="m-0">
                      <span style={{ fontWeight: "bold" }}>
                        {item?.balance?.number || "******"}{" "}
                      </span>
                      {item?.balance?.ticker?.toUpperCase() || "BRX"}
                    </p>
                    <p className="m-0 text-small">
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {item?.count}
                      </span>{" "}
                      Units
                    </p>
                    {/* <div
                      className={`${
                        item?.active === true
                          ? "bg-success"
                          : item?.active === false
                          ? "bg-danger"
                          : "bg-warning"
                      }`}
                      style={{
                        height: "10px",
                        width: "10px",
                        borderRadius: "50%",
                      }}
                    ></div> */}
                  </div>
                  {/* <i className="bx bx-chevron-right bx-sm"></i> */}
                </div>
              </div>

              <hr className="m-0" />
            </div>
          ))}
        </div>
      ) : (
        <div className="border rounded p-4 "> No Users found.....</div>
      )}

      {/* <nav aria-label="Page navigation example">
         <ul
          className="pagination justify-content-between align-items-center
          "
        >
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <p
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              href="#"
              tabIndex="-1"
              aria-disabled="true"
            >
              <i className="bx bx-chevron-left"></i>
            </p>
          </li>

          <p className="m-0">
            {totalItem == 0
              ? totalItem
              : currentPage * itemsPerPage + 1 - itemsPerPage}{" "}
            -{" "}
            {currentPage * itemsPerPage > totalItem
              ? totalItem
              : currentPage * itemsPerPage}{" "}
            of {totalItem}
          </p>
          <li
             className={`page-item align-items-end ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <p
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              href="#"
            >
              <i className="bx bx-chevron-right"></i>
            </p>
          </li>
        </ul>
      </nav> */}
    </>
  );
}
