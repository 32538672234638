// documents

export default function DocumentBasicInfo(props) {

    const data = props.data;
    //console.log(data)
    
    return (
    <>
      <div className="p-3 bg-white border">
        <h6 className="fw-bold">{data.name}</h6>
        <p className="small m-0">{data.nmbr}</p>
  
      </div>
      
    </>
    )
  }