//loader
export default function ListNoData() {
 
    return (
    <>
      <div className="">
        <p className="text-muted">No Records</p>
      </div>
    </>
    )
  }