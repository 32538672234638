import React from "react";
import { useState, useEffect } from "react";
import { UserDocumentsList } from "../../services/srvc-transfers-realm";
import { GetUserForm, NumberFormat } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { AccountsList } from "../../services/srvc-creator-realm";

export default function UserDocument({ type }) {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const [loader, setLoader] = useState(true);
  const [currentItems, setCurrentItems] = useState([]);
  const [expandedItemIndex, setExpandedItemIndex] = useState(-1);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);

      var res = await AccountsList({
        data: {
          user: asset.item,
          // user: "5554beb8866e485d8580097e9faa63ce9",
        },
      });
      setCurrentItems(res?.data?.list);
      setLoader(false);
    };
    fetchData();
  }, []);
  const handleItemClick = (index) => {
    setExpandedItemIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  return (
    <div className={type.text === "accounts" ? "" : "d-none"}>
      <h5 className="">Accounts</h5>
      {loader ? (
        <p>Please wait ....</p>
      ) : currentItems.length === 0 ? (
        <p>No items found</p>
      ) : (
        currentItems.map((item, index) => (
          <div
            // onClick={() => handleItemClick(index)}
            style={{ cursor: "pointer" }}
            className="border rounded  border-none mb-4"
            key={index}
          >
            <div className="d-flex justify-content-between bg-white p-4 ">
              <div>
                <p className="m-0 text-primary" style={{ fontWeight: "bold" }}>
                  {item?.account?.name || ""}
                </p>
                <p className="m-0">
                  {item?.account?.form == "base account"
                    ? "Minilnad"
                    : item?.account?.branch}
                </p>
                <p className="m-0">{item.account.format}</p>
                <marquee>
                  <p className="m-0 " style={{ fontSize: "11px" }}>
                    {item.account.number}
                  </p>
                </marquee>
              </div>
              <div>
                <div>
                  <div className="d-flex  gap-3 align-items-center mb-2">
                    <div className="d-flex gap-1">
                      <p className="m-0" style={{ fontWeight: "bold" }}>
                        {NumberFormat(item?.balance.amount / 1000000, "w", "2")}
                      </p>
                      <p className="m-0">{item.balance.ticker}</p>
                    </div>
                    <div
                      className={`${item.active ? "bg-success" : "bg-danger"}`}
                      style={{
                        height: "10px",
                        width: "10px",
                        borderRadius: "50%",
                      }}
                    ></div>
                  </div>
                  <p style={{ fontSize: "11px", textAlign: "end" }}>
                    {new Date(item.created).toDateString()}
                  </p>
                </div>
              </div>
            </div>
            {expandedItemIndex === index && (
              <div className="accordion-content p-4">
                <div className="d-flex">
                  <p className="me-auto w-25 small ">Bank branch</p>
                  <p className="text-end w-75 small">{item.account.branch}</p>
                </div>
                <div className="d-flex">
                  <p className="me-auto w-25 small ">Bank name</p>
                  <p className="text-end w-75 small">
                    {item?.bank?.name || "*****"}
                  </p>
                </div>
                <div className="d-flex">
                  <p className="me-auto w-25 small ">Modified</p>
                  <p className="text-end w-75 small">
                    {new Date(item.modified).toDateString()}
                  </p>
                </div>
                <div className="d-flex">
                  <p className="me-auto w-25 small ">User mail</p>
                  <p className="text-end w-75 small">{item.user.mail}</p>
                </div>
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
}
