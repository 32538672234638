// transfers
import { useEffect, useState } from "react";
import {  useParams, useNavigate } from 'react-router-dom';

import IconSelectCard from "../webx/ctas-iconselectcard";
import WebbLoader from "../webx/webb-loader-sm";
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";

import DocumentBasicInfo from "../webx/docx-basicinfo";
import { ContractSend } from "../../services/srvc-contracts-realm";

const list = require('../../data.static/optn-esignature.json').data;


export default function ContractSendModule(props) {

  const { id } = useParams();
  const navigate = useNavigate()

  const lstx = (list.filter(item => item.user.includes("in")));
  const sort = [...new Set(lstx.map(item=>item.sort))];

  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState(false);
  
  const [info, setInfo] = useState(props.data);
  const [data, setData] = useState({code:''});

  useEffect( () => {
    if (id){
      const fetchData = async() => {

       
        setLoading(false);
      }
      fetchData()
    } else {}
  },[id]);


  // form validation
  useEffect( () => {
    setForm(false)
    if (data.code !== '' ) setForm(true);
  },);


  const handleSelect = async(sort) => {
    handleChange('code', sort);
    // console.log('item: ', sort);
  }


  const handleChange = async(key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // api call
    if (data.code==='adhr') {
    // aadhaar otp e-signature
      
    }
    
    if (data.code==='mmid') {
      // mobile otp e-signature
    //   const res = await SendDocumentEsin (JSON.stringify({
    //     body: { dcid: id, mode: 'mmid' },
    //     user: '',
    //   }))

    //   if (res) {
    //     console.log (res);
    //     navigate(`/us/documents/view/${id}`)
    //   }

    }

    if (data.code==='emid') {
      // mobile otp e-signature
    const res = await ContractSend({data: { item: id, mode: 'emid' },user: '',})

    if (res.stat) {
        navigate(`/team/contracts/details/${id}`)
      }
    else{
        window.alert(res.memo)
        setLoading(false)
    }

    }
   
  }


  if (loading){ return ( <> <WebbLoader /> </> ) }

  return (
  <>
    <WebbDividerSmall />
    <DocumentBasicInfo data={info}/>
    <WebbDividerSmall />
    
    {/* info */}
    <div className="">
      <p className="text-tone">Please select an option</p>
    </div>
    <WebbDividerSmall />

    {/* options */}
    
    <div className="">
    {sort && sort.map((srtx, x) => (
      <div className="row mb-3 pb-3" key={x}>
        <p className="fw-bold text-color-main mb-2">{srtx}</p>
        
        <div className="">
          {lstx && lstx.map((item, i) => ( item.sort === srtx ?
            
            <IconSelectCard data={item} key={i} sort={data.code} item={handleSelect}/>
          
          :''))}
        </div>
      </div>
    ))}
    </div>

    {/* form */}
    <form onSubmit={handleSubmit}>

      <div className="">
        <div className="d-grid">
          <button className={`btn height-md btn-primary back-color-main rounded-none w-100`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>

    </form>


    <WebbDividerMedium />
    <WebbDividerMedium />

  </>
  )
}