import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const PieChart = ({ data, name ,total }) => {

  const chartRef = useRef(null);

  useEffect(() => {
    if (data && data.labels && data.datasets && data.datasets.length > 0) {
      const config = {
        type: "pie",
        data: data,
      };

      // Create the pie chart
      const myChart = new Chart(chartRef.current, config);

      // Clean up on component unmount
      return () => {
        myChart.destroy();
      };
    }
  }, [data]); // Depend on data changes

  return (
    <div className="d-flex flex-column align-items-center">
    <div style={{ width: "100%", maxWidth: "800px" }}>
      <canvas ref={chartRef} style={{ width: "100%" }}></canvas>
      <h2 className="text-normal mt-4 text-color-next mb-2 text-center">
        {`${name} Statistics`}{" "}
      </h2>
    </div>
    <p className=" fw-bold text-capitalize m-0" > {`Total ${name} = ${total}`}</p>

    </div>
  );
};

export default PieChart;
