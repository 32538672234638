// documents
import { useEffect, useState } from "react";
import {  useNavigate, useParams } from 'react-router-dom';

import WebbLoader from "../webx/webb-loader-sm";
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import { ContractDetailsRead } from "../../services/srvc-contracts-realm";
import { CreateTemplateTask, GetTemplateDetails } from "../../services/srvc-template-realm";



export default function ContractAddInputModule() {

  const { id, tmid } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading]=useState(true);
  const [form, setForm] = useState(true);

  const [kycu,setKycu] = useState(false)
  const [kycb,setKycb] = useState(false)

  const [info, setInfo] = useState({}); // basic info
  const [data, setData] = useState([]); //format data
  const [viewdynamic,setViewdynamic]=useState(false)

  const [merge,setMerge] = useState(false)

  const [DynamicFieldsArr,setDynamicFieldsArr]= useState([])

  var dynamicFieldsArr=[]
  var AllkeysArr=[]
  var finalArr=[]
  var signerlist=[]

  useEffect( () => {
    if (id){
      const fetchData = async() => {
        
        // basic info
        var res = await ContractDetailsRead({data:{item:id}, srvc:''})
        setInfo(res.data);

        var res = await GetTemplateDetails({data:{item:tmid}, user: ''})
        // console.log(res.data)
        if(res.data!= false){setData(res.data)}
        else{setData([])}
        // setData(res.data) // form info

        setLoading(false);
      }
      fetchData()
    } else {}
  },[id]);

  // form validation
  useEffect( () => {
    setForm(false)
    if (data.form !=='') setForm(true);
  },[data]);

  const HandleFormInput = async(key,value) => {

    // console.log(key, value)
    var indx=key
    var obj={ type:indx }
    obj[indx]=value
    // console.log(obj)
    dynamicFieldsArr.push(obj);
    setDynamicFieldsArr([...DynamicFieldsArr,...dynamicFieldsArr])
  }


  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 

    // console.log(DynamicFieldsArr)
    DynamicFieldsArr.map(item=>{
      var keys= Object.keys(item)
      AllkeysArr.push(keys[1])
    })
 
    // console.log(AllkeysArr)
     
    var uniqueKeysArr=AllkeysArr.filter((x,i,a)=>a.indexOf(x)==i)
    // console.log(uniqueKeysArr)
    for(var i=0;i<uniqueKeysArr.length;i++) {
      var result=  DynamicFieldsArr.filter((x)=>x.type === uniqueKeysArr[i]);
      finalArr.push(result[result.length-1])
    }
    // console.log(finalArr)
     
    var obj1={}
    for(var i=0;i<finalArr.length;i++) {
      obj1={...obj1,...finalArr[i]}
    }
 
      // console.log(obj1)
    var listArr=[]
    listArr.push(obj1)
    
    var datx = {
      data:
        {
        "gdocid": data.nmbr,
        "document": id,
        "ekyc": merge,
        "kycu": kycu,
        "kycb": kycb,
        "list": listArr,
        "auto": false,
        "mode": "emid",
        "user": '',
        },
      user: ''
    }

    const res = await CreateTemplateTask(datx)
    console.log (res)
    if(res.stat){ navigate(`/team/contracts/details/${id}`)}
    else{ setLoading(false);}

  }

  if (loading){ return ( <> <WebbLoader /> </> ) }

  return (
  <>
    {/* info */}
    <WebbDividerSmall />
    <p className="text-lead text-bold m-0">{info.name}</p>
    <p className="text-color-tone">{id}</p>
    <WebbDividerSmall />
    <p className="text-bold">Please Enter Initial Document Data</p>
    
    {/* form-data */}
    <div className={`p-3 bg-white ${data.vars.length>0?'':'d-none'}`} style={{height:"430px", overflowY:"scroll"}}> 
      {
      data!=null && data.vars.length>0 && data.vars.map((item,i) => {
        return(
          <div key={i}>
            <p className="mb-1">{item.name}</p>
            <input className='form-control height-md rounded-none'
              onChange={(e)=>HandleFormInput(item.code,e.target.value)} 
              type="text" 
              placeholder={'...'}/>
            <br/>
         </div>
        )
      })
      }
      <div className={data && data.vars.length==0?'':'d-none'}>
        <p className="text-center text-warning">Sorry! We could not found dynamic fields in this template.</p>

      </div>
    </div>

    {/* KYC */}
    {/* <WebbDividerMedium /> */}
    {/* <p className="text-bold">Collect User KYC</p>
    
    <div className={`p-2 px-3 back-color-wite ${data.vars.length>0?'':'d-none'}`}>
      <div className="d-flex cursor" onClick={() => setKycu(!kycu)}>
        <div className="me-auto mt-1">Individual KYC</div>
        <div className="text-end">
          <i className={`bi text-lead ${kycu ? 'bi-check-circle-fill text-color-success' : 'bi-circle-fill text-color-lite'}`}></i>
        </div>
      </div>
    </div>
    <div className={`border-bottom border-light`}></div>
    <div className={`p-2 px-3 back-color-wite  ${data.vars.length>0?'':'d-none'}`}>
      <div className="d-flex cursor" onClick={() => setKycb(!kycb)}>
        <div className="me-auto mt-1">Business KYC</div>
        <div className="text-end">
          <i className={`bi text-lead ${kycb ? 'bi-check-circle-fill text-color-success' : 'bi-circle-fill text-color-lite'}`}></i>
        </div>
      </div>
    </div> */}


    {/* <WebbDividerMedium /> */}
    
    <div className={kycu || kycb ? '': 'd-none'}>

      <p className="text-bold">Document Options</p>

      <div className="border-bottom border-light"></div>
      <div className="p-2 px-3 back-color-wite">
        <div className="d-flex cursor" onClick={() => setMerge(!merge)}>
          <div className="me-auto mt-1">Update Document with KYC Data</div>
          <div className="text-end">
            <i className={`bi text-lead ${merge ? 'bi-check-circle-fill text-color-success' : 'bi-circle-fill text-color-lite'}`}></i>
          </div>
        </div>
      </div>

    </div>

    

    <WebbDividerMedium />

    {/* form */}
    <form onSubmit={handleSubmit}>

      <div className={data.vars.length>0?'':'d-none'}>
        <div className="d-grid">
          <button className={`btn height-md btn-primary back-color-main rounded-none border-none w-100`}
            disabled={!form}
          ><small>Select & Continue</small>
          </button>
        </div>
      </div>

    </form>
  
    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}