import React, { useState } from "react";
import FileCreateCX from "../webx/srvc-filecreate-cweb-xx";
import { AssetDocumentSave } from "../../services/srvc-assets-realm";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";

export default function AssetAddDocument() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const { item } = useParams();
  const [pdf, setPdf] = useState(null);
  const [name, setName] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const handleFile = async (item) => {
    setPdf(item.file);
  };

  const handleSubmit = async () => {
    setLoader(true);

    var datx = {
      asset: item,
      user: asset.item,
      type: "local",
      file: pdf,
      name: name,
      memo: " ",
    };
    var res = await AssetDocumentSave({ data: datx });
    if (res.stat) navigate(`/team/assets/${item}`);
    setLoader(false);
  };

  return (
    <div>
      <div>
        {loader ? (
          <div className="p-3  rounded-xd d-flex align-items-center  justify-content-center " style={{marginTop:"200px"}}>
          <p className="P-4 m-0 mx-4 fw-bold">Please wait ....</p>
            <div class="spinner-border" role="status">
            </div>
          </div>
        ) : (
          <div className="p-3 back-color-wite rounded-xd mt-2 ">
            <label>Document's Name:</label>
            <input
              type="text"
              className="border rounded w-100 mt-2 mb-4 p-1"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter Document Name"
            />
            <FileCreateCX media={handleFile} accept="application/pdf" />
            <div className="d-flex mt-3 justify-content-end">
              <div className="text-end mt-2">
                <button
                  onClick={() => handleSubmit()}
                  className={`btn btn-primary rounded-xx text-small ${
                    pdf === null ? "disabled" : ""
                  }`}
                  disabled={pdf === null}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
