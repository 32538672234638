import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useState } from "react";

import ContentFormat from "../content/webz/content-format-xv";

import WebbIcon from "../content/webx/webb-icon";
import WebbHeader from "../content/webz/webb-header-navs-xv";
import UserAvatar from "../content/webx/user-avatar";

import WebbFooterMobile from "../content/webz/webb-footer-mobile";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";
import WebbLoaderSmall from "../content/webx/webb-loader-sm";

import { GetUserForm } from "../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../services/srvc-auth-local";

import NetworkSearchModule from "../content/network/network-search";

import NetworkActionsModule from "../content/network/network-actions";
import NetworkUserListModule from "../content/network/network-user-list";
import UserInfocardModule from "../content/user/user-infocard";
import UserActionsModule from "../content/user/user-actions";
import UserOptionModule from "../content/user/user-options";
import WebbIconBack from "../content/webx/webb-icon-back";
import WebbDateTimeMedium from "../content/webx/webb-datetime";

export default function TeamDetails() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const navigate = useNavigate();

  const [refresh, setRefresh] = useState(false);
  const [detailselected, setDetailSelected] = useState({
    name: "Over View",
    text: "overview",
    icon: "bx bxs-user-detail",
    avtr: "",
    link: "network/overview",
    user: ["user", "team"],
    live: true,
    actv: true,
  });

  const metadata = {
    name: "Account Detail",
    banner: {
      link: "https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900",
    },
  };

  const refreshPage = async () => {
    setRefresh(!refresh);
  };

  const handleDetailSelected = async (item) => {
    setDetailSelected(item);
  };

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: "xtra", show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`/${asset.role}/home`}>
                <WebbIcon
                  data={{ color: "text-color-main", size: "text-icon-sm" }}
                />
              </Link>
            </>
          ),
          data: (
            <>
              {" "}
              <WebbHeader />{" "}
            </>
          ),
          footer: (
            <>
              <UserAvatar />
            </>
          ),
          mobile: (
            <>
              <div className="cursor" onClick={() => navigate(-1)}>
                <WebbIconBack
                  data={{ color: "text-color-tone", size: "text-icon-md" }}
                />
              </div>
            </>
          ),
        }}
        sidebar={{
          header: (
            <>
              <p className="text-normal text-bold m-0">{metadata.name}</p>
            </>
          ),
          data: (
            <>
              <UserOptionModule handleSelect={handleDetailSelected} />
            </>
          ),
          footer: (
            <>
              {" "}
              <WebbDateTimeMedium />
            </>
          ),
        }}
        content={{
          header: <>{/* <p className="m-0"> List</p> */}</>,
          data: (
            <>
              {/* <NetworkUserListModule  /> */}
              <UserInfocardModule type={detailselected} />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          ),
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: (
            <>
              {/* <NetworkActionsModule /> */}
              {/* <UserActionsModule/> */}
              <WebbDividerSmall />
            </>
          ),
          footer: <></>,
        }}
      ></ContentFormat>
    </>
  );
}
