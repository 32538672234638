// documents
import { useHistory, useNavigate } from "react-router-dom";
import ListNoData from "./list-nodata";



export default function DocumentMembersDelete(props) {


  const navigate = useNavigate();

  const data = props.data

  console.log(data)

  const handleCallback = (usid) => {
    // console.log(usid)
    props.item(usid);
  }

  if (data.length===0) return (<> <ListNoData /> </>)

  return (
  <>
    <div className={`border back-color-wite `}>
    {data && data.length > 0 && data.map((item, i) => (
      <div className="" key={i}>
      <div className="d-flex p-3" key={i}>
        <div className="fw-bold"><span>{i+1}.</span></div>
        <div className="ps-2">
          <p className="fw-bold m-0 p-0 text-sm">
            
            <span>{item?.name || '*****'}</span>
            <span className="ps-2"></span>
            <span className={`badge small rounded-pill back-color-tone ${item.usid === '' ? '' : 'd-none'}`}>
              {'You'}
            </span>
          </p>
          <p className="small m-0 p-0">{item?.emid || ''}</p>
        </div>
        <div className="ms-auto text-end" style={{width:'15%'}}>
          <button className="btn rounded-circle back-color-lite m-0 p-0  hilite"
            style={{width:'2.4rem', height:'2.4rem'}}
            onClick={() => handleCallback(item.usid)}  
          >
           <i className="bi-x"></i>
          </button>
        </div>
      </div>
      <div className={`border-bottom ${i < data.length-1 ? '': 'd-none'}`}></div>
      </div>
      ))}
    </div>
  </>
  )
}