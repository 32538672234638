import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import WebbDividerSmall from "./webb-divider-sm";
import FormNeeded from "./form-needed";
import {
  AssetsTransfersDecline,
  AssetsTransfersSubmit,
} from "../../services/srvc-transfers-assets-realm.js";
const TotalAssetTransaction = ({
  currentPage,
  totalPages,
  handlePageChange,
  currentItems,
  itemsPerPage,
  totalItem,
}) => {
  useEffect(() => {
    handlePageChange(currentPage);
  }, []);

  const handleApprove = async (item) => {
    var res = await AssetsTransfersSubmit({
      data: {
        item: item,
      },
    });

    if (res) {
      alert("Transaction approved....");
      handlePageChange();
    }
  };

  const handleDecline = async (item) => {
    var res = await AssetsTransfersDecline({
      data: {
        item: item,
      },
    });

    if (res) {
      alert("Transaction declined.....");
      handlePageChange();
    }
  };

  return (
    <>
      <div className="border rounded  border-none mb-4">
        {currentItems.map((item, index) => (
          <div key={item?.item}>
            <div
              className="d-flex justify-content-between bg-white p-4 "
              style={{ cursor: "pointer" }}
              data-bs-toggle="modal"
              data-bs-target={`#staticBackdrop${index}`}
            >
              <div>
                <p className="m-0 text-primary" style={{ fontWeight: "bold" }}>
                  {item?.user?.name || ""}
                </p>
                <p className="m-0 d-none" style={{ fontSize: "11px" }}>
                  {item?.user?.mail || ""}
                </p>
                <p className="m-0">{item?.meta?.memo || ""}</p>
                <p style={{ fontSize: "11px", margin: "0" }}>
                  {new Date(item?.created).toLocaleString(undefined, {
                    hour12: true,
                  })}{" "}
                </p>
              </div>
              <div>
                <div>
                  <div className="d-flex  gap-3 align-items-center justify-content-end mb-2">
                    <div className="d-flex gap-1">
                      <p className="m-0" style={{ fontWeight: "bold" }}>
                        {item?.sale?.number == 0
                          ? "0.00"
                          : item?.sale?.nmbr || ""}{" "}
                      </p>
                      <p className="m-0">{item?.sale?.tick || ""}</p>
                    </div>
                    <div
                      className={`${
                        item.status == 6 || item.status == "6"
                          ? "bg-success"
                          : item?.status == 3 ||
                            item?.status == "3" ||
                            item?.status == 4 ||
                            item?.status == "4" ||
                            item?.status == 7 ||
                            item?.status == "7" ||
                            item?.status == 8 ||
                            item?.status == "8" ||
                            item?.status == 9 ||
                            item?.status == "9"
                          ? "bg-danger"
                          : "bg-warning"
                      }`}
                      style={{
                        height: "10px",
                        width: "10px",
                        borderRadius: "50%",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal fade"
              id={`staticBackdrop${index}`}
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="staticBackdropLabel">
                      Detail
                    </h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div className="border back-color-wite p-2 px-3 rounded-xd pt-4 ">
                      <div>
                        <div className="d-flex  gap-4  justify-content-between">
                          <p> Name</p>
                          <p
                            style={{
                              minWidth: "50px",
                              marginLeft: "40px",
                              wordWrap: "break-word",
                            }}
                          >
                            {item?.user?.name || ""}
                          </p>
                        </div>
                        <div className="d-flex  gap-4  justify-content-between">
                          <p> Mail</p>
                          <p
                            style={{
                              minWidth: "50px",
                              marginLeft: "40px",
                              wordWrap: "break-word",
                            }}
                          >
                            {item?.user?.mail || ""}
                          </p>
                        </div>
                        <div className="d-flex  gap-4  justify-content-between">
                          <p> ID</p>
                          <p
                            style={{
                              minWidth: "50px",
                              marginLeft: "40px",
                              wordWrap: "break-word",
                            }}
                          >
                            {item?.user?.item || ""}
                          </p>
                        </div>
                        <div className="d-flex  gap-4  justify-content-between">
                          <p> Date</p>
                          <p
                            style={{
                              minWidth: "50px",
                              marginLeft: "40px",
                              wordWrap: "break-word",
                            }}
                          >
                            {new Date(item?.created).toDateString() || ""}
                          </p>
                        </div>
                        <div className="d-flex  gap-4  justify-content-between">
                          <p> Sale</p>
                          <p
                            style={{
                              minWidth: "50px",
                              marginLeft: "40px",
                              wordWrap: "break-word",
                            }}
                          >
                            <p className="m-0">
                              {item?.sale?.nmbr == 0
                                ? "0.00"
                                : item?.sale?.nmbr || ""}{" "}
                              {item?.sale?.tick || ""}
                            </p>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p>Status</p>
                        <p>
                          {item?.status == 0
                            ? "Pending"
                            : item?.status == 6
                            ? "Approved"
                            : "Cancelled"}
                        </p>
                      </div>

                      <div
                        className={`d-flex mt-3 ${
                          item?.status == 0 ? "" : "d-none"
                        }`}
                      >
                        <div className="me-auto">
                          <button
                            onClick={() => {
                              handleDecline(item?.item);
                            }}
                            className="btn btn-outline-danger rounded-xx text-small"
                          >
                            Decline
                          </button>
                        </div>
                        <div className="text-end mt-2">
                          <button
                            onClick={() => handleApprove(item?.item)}
                            className="btn btn-outline-success rounded-xx text-small  text-small"
                          >
                            Approve
                          </button>
                        </div>
                      </div>
                    </div>
                    <WebbDividerSmall />
                  </div>
                </div>
              </div>
            </div>
            {/* </Link> */}
            <hr className="m-0" />
          </div>
        ))}
      </div>
      <nav aria-label="Page navigation example">
        <ul
          className="pagination justify-content-between align-items-center
          "
        >
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <p
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              href="#"
            >
              <i className="bx bx-chevron-left"></i>
            </p>
          </li>

          <p className="m-0">
            {totalItem == 0
              ? totalItem
              : currentPage * itemsPerPage + 1 - itemsPerPage}{" "}
            -{" "}
            {currentPage * itemsPerPage > totalItem
              ? totalItem
              : currentPage * itemsPerPage}{" "}
            of {totalItem}
          </p>

          <li
            className={`page-item align-items-end ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <p
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              href="#"
            >
              <i className="bx bx-chevron-right"></i>
            </p>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default TotalAssetTransaction;
