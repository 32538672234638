import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AssetsDetail } from "../../services/srvc-assets-realm";
export default function   AssetActionModules() {
  const { item } = useParams();

  const [currentItems, setCurrentItems] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      var res = await AssetsDetail({
        data: {
          item: item,
        },
      });
      setCurrentItems(res?.data);
      setLoader(false);
    };

    fetchData();
  }, []);

  if (loader == true) {
    return <p>Please wait ....</p>;
  }
  if (!loader && currentItems.length == 0) {
    return <p>No items found</p>;
  }
  return (
    <>
      <div
        key={currentItems?.item||""}
        className="bg-white border rounded position-relative"
        style={{ width:"100%", marginBottom: "20px" }}
      >
        <img
          src={currentItems?.media?.link||""}
          style={{
            width: "100%",
            height: "200px",
            borderTopLeftRadius: "0.375rem",
            borderTopRightRadius: "0.375rem",
            objectFit:"cover"
          }}
          className="border"
          alt="img"
        />
        <p className="px-4 m-0 mt-4">{currentItems?.creator?.name||""}</p>
        <p
          className="px-4 fw-bold"
          style={{ fontSize: "20px", color: "slateblue" }}
        >
          {currentItems?.meta?.name||""}
        </p>
        <p
          className="px-4 m-0 mb-2"
          style={{
            maxHeight:"150px",
            overflowY:"scroll"
          }}
        >
          {currentItems?.meta?.memo||""}
        </p>
        <p className="px-4" style={{ marginBottom: "60px" }}>
          {" "}
          {currentItems?.active ? "" : "COMING SOON!"}
        </p>
        <div className="d-flex justify-content-between position-absolute bottom-0 w-100 mt-4 mb-3  p-4 pb-0">
          <div className="d-flex gap-4 ">
            {" "}
            <div className="d-flex gap-2 align-items-center">
              <i className="bx bxs-like"></i>
              <p className="m-0">{currentItems?.webb?.like||"0"}</p>
            </div>
            <div className="d-flex gap-2 align-items-center">
              <i className="bx bx-low-vision"></i>
              <p className="m-0">{currentItems?.webb?.view||"0"}</p>
            </div>
          </div>
          <div>
            <div className="d-flex gap-2 align-items-center">
              <i className="bx bxs-user"></i>
              <p className="m-0">{currentItems?.webb?.share||"0"}</p>
            </div>
          </div>
        </div>
      </div>
      <div className=" my-2">
      <div className="bg-white my-2  p-3 border rounded">
        <p className="fw-bold">Location</p>
        <div className="d-flex  gap-4  justify-content-between">
          <p> Name</p>
          <p
            style={{
              marginLeft: "40px",
              wordWrap: "break-word",
            }}
          >
            {currentItems.location.name}
          </p>
        </div>
        <div className="d-flex  gap-4  justify-content-between">
          <p className="m-0">Site</p>
          <p className="m-0"
            style={{
              marginLeft: "40px",
              wordWrap: "break-word"
            }}
          >
            {currentItems?.location?.site || ""}
          </p>
        </div>{" "}
            
        
          
      </div>
        </div>
    </>
  );
}
