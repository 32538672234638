import React, { useEffect, useState } from "react";
import {
  UserAccountDetails,
  UserActivate,
} from "../../services/srvc-users-realm";
import { useParams } from "react-router-dom";
import {
  CreatorAccountDetails,
  CreatorActivate,
} from "../../services/srvc-creator-realm";

export default function UserActionModule(props) {
  const { item, accounttype } = useParams();
  const [loader, setLoader] = useState(true);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentState, setCurrentState] = useState("");

  const fetchData = async () => {
    var res = await (accounttype === "user"? UserAccountDetails  : CreatorAccountDetails)({
      data: {
        user: item,
      },
      srvc: "******",
    });
    if(res){
      setCurrentItems(() => res.data);
      setCurrentState(() => res.data.active);
    }
    
    setLoader(false);
  };

  const fetchToggle = async () => {
    var res = await (accounttype == "user" ? UserActivate : CreatorActivate)({
      data: {
        user: item,
        status: currentState == true ? "deactivate" : "activate",
      },
      srvc: "******",
    });
    if (res.stat) {
      props.handleRefresh();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCheckboxChange = () => {
    setCurrentState(!currentState);
    fetchToggle();
  };

  return (
    <div>
      <div className=" d-flex gap-2 border p-3 bg-white w-100">
        <div className="d-flex justify-content-between w-100">
          <div className="me-auto">
            <p className="m-0">Account Status</p>
            {currentState === true
                ? "Active"
                : currentState === false
                ? "Inactive"
                : "Pending"}
          </div>
          <div className="text-end">
            <div className="d-flex  gap-2 form-check form-switch m-0 p-0 mb-2 ms-5 ">
              <input
              disabled
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem", margin: "0px" }}
                checked={currentState}
                onChange={() => handleCheckboxChange()}
              ></input>

              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
