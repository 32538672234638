// documents
import { useEffect, useState } from "react";
import {  useNavigate, useParams } from "react-router-dom";

import WebbLoader from "../webx/webb-loader-sm";
import FormNeeded from "../webx/form-needed";
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import HeaderSectionDark from "../webx/head-section-dark";

import DocumentBasicInfo from "../webx/docx-basicinfo";
import DocumentMembersDelete from "../webx/docx-members-delete";
import DocumentMembersSet from "../webx/docx-members-set";
import { ContractDetailsRead, ContractSetMembers } from "../../services/srvc-contracts-realm";
import WebbLoaderSmall from "../webx/webb-loader-sm";
import { SearchUserList } from "../../services/data-users-realm";




export default function ContractsEditMembersModule() {

  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading]=useState(true);
  const [form, setForm] = useState(false);
  
  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(10)

  const [info, setInfo] = useState({});
  const [list, setList] = useState([]);
  
  const [mmbr, setMembers] = useState([]);
  const [add, setAdd] = useState(false)

  const [show, setShow] = useState(true)
  const [reload,setRelod] = useState(false)

  const [teammembers, setTeammembers] = useState([])
  const [tmbx, setTmbx] = useState()
  const [search, setSearch] = useState('')
  const [searchloader, setSearchLoader] = useState(false)
  const [memo, setMemo] = useState('')



  useEffect( () => {
    setLoading(true)
    if (id){
      const fetchData = async() => {
        var res = await ContractDetailsRead({data:{item:id}, srvc:''})
        console.log(res)
        var mmbx =Array.from(res.data.members, item =>{ return ({name:item.name, emid:item.mail, usid:item.item})})
        setMembers(mmbx)
        setInfo(res.data)
        setLoading(false);
      }
      fetchData()
    } else {}
  },[ reload]);

  // form validation
  useEffect( () => {
    setForm(false)
    if (mmbr.length > 0 ) setForm(true);
  },[mmbr]);

  const SetMembers = async(mmbx) => {
    console.log(mmbx)
    setMembers([...mmbr, {name: mmbx.meta.name, emid: mmbx.coms[0].nmbr, usid: mmbx.item}])
    setSearch('')
    // setMembers([...mmbr, {...mmbx, rank: mmbr.length+1}])

    if(mmbr.user==''){setShow(false)}
  } 

  const ResetMembers = async(sort) => {
    // console.log (sort)
    var indx = mmbr.findIndex(item => item.usid === sort)
    // console.log (indx)
    if (indx>-1) {
      setMembers([...mmbr.slice(0,indx), ...mmbr.slice(indx+1)])
      // mmbr.forEach((item, i) => item.rank = i+1 )
    }
    
  }

  const SearchUser = async()=>{
    setSearchLoader(true)
    var datx = { user: id, index: index, items: items, search:{"search":search, "status":'active'} }
    var result = (await SearchUserList({data: datx, srvc: '******'}))
    console.log(result)
    setList(result.data.list)
    setSearchLoader(false)
  }

  if (loading){ return ( <> <WebbLoader /> </> ) }

  const handleChange = async(key, val) => {
    // setForm(false);
    
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
   
    var  person =[]
    for(var i=0;i<mmbr.length;i++)
      {
        var obj = {name:mmbr[i].name,mail: mmbr[i].emid,item: mmbr[i].usid,rank: `${i+1}`}
        person.push(obj)
      }

    var payload = {data: {item: id, member: person},user: ''}
    // console.log(payload)
    var res = await ContractSetMembers(payload)
    if(res.stat== true){
        navigate(`/team/contracts/details/${id}`)
    }
    else
    {
        setMemo('Error:')
        setLoading(false);
    }
  }


  const AddMyself = async()=>{
    setMembers([...mmbr, {name: '', emid: '', usid: ''}])
  }

  const setAddContact =async(item)=>{
    // console.log(item)
    setAdd(false)
    setRelod(true)


  }

  if (loading){ return ( <> <WebbLoader /> </> ) }
//   if (list.length===0 && teammembers.length==0 ) return (
//     <>
//       <WebbDividerMedium />
//       <div className="p-3 rounded-wd back-color-wite text-center">
//         <WebbDividerMedium />
//         <p className="m-0">Please add Contacts via Dashboard</p>
//         <WebbDividerMedium />
//       </div>
    
//     </>
//   )


  
  return (
  <>
    {/* info */}
    <WebbDividerSmall />
    <p className="text-lead text-bold m-0">{info.name}</p>
    <p className="text-color-tone">{id}</p>
  

    <div className="search member">
      <div className="d-flex">
        <div className="me-auto w-75">
          <input value={search} onChange={(e)=>setSearch(e.target.value)} style={{height:'40px'}} placeholder="search user email or user id" className="form-control" />
        </div>
        <div className="text-end" >
          <button onClick={()=>SearchUser()} style={{height:'40px'}} className="btn btn-sm back-color-wite border hitone p-2 px-2 rounded-wd text-color-next">Search</button>
        </div>

      </div>
    </div>
    <WebbDividerSmall />

    {
      searchloader?
      <>
        <WebbLoaderSmall />
      </>
      :
      <>
      <DocumentMembersSet data={list} load={loading} item={SetMembers}/>

      </>
    }




    <WebbDividerSmall/>
  
    <HeaderSectionDark data={{name: 'Approval / Signature Order'}}/>
    <DocumentMembersDelete data={mmbr} item={ResetMembers}/>
    {/* <WebbDividerMedium /> */}
  
    {/* <div className="d-flex">
      <div className={`me-auto`}>
        <button onClick={()=>AddMyself()} 
        className={`btn btn-sm back-color-wite border hitone p-2 px-3 rounded-wd text-color-next`}
        >Add Self</button>
      </div>
      <div class="form-check form-switch mt-2 text-end">
        <input value={tmbx} onChange={()=>setTmbx(!tmbx)} class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"  checked={tmbx}  />
        <label class="form-check-label" for="flexSwitchCheckChecked">{tmbx?'Teams':'Contact'}</label>
      </div>
    </div> */}
    <WebbDividerMedium />

    {/* <div className="d-flex mb-5 d-none">
      <div className="me-auto"><HeaderSectionLite data={{name: 'Add Signers'}}/></div>
      <div className="me-auto"><button onClick={()=>setAdd(!add)} 
        className={`btn btn-sm back-color-lite border hitone p-2 px-3 rounded-wd`}
        >New Contacts</button>
      </div>

      <div className="text-end">
      </div>  
        
    </div> */}

   

    <WebbDividerMedium />

    <div className={`${tmbx?'':'d-none'}`}>
      <DocumentMembersSet data={teammembers} load={loading} item={SetMembers}/>
    </div>
    <div className={`${tmbx?'d-none':''}`}>
      <DocumentMembersSet data={list} load={loading} item={SetMembers}/>
    </div>

    
    <form onSubmit={handleSubmit}>

      <div className="">
        <div className="d-grid">
          <button className={`btn height-md btn-primary back-color-main rounded-none w-100`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>

    </form>

    
    <WebbDividerMedium />
    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}


// {
//   add?
//   <>
//     <NewContactIndividualModule addContact = {setAddContact} />
//   </>:
//   <>
//    <DocumentMembersSet data={list} load={loading} item={SetMembers}/>
//    <form onSubmit={handleSubmit}>

//     <div className="">
//       <div className="d-grid">
//         <button className={`btn height-md btn-primary back-color-main rounded-none`}
//           disabled={!form}
//         ><small>Save & Continue</small>
//         </button>
//       </div>
//     </div>

//     </form>
//   </>
// }