import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AssetsList, {
  AssetsTransactions,
} from "../../services/srvc-transfers-assets-realm.js";
import TotalAssetTransaction from "../webx/assets-list";

export default function AssetsTransactionList() {
  const { item } = useParams();
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState({
    pending: true,
    all: false,
  });

  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      var res = await AssetsTransactions({
        data: {
          item: item,
          index: currentPage,
          items: itemsPerPage,
          status: data.pending ? "0" : "",
        },
      });

      setCurrentItems(res?.data?.list);
      setTotalItems(res?.data?.count);
      setLoader(false);
    };

    fetchData();
  }, [currentPage, data.pending, data.all]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const handleCheckboxChange = (key) => {
    let newData;

    if (key === "all") {
      newData = { ...data, all: true, pending: false };
    } else if (key === "pending") {
      newData = { ...data, all: false, pending: true };
    } else {
      newData = { ...data, [key]: !data[key] };
    }

    setData(newData);
  };

  if (loader) {
    return <p>Please wait ....</p>;
  }
  // console.log(data, "data");
  return (
    <>
      <div className="">
        {/* <div> */}
        <div className="d-flex gap-4 border rounded  border-none mb-4 p-4">
          <div className="d-flex align-items-center form-check form-switch m-0 p-0 mb-2 " style={{columnGap:"50px"}}>
            <div className="">
              <p className="m-0 p-0">All</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.all}
                onChange={() => handleCheckboxChange("all")}
              ></input>
            </div>
          </div>
          <div className="d-flex align-items-center form-check form-switch m-0 p-0 mb-2 " style={{columnGap:"50px"}}>
            <div className="">
              <p className="m-0 p-0">Pending</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.pending}
                onChange={() => handleCheckboxChange("pending")}
              ></input>
            </div>
          </div>
        </div>

        {currentItems.length > 0 ? (
          <TotalAssetTransaction
            currentItems={currentItems}
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            itemsPerPage={itemsPerPage}
            totalItem={totalItems}
            handleRefresh={handleRefresh}
          />
        ) : (
          <div className="border rounded p-4 "> No Transactions found.....</div>
        )}
      </div>
    </>
  );
}
