import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import AssetsCard from "../assets/assets-card";
import { AssetSearch, AssetsList } from "../../services/srvc-assets-realm";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";

export default function AssetList({ assetSearchData }) {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const navigate = useNavigate();
  const location = useLocation();
  const currentPageUrl =
    parseInt(new URLSearchParams(location.search).get("page")) || 1;

  const {
    realestate,
    land,
    residential,
    active,
    all,
    inactive,
    allstatus,
    searchButton,
    searchTerm,
  } = assetSearchData;
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(currentPageUrl);
  const [currentItems, setCurrentItems] = useState([]);
  const [loader, setLoader] = useState(true);
  const [totalItems, setTotalItems] = useState("");

  useEffect(() => {
    setCurrentPage(1);
  }, [
    realestate,
    land,
    residential,
    active,
    all,
    inactive,
    allstatus,
    searchButton,
    
  ]);

  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      var res = await AssetsList({
        data: {
          index: currentPage,
          items: itemsPerPage,
          owner:asset.item,
          status: active ? "active" : inactive ? "inactive" : "",
          type: realestate
            ? "realestate"
            : land
            ? "land"
            : residential
            ? "residential"
            : "",
        },
      });

      if (searchButton == false && res?.data?.list) {
        setCurrentItems(res?.data?.list);
        setTotalItems(res?.data?.count);
      }

      if (res) {
        setLoader(false);
      }
    };

    if (!searchButton) {
      fetchData();
    }
  }, [
    realestate,
    land,
    residential,
    all,
    active,
    inactive,
    allstatus,
    currentPage,
    searchButton,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);

      var res = await AssetSearch({
        data: {
          search: {
            search: searchTerm,
            status: active ? "active" : inactive ? "inactive" : "",
            type: realestate
            ? "realestate"
            : land
            ? "land"
            : residential
            ? "residential"
            : "",
          },
          owner:asset.item,
          index: currentPage,
          items: itemsPerPage,
        },
      });

      if(res?.data?.list){
   setCurrentItems(res?.data?.list);
      setTotalItems(res?.data?.count);
      }
   
      if (res) {
        setLoader(false);
      }
    };

    if (searchButton == true) {
      fetchData();
    }
  }, [searchButton, currentPage]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loader == true) {
    return <p>Please wait ....</p>;
  }
  if (!loader && currentItems.length == 0) {
    return <p>No items found</p>;
  }

  return (
    <div>
      <AssetsCard
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        currentItems={currentItems}
        itemsPerPage={itemsPerPage}
        totalItem={totalItems}
      />
    </div>
  );
}
