import React, { useState, useEffect } from "react";
import { UsersAccountsList } from "../../services/srvc-transfers-realm";

export default function UserAccounts({ type, item }) {
  const [loader, setLoader] = useState(true);
  const [currentItems, setCurrentItems] = useState([]);
  const [expandedItemIndex, setExpandedItemIndex] = useState(-1);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);

      var res = await UsersAccountsList({
        data: {
          user: "cc26487e45c249e682625b87e473752a4",
          // user:item
        },
      });
      console.log(res, "res for accounts");
      setCurrentItems(res?.data?.list);
      setLoader(false);
    };
    fetchData();
  }, []);

  const handleItemClick = (index) => {
    setExpandedItemIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  return (
    <div className={type.text === "accounts" ? "" : "d-none"}>
      <h5 className="">Accounts</h5>
      {loader ? (
        <p>Please wait ....</p>
      ) : currentItems.length === 0 ? (
        <p>No items found</p>
      ) : (
        currentItems.map((item, index) => (
          <div
            onClick={() => handleItemClick(index)}
            style={{ cursor: "pointer" }}
            className="border rounded  border-none mb-4"
            key={index}
          >
            <div className="d-flex justify-content-between bg-white p-4 ">
              <div>
                <p className="m-0 text-primary" style={{ fontWeight: "bold" }}>
                  {item?.account?.name||""}
                </p>
                <p className="m-0">
                  {item?.account?.form == "base account"
                    ? "Minilnad"
                    : item?.account?.branch}
                </p>
                <p className="m-0">{item.account.form}</p>
                <p className="m-0 " style={{ fontSize: "11px" }}>
                  {item.account.number}
                </p>
              </div>
              <div>
                <div>
                  <div className="d-flex  gap-3 align-items-center mb-2">
                    <div className="d-flex gap-1">
                      <p className="m-0" style={{ fontWeight: "bold" }}>
                        {item.balance.amount}
                      </p>
                      <p className="m-0">{item.balance.ticker}</p>
                    </div>
                    <div
                      className={`${item.active ? "bg-success" : "bg-danger"}`}
                      style={{
                        height: "10px",
                        width: "10px",
                        borderRadius: "50%",
                      }}
                    ></div>
                  </div>
                  <p style={{ fontSize: "11px", textAlign: "end" }}>
                    {new Date(item.created).toDateString()}
                  </p>
                </div>
              </div>
            </div>
            {expandedItemIndex === index && (
              <div className="accordion-content p-4">
                <div className="d-flex">
                  <p className="me-auto w-25 small ">Bank branch</p>
                  <p className="text-end w-75 small">{item.bank.branch}</p>
                </div>
                <div className="d-flex">
                  <p className="me-auto w-25 small ">Bank name</p>
                  <p className="text-end w-75 small">{item.bank.name}</p>
                </div>
                <div className="d-flex">
                  <p className="me-auto w-25 small ">Modified</p>
                  <p className="text-end w-75 small">
                    {new Date(item.modified).toDateString()}
                  </p>
                </div>
                <div className="d-flex">
                  <p className="me-auto w-25 small ">User mobile</p>
                  <p className="text-end w-75 small">{item.meta.mobile}</p>
                </div>
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
}
