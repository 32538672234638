//format
export default function ContentFormat({
  header,
  sidebar,
  media,
  content,
  actionbar,
  footer,
  name,
}) {
  // console.log (header, content, footer, name)
  return (
    <>
      <div className="sticky-top d-none d-md-block">
        <div className="container-fluid" style={{ height: "100vh" }}>
          <div className="row flex-nowrap">
            {/* header */}
            <div
              className="p-0 d-none d-lg-block border-end"
              style={{ width: "4.2rem" }}
            >
              <div
                className="d-flex align-items-center flex-column"
                style={{ height: "100vh" }}
              >
                <div className="">
                  <div className="p-3 pb-2">{header.header}</div>
                  <div className="mx-1 mb-2 border-bottom"></div>
                </div>

                <div className="p-3 pt-0" style={{ overflowY: "auto" }}>
                  {header.data}
                </div>

                <div className="mt-auto p-3">{header.footer}</div>
              </div>
            </div>

            {/* sidebar */}
            <div
              className="p-0 d-none d-md-block bg-body-tertiary border-end"
              style={{ width: "21%" }}
            >
              <div className="d-flex flex-column" style={{ height: "100vh" }}>
                <div className="p-3">
                  <div className="" style={{ height: "1.4rem" }}>
                    {sidebar.header}
                  </div>
                </div>

                <div className="p-3" style={{ overflowY: "auto" }}>
                  {sidebar.data}
                </div>

                <div className="mt-auto p-3">{sidebar.footer}</div>
              </div>
            </div>

            {/* content */}
            <div
              className="col p-0 back-color-wite"
              style={{ width: "calc(100% - 45% - 4rem)" }}
            >
              <div className="d-flex flex-column" style={{ height: "100vh" }}>
                <div className="p-3 align-self-stretch border-bottom">
                  <div className="" style={{ height: "1.4rem" }}>
                    {content.header}
                  </div>
                </div>

                <div className="p-3" style={{ overflowY: "auto" }}>
                  {content.data}
                </div>
              </div>
            </div>

            {/* actionbar */}
            <div
              className="p-0 d-none d-md-block bg-body-tertiary border-start "
              style={{ width: "24%" }}
            >
              <div className="d-flex flex-column" style={{ height: "100vh" }}>
                <div className="p-3 border-bottom">
                  <div className="" style={{ height: "1.4rem" }}>
                    {actionbar.header}
                  </div>
                </div>

                <div className="p-3" style={{ overflowY: "auto" }}>
                  {actionbar.data}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* media */}

      {/* format small */}
      <div className="d-block d-md-none">
        <div className="col flex-nowrap">
          <div className="d-flex justify-content-between align-items-center py-2 px-4">
            <div className="">{header.mobile}</div>
            <div className="">{sidebar.header}</div>
            <div className="">{header.footer}</div>
          </div>
          <div
            className="container-fluid d-flex flex-column"
            style={{ height: "calc(100vh - 64px)" }}
          >
            {/* sidebar */}
            <div className="col flex-nowrap flex-grow-1">
              <div
                className="col p-0 bg-body-tertiary border-end"
                style={{ maxWidth: "100%", overflowY: "auto" }}
              >
                <div className="d-flex flex-column h-100">
                  <div className="px-2 pt-1">{sidebar.data}</div>
                  <div className="mt-auto">{actionbar.mobileActions}</div>
                </div>
              </div>
              {/* content */}
              <div
                className="col p-0 back-color-wite d-flex flex-column"
                style={{ overflowY: "auto" }}
              >
                <div className="ps-2">{content.header}</div>
                <div className="p-3 flex-grow-1 d-flex flex-column">
                  {content.data}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
