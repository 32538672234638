// documents
import { Link, useHistory, useNavigate, useParams } from 'react-router-dom';


const list = require('../../data.static/list-smrt-actions.json').data.filter(item=>item.actv)

export default function ContractActions(props) {
  
  // console.log(props) 
    
  const {id} = useParams();
  const navigate = useNavigate();
  
  const data = props.data.docx;
  const lstx = list.filter(item => item.user.includes("in"))
  // var idlist = props.data.idlist

  //  console.log(data)

  var admin = data.user.filter(item=>{
        return item.role=="admin"
  }) 

  // console.log(admin)

  const handleEsin = async() => {
    console.log("hit")
    if (props.data.mmbr.length == 0) {
      alert('Please add Signing Parties');
    }
    if (props.data.file == false) {
      alert('Please add Files');
    }
    if (props.data.mmbr.length > 0 && props.data.file.base != '') {
      navigate(`/team/contracts/send/${id}`);
    }
  }
 
  const handleMembers = async() => {
    navigate(`/team/contracts/edit/mmbr/${id}`)
  }

  const handleEstm = async() => {
    navigate(`/smart/add/stamp/${id}`)
  }
  
  const handleStampList= async() => {
    navigate(`/smart/add/stamp/${id}`)
  }

  const handleFiles = async() => {
    navigate(`/team/contracts/add/file/${id}`)
  }

  const handleTime = async() => {
    navigate(`/smart/v/track/${id}`)
  }

  const handleRoom = async() => {
    console.log ('discuss')
    navigate(`/rooms/${id}`)
  }

  const handleFeatures = async() => {
    // history.push(`/${asset.form.substring(0,2)}/documents/edit/feat/${id}`)
  }

  const handleArchive = async() => {
    console.log ('archive')
  }
  
  const handleButton = async(code) => {

    switch(code) {
      case 'esin':
        handleEsin();
        break;
      case 'estm':
        handleEstm();
        break;
      case 'file':
        handleFiles();
        break;
      case 'members':
        handleMembers();
        break;
      case 'estmlist':
        handleStampList();
        break;
      case 'file':
        // handleFiles();
        break;        
      case 'time':
        handleTime();
        break;        
      case 'room':
        handleRoom();
        break;
      case 'feat':
        handleFeatures();
        break;        
      case 'arch':
        handleArchive();
        break;
      default:
        // code block
    }

  }


  return (
    <>
      <div className="back-color-wite rounded-wd ">

        <div className={`row row-cols-5 g-0`} >
        {lstx && lstx.length>0 && lstx.map ((item, i) => 
          <div className="col d-grid"   key={i}  
            style={{pointerEvents: item.stat.includes(data.status) ? "all" : "none"}} >
            <div
              className={`${admin[0].usid == 'satyaverma0075@gmail.com' ? "":""}  rounded-wd text-center m-2 py-2 hilite text-color-${item.stat.includes(data.status)?"main":"lite"}`}
              onClick={()=> handleButton(item.code)} 
              style={{cursor:item.stat.includes(data.status) ? 'pointer': ''}}
              >
                <i className={item.icon}
                  style={{fontSize:'2rem'}}></i>
                <p className="m-0 p-0 text-color-tone text-sm"><small>{item.name}</small></p>
            </div>
          </div>

        )}
        </div>
  
      </div>
    </>
    )
  }