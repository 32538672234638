
import React, { useState } from "react";
import AssetsUnitList from "./assets-unit-list";
import AssetGallerySection from "./asset-gallery-section";
import AssetDocumentsSection from "./asset-documents-section";
import AssetsTransactionList from "./assets-transaction-list";
import AssetUserListModule from "./assets-user-list";

export default function AssetDetail() {
  const [activeTab, setActiveTab] = useState("unitList");

  const renderTabContent = () => {
    switch (activeTab) {
      case "gallery":
        return <AssetGallerySection />;
      case "documents":
        return <AssetDocumentsSection />;
      case "unitList":
        return <AssetsUnitList />;
      case "transactionList":
        return <AssetsTransactionList />;
        case "userList":
        return <AssetUserListModule/>
      default:
        return null;
    }
  };

  return (
    <div>
      <ul className="nav nav-tabs">
      <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "unitList" && "active"} fw-bold`}
            onClick={() => setActiveTab("unitList")}
          >
            Units
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link fw-bold ${activeTab === "userList" && "active"}`}
            onClick={() => setActiveTab("userList")}
          >
            Users
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link fw-bold ${activeTab === "transactionList" && "active"}`}
            onClick={() => setActiveTab("transactionList")}
          >
            Transfers
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link fw-bold ${activeTab === "gallery" && "active"}`}
            onClick={() => setActiveTab("gallery")}
          >
            Gallery
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link fw-bold ${activeTab === "documents" && "active"}`}
            onClick={() => setActiveTab("documents")}
          >
            Documents
          </button>
        </li>
       
       
      </ul>
      <div className="tab-content mt-4">
        {renderTabContent()}
      </div>
    </div>
  );
}
