import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import FileCreateCX from "../webx/srvc-filecreate-cweb-xx";

import dist from "react-jazzicon";
import { AssetCreate } from "../../services/srvc-assets-realm";
import { CreatorList } from "../../services/srvc-creator-realm";
import FormNeeded from "../webx/form-needed";

export default function AssetsCreateModule() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [memo, setMemo] = useState("");

  // const [selectCreator, setSelectCreator] = useState(true);
  const [img, setImg] = useState(false);
  const [info, setInfo] = useState(true);
  const [addr, setAddr] = useState(false);
  const [progress, setProgress] = useState("0%");
  const [source, setSource] = useState({
    actv: false,
    name: "",
    memo: "",
    number: "",
    link: "",
    item: "",
  });
  const [feat, setFeat] = useState({
    actv: false,
    roi: "9",
    lockin: "15",
    ticket: "100000",
    modle: "sale",
  });
  const [social, setSocial] = useState({
    actv: false,
    site: "",
    lnkd: "",
    twtr: "",
    fcbk: "",
    inst: "",
  });
  const [creator, setCreator] = useState([]);

  const [image, setImage] = useState(null);
  const [data, setData] = useState({
    name: "",
    memo: "",
    location: "",
    image: "",
    form: "",
    link: "",
    state: "",
    stateId:"",
    dist: "",
    city: "",
    creator: asset.item,
    srvc: asset.srvc,
  });
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.countrystatecity.in/v1/countries/IN/states",
          {
            method: "GET",
            headers: {
              "X-CSCAPI-KEY":
                "VXRWdm5uZWx3Mkx4N2xJMXd3emVBMm43RTNSZFRWbnE5c2ZWZThCbg==",
            },
            redirect: "follow",
          }
        );
        const result = await response.json();
        setStates(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        if (data.stateId !== "") { 
        const response = await fetch(
          `https://api.countrystatecity.in/v1/countries/IN/states/${data.stateId}/cities`,
          {
            method: "GET",
            headers: {
              "X-CSCAPI-KEY":
                "VXRWdm5uZWx3Mkx4N2xJMXd3emVBMm43RTNSZFRWbnE5c2ZWZThCbg==",
            },
            redirect: "follow",
          }
        );
        const result = await response.json();
        setCities(result);
      }} catch (error) {
        console.error("Error fetching cities:", error);
      }
    }

    if (data.state !== "") {
      fetchCities();
    }
  }, [data.stateId]);


  const handleClick = async (item) => {
    navigate(`/${asset.role}/ax/${item}`);
  };

  const handleSubmit = async () => {
    setMemo("Please Wait...");

    var datx = {
      creator: data.creator,
      name: data.name,
      memo: data.memo,
      domain: { name: data.form, sort: "land" },
      location: {
        state: data.state,
        district: data.dist,
        city: data.city,
        name: data.location,
      },
      social: social,
      feat: feat,
      source: source,
      media: { link: null, image: data.image },
      srvc: data.srvc,
    };
    var res = await AssetCreate({ data: datx });
    if (res.stat) navigate("/team/assets");
    else {
      setMemo("Error: Server Error");
    }
  };

  const handleFile = async (item) => {
    setImage(item.file);
    setData({ ...data, image: item.file });
  };

  const handlePrevNext = async (item) => {
    if (item == "start") {      setImg(false);
      setAddr(false);
      setInfo(true);
      setSource({ ...source, actv: false });
      setFeat({ ...feat, actv: false });
      setSocial({ ...social, actv: false });
      setProgress("0%");
    }
    
    if (item == "information") {
      setImg(false);
      setAddr(false);
      setInfo(false);
      setSource({ ...source, actv: false });
      setFeat({ ...feat, actv: true });
      setSocial({ ...social, actv: false });
      setProgress("18%");
    }

    if (item == "feat") {
      setImg(false);
      setAddr(false);
      setInfo(false);
      setSource({ ...source, actv: true });
      setFeat({ ...feat, actv: false });
      setSocial({ ...social, actv: false });
      setProgress("35%");
    }
    if (item == "source") {
      setImg(false);
      setAddr(true);
      setInfo(false);
      setSource({ ...source, actv: false });
      setFeat({ ...feat, actv: false });
      setSocial({ ...social, actv: false });
      setProgress("52.5%");
    }
    if (item == "address") {
      setImg(true);
      setAddr(false);
      setInfo(false);
      setSource({ ...source, actv: false });
      setFeat({ ...feat, actv: false });
      setSocial({ ...social, actv: false });
      setProgress("70%");
    }

    if (item == "image") {
      setImg(false);
      setAddr(false);
      setInfo(false);
      setSource({ ...source, actv: false });
      setFeat({ ...feat, actv: false });
      setSocial({ ...social, actv: true });
      setProgress("87%");
    }

    if (item == "social") {
      setProgress("100%");
      setSubmit(true);
      handleSubmit();
    }
  };
  if (loader == true) {
    return <p>Please wait ....</p>;
  }

  return (
    <>
      {/* data */}
      <div className="mb-3 mx-3 ">
        <div className="row">
          <div className="col-2 col-md-2 col-lg-2 fw-bold "></div>
          <div className="col-2 col-md-2 col-lg-2 text-start fw-bold">1</div>
          <div className="col-2 col-md-2 col-lg-2 text-start fw-bold">2</div>
          <div className="col-2 col-md-2 col-lg-2 text-start fw-bold">3</div>
          <div className="col-2 col-md-2 col-lg-2 text-start fw-bold">4</div>
          <div className="col-2 col-md-2 col-lg-2 text-start fw-bold">5</div>
        </div>

        <div class="progress" style={{ height: "0.50rem" }}>
          <div
            class="progress-bar progress-bar-striped progress-bar-animated back-color-success"
            role="progressbar"
            aria-label="Basic example"
            style={{ width: progress }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>

        <div
          className={info ? "border rounded p-2 px-4 bg-white mt-4" : "d-none"}
        >
          <p className="text-center fw-bold">Enter Asset Details</p>
          <label className="text-small mt-3">Asset Name</label> <FormNeeded />
          <input
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
            className="form-control mb-1"
          />
          <label className="text-small mt-3">Asset Description</label>
          <FormNeeded />
          <textarea
            value={data.memo}
            style={{height:"100px"}}
            onChange={(e) => setData({ ...data, memo: e.target.value })}
            className="form-control mb-1"
          ></textarea>
          <label className="text-small mt-3"> Asset Type</label>
          <FormNeeded />
          <select
            onChange={(e) => setData({ ...data, form: e.target.value })}
            className="form-select mb-1"
          >
            <option className="text-small" value={""}>
              Select Asset Type
            </option>
            <option className="text-small" value={"residential"}>
              Residential
            </option>
            <option className="text-small" value={"agricultural"}>
              Agricultural
            </option>
            <option className="text-small" value={"industrial"}>
              Industrial
            </option>
            <option className="text-small" value={"realestate"}>
              Real Estate
            </option>
          </select>
          <div className="d-flex mt-4 ">
            <div className="me-auto">
              {/* <button
                onClick={() => navigate("/team/assets")}
                className="btn btn-light rounded-xx text-small"
              >
                Back
              </button> */}
            </div>
            <div className="text-end">
              <button
                className={`btn btn-primary rounded-xx text-small ${
                  !data.name || !data.memo || !data.form ? "disabled" : ""
                }`}
                onClick={() => handlePrevNext("information")}
              >
                Continue
              </button>
            </div>
          </div>
        </div>

        <div
          className={
            feat.actv ? "border rounded p-2 px-4 bg-white mt-4 " : "d-none"
          }
        >
          <p className="text-center fw-bold">Enter Asset Features</p>

          <label className="text-small">Model</label>
          <input
            value={feat.modle}
            onChange={(e) => setFeat({ ...feat, modle: e.target.value })}
            className="form-control"
          />

          <label className="text-small mt-3">Rate Of Interest (ROI)</label>
          <input
            value={feat.roi}
            onChange={(e) => setFeat({ ...feat, roi: e.target.value })}
            className="form-control"
          />

          <label className="text-small mt-3">Lock In Period (Months)</label>
          <input
            value={feat.lockin}
            onChange={(e) => setFeat({ ...feat, lockin: e.target.value })}
            className="form-control"
          />

          <label className="text-small mt-3">Ticket Size </label>
          <input
            value={feat.ticket}
            onChange={(e) => setFeat({ ...feat, ticket: e.target.value })}
            className="form-control"
          />

          <div className="d-flex mt-3">
            <div className="me-auto">
              <button
                onClick={() => {
                  handlePrevNext("start");
                }}
                className="btn btn-light rounded-xx  text-small"
              >
                Back
              </button>
            </div>
            <div
              disabled={!data.state || !data.dist || !data.address}
              className="text-end"
            >
              <button
                onClick={() => {
                  handlePrevNext("feat");
                }}
                className="btn btn-primary rounded-xx  text-small"
              >
                Continue
              </button>
            </div>
          </div>
        </div>

        <div
          className={
            source.actv ? "border rounded p-2 px-4 bg-white mt-4 " : "d-none"
          }
        >
          <p className="text-center fw-bold">Enter Source Details</p>

          <label className="text-small">Source Name</label>
          <input
            value={source.name}
            onChange={(e) => setSource({ ...source, name: e.target.value })}
            className="form-control"
          />

          <label className="text-small mt-3">Description</label>
          <textarea
            value={source.memo}
            onChange={(e) => setSource({ ...source, memo: e.target.value })}
            className="form-control"
          ></textarea>

          <label className="text-small mt-3">Number</label>
          <input
            value={source.number}
            onChange={(e) => setSource({ ...source, number: e.target.value })}
            className="form-control"
          />

          <label className="text-small mt-3">Link</label>
          <input
            value={source.link}
            onChange={(e) => setSource({ ...source, link: e.target.value })}
            className="form-control"
          />

          <div className="d-flex mt-3">
            <div className="me-auto">
              <button
                onClick={() => {
                  handlePrevNext("information");
                }}
                className="btn btn-light rounded-xx text-small"
              >
                Back
              </button>
            </div>
            <div
              disabled={!data.state || !data.dist || !data.address}
              className="text-end"
            >
              <button
                onClick={() => {
                  handlePrevNext("source");
                }}
                className="btn btn-primary rounded-xx  text-small"
              >
                Continue
              </button>
            </div>
          </div>
        </div>

        <div
          className={addr ? "border rounded p-2 px-4 bg-white mt-4 " : "d-none"}
        >
          <p className="text-center fw-bold">Enter Asset Address</p>

          <label className="text-small">State</label>
         
          <input
            list="browsers"
            name="myBrowser"
            value={data.state}
            onChange={(e) => {
              const selectedStateName = e.target.value;
              const selectedState = states.find(state => state.name === selectedStateName);
              if (selectedState) {
                setData({ ...data, state: selectedStateName, stateId: selectedState.iso2 });
              } else {
                setData({ ...data, state: selectedStateName, stateId: "" });
              }
            }}
            id="myBrowser"
            className="form-control"
          />
          <datalist id="browsers">
            {states.map((state) => (
              <option key={state.id} value={state.name} >
                {state.name}
              </option>
            ))}
          </datalist>

          <label className="text-small mt-3">District</label>
         
            <input
            list="districts"
            name="mydistricts"
            value={data.dist}
            onChange={(e) => setData({ ...data, dist: e.target.value })}
            id="mydistricts"
            className="form-control"
          />
          <datalist id="districts">
            
            {cities.map((district) => (
              <option key={district.id} value={district.name}>
                {district.name}
              </option>
            ))}
          </datalist>

          <label className="text-small mt-3">City</label>
          


<input
            list="cities"
            name="mycities"
            value={data.city}
            onChange={(e) => setData({ ...data, city: e.target.value })}
            id="mycities"
            className="form-control"
          />
          <datalist id="cities">
            {cities.map((city) => (
              <option value={city.name} />
            ))}
          </datalist>

          <label className="text-small mt-3">Location Name</label>
          <input
            value={data.location}
            onChange={(e) => setData({ ...data, location: e.target.value })}
            className="form-control"
          />

          <div className="d-flex mt-3">
            <div className="me-auto">
              <button
                onClick={() => {
                  handlePrevNext("feat");
                }}
                className="btn btn-light rounded-xx  text-small"
              >
                Back
              </button>
            </div>
            <div
              disabled={!data.state || !data.dist || !data.address}
              className="text-end"
            >
              <button
                onClick={() => {
                  handlePrevNext("address");
                }}
                className="btn btn-primary rounded-xx  text-small"
              >
                Continue
              </button>
            </div>
          </div>
        </div>

        <div className={img ? "" : "d-none"}>
          <div className="p-2 px-4 back-color-wite rounded-xd mt-2 ">
            <FileCreateCX
              media={handleFile}
              accept="image/png, image/jpg, image/jpeg"
            />
            <div className="d-flex mt-3">
              <div className="me-auto">
                <button
                  onClick={() => {
                    handlePrevNext("source");
                  }}
                  className="btn btn-light rounded-xx text-small"
                >
                  Back
                </button>
              </div>
              <div className="text-end mt-2">
                <button
                  disabled={image == null}
                  onClick={() => handlePrevNext("image")}
                  className="btn btn-primary rounded-xx text-small  text-small"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            social.actv ? "border rounded p-2 px-4 bg-white mt-4 " : "d-none"
          }
        >
          <p className="text-center fw-bold">Enter Social Site </p>

          <label className="text-small">Website</label>
          <input
            value={social.site}
            onChange={(e) => setSocial({ ...social, site: e.target.value })}
            className="form-control"
          />

          <label className="text-small mt-3">LinkedIn</label>
          <input
            value={social.lnkd}
            onChange={(e) => setSocial({ ...social, lnkd: e.target.value })}
            className="form-control"
          />

          <label className="text-small mt-3">Twitter (X)</label>
          <input
            value={social.twtr}
            onChange={(e) => setSocial({ ...social, twtr: e.target.value })}
            className="form-control"
          />

          <label className="text-small mt-3">Instagram</label>
          <input
            value={social.inst}
            onChange={(e) => setSocial({ ...social, inst: e.target.value })}
            className="form-control"
          />

          <label className="text-small mt-3">Facebook</label>
          <input
            value={social.fcbk}
            onChange={(e) => setSocial({ ...social, fcbk: e.target.value })}
            className="form-control"
          />

          <p className="text-primary text-small mt-2 text-center">{memo}</p>

          <div className="d-flex mt-3">
            <div className="me-auto">
              <button
                onClick={() => {
                  handlePrevNext("address");
                }}
                disabled={submit}
                className="btn btn-light rounded-xx text-small"
              >
                Back
              </button>
            </div>
            <div
              disabled={!data.state || !data.dist || !data.address}
              className="text-end"
            >
              <button
                disabled={submit}
                onClick={() => {
                  handlePrevNext("social");
                }}
                className="btn btn-primary rounded-xx text-small"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
