import React, { useEffect, useState } from "react";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { ContactList } from "../../services/srvc-creator-realm";
import { TransfersCreate } from "../../services/srvc-transfers-funds-realm";

export default function TransfersCreateTransfer() {

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [contact, setContact] = useState([])
  const [cred, setCred] = useState()
  const [amount, setAmount] = useState('0')
  const [remark, setRemark] = useState('Amount Transfer')
  const [memo, setMemo] = useState({text:'', color:''})
  const [submit, setSubmit] = useState(false)


  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };



  useEffect(()=>{

    const fetchdata = async()=>{
      var datx =  {
        team: asset.item,
        search: {
          status:  "active" ,
        },
        index: 1,
        items: 50,
      }
    
      var res = await ContactList({data:datx})
      // console.log(res)
      setContact(res.data.list)
    }

    fetchdata()

  },[])

  const handleCreditUser = async(item)=>{
    var obj =  contact.find(entry => entry.item === item)
    setCred({name:obj.meta.name, mail:obj.meta.mail, mobile:obj.meta.mobile, item:obj.user})
  }

  const handleSubmit = async()=>{

    setSubmit(true)
    setMemo({text:'Please wait...', color:'warning'})
    var datx ={
      credit: { name:cred.name, mail: cred.mail, item: cred.item },
      debit: { name:asset.name,mail: asset.mail, item: asset.item},
      sale: { number:amount},
      trxn: {sort: "transfers", format:'funds'},
      meta: { name:"Funds.Transfer", memo: remark}
    }
    console.log(datx)
    var res = await TransfersCreate({data: datx, srvc:'******'})
    console.log(res)
    if(res.stat) setMemo({text:'Transfer Successfull', color:"success"})
    else{
      setMemo({text:'Transfer Failed', color:"danger"})
    }
    
  }



  return (
    <div className="container">
      <div
        className="form-container "
        style={{
          margin: "50px auto",
          backgroundColor: " #ffffff",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: " 0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h6 className="text-center">Create Transfer</h6>
        <div className="form-group my-3">
          <div className=" mt-2">
            <div className="me-auto">
              <label >Credit User</label>
              <select onChange={(e)=> handleCreditUser(e.target.value)} className="form-select mt-1">
                {
                  contact && contact.map((item, i)=>{
                    return(
                      <>
                        <option value={item.item} >{item?.meta?.name}</option>
                      </>
                    )
                  })
                }
              </select>
            </div>
          </div>

          <div className="mt-2">
            <div className="me-auto">
              <label>Debit User</label>
              <select disabled className="form-select mt-1">
                <option className="disabled">{asset.name}</option>
                
              </select>
            </div>
            
          </div>
           
        </div>
        
        <div className="form-group my-3">
          <label htmlFor="amount">Amount:</label>
          <input
            value={amount}
            onChange={(e)=>setAmount(e.target.value)}
            type="number"
            className="form-control mt-2"
            id="amount"
            placeholder="Enter amount"
            style={{ width: "100%" }}
          />
        </div>

        <div className="form-group my-3">
          <label htmlFor="amount">Remark:</label>
          <input
            value={remark}
            onChange={(e)=>setRemark(e.target.value)}
            type="text"
            className="form-control mt-2"
            placeholder="Transfer Remark"
            style={{ width: "100%" }}
          />
        </div>

        <p className={`text-small text-center mt-2 text-${memo.color} `}>{memo.text}</p>

        <button
          
          className="btn btn-primary rounded-xx text-small  mt-5  w-100"
          style={{ alignContent: "end", width:'auto' }}
          onClick={()=>handleSubmit()}
          disabled= {submit}
        >
          Submit
        </button>
      </div>

     

    </div>
  );
}
