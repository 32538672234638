import React, { useState } from "react";
import { useEffect } from "react";

import PieChart from "../webx/dashboard-piechart";

import { UserStatistics } from "../../services/srvc-users-realm";
import { TransferStatistics } from "../../services/srvc-transfers-realm";
import { DashboardAssetStatistics } from "../../services/srvc-assets-realm";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";

export default function DashboardModule() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [data, setData] = useState({
    totalAccount: "",
    activeAccount: "",
    inactiveAccount: "",
    totalAssets: "",
    activeAssets: "",
    inactiveAssets: "",
    totalUnits: "",
    activeUnits: "",
    inactiveUnits: "",
    totalTransaction: "",
    successTransaction: "",
    pendingTransaction: "",
    failedTransaction: "",
  });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     var res = await UserStatistics({ data: { user: "" } });
  //     setData((prevData) => ({
  //       ...prevData,
  //       totalAccount: res?.data?.total || "0",
  //       activeAccount: res?.data?.active || "0",
  //       inactiveAccount: res?.data?.inactive || "0",
  //     }));
  //   };
  //   fetchData();
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      var res = await TransferStatistics({ data: { user: asset.item } });
      console.log(res, "++++++++++++++");
      setData((prevData) => ({
        ...prevData,
        totalTransaction: res?.data?.transaction?.total || "0",
        successTransaction: res?.data?.transaction?.success || "0",
        pendingTransaction: res?.data?.transaction?.pending || "0",
        failedTransaction: res?.data?.transaction?.failed || "0",
      }));
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      var res = await DashboardAssetStatistics({ data: { user: asset.item } });
      setData((prevData) => ({
        ...prevData,
        totalAssets: res?.data?.assets?.total || "0",
        activeAssets: res?.data?.assets?.active || "0",
        inactiveAssets: res?.data?.assets?.inactive || "0",
        totalUnits: res?.data?.units?.total || "0",
        activeUnits: res?.data?.units?.active || "0",
        inactiveUnits: res?.data?.units?.inactive || "0",
      }));
    };
    fetchData();
  }, []);

  const accountdata = {
    labels: [" Active", " Inactive"],
    datasets: [
      {
        data: [data.activeAccount, data.inactiveAccount],
        backgroundColor: ["	#50C878", "#FF4433"],
        hoverBackgroundColor: ["	#50C878", "#FF4433"],
      },
    ],
  };
  const transfersdata = {
    labels: [" Success", "Failed", " Pending"],
    datasets: [
      {
        data: [
          data.successTransaction,
          data.failedTransaction,
          data.pendingTransaction,
        ],
        backgroundColor: ["	#50C878", "#FF4433", "#FFCE56"],
        hoverBackgroundColor: ["	#50C878", "#FF4433", "#FFCE56"],
      },
    ],
  };
  const assetsdata = {
    labels: [" Active", " Inactive"],
    datasets: [
      {
        data: [data.activeAssets, data.inactiveAssets],
        backgroundColor: ["	#50C878", "#FF4433"],
        hoverBackgroundColor: ["	#50C878", "#FF4433"],
      },
    ],
  };
  const unitsdata = {
    labels: [" Active", " Inactive"],
    datasets: [
      {
        data: [data.activeUnits, data.inactiveUnits],
        backgroundColor: ["	#50C878", "#FF4433"],
        hoverBackgroundColor: ["	#50C878", "#FF4433"],
      },
    ],
  };

  return (
    <div>
      {data ? (
        <div
          className="main-div d-flex"
          style={{
            borderRadius: "10px",
            overflow: "auto",
            padding: "20px",
          }}
        >
          <div className="bottom-div d-flex flex-column flex-md-row">
            <div className="bottom-div-left d-flex bg-white rounded-3 p-4 ">
              {data.activeAssets == 0 && data.inactiveAssets == 0 ? (
                ""
              ) : (
                <PieChart
                  data={assetsdata}
                  name="Assets "
                  total={data.totalAssets}
                />
              )}
            </div>
            <div className="bottom-div-right d-flex bg-white rounded-3 p-4">
              {data.activeUnits == 0 && data.inactiveUnits == 0 ? (
                ""
              ) : (
                <PieChart
                  data={unitsdata}
                  name="Units "
                  total={data.totalUnits}
                />
              )}
            </div>
          </div>
          <div className="bottom-div d-flex">
            <div className="bottom-div-right d-flex bg-white rounded-3 p-4 d-none">
              {data.successTransaction == 0 &&
              data.failedTransaction == 0 &&
              data.pendingTransaction == 0 ? (
                " "
              ) : (
                <PieChart
                  data={transfersdata}
                  name="Transfers "
                  total={data.totalTransaction}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
