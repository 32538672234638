import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateNetworkData,
  updateSearchTerm,
  updateSearchButton,
} from "../actions/networkActions";

import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import { NumberFormat } from "../../services/srvc-utilities";

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import DropdownFormat from "../webz/dropdown-format-xm";

export default function NetworkSearchModule() {
  const dispatch = useDispatch();
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  // Get data from Redux store
  const data = useSelector((state) => state.network);

  const handleCheckboxChange = (key) => {
    let newData = { ...data };

    if (key === "active") {
      newData = {
        ...newData,
        active: !newData.active,
        deleted: false,
        all: false,
        searchTerm: "",
        searchButton: false,
        page: 1,
      };
    } else if (key === "deleted") {
      newData = {
        ...newData,
        active: false,
        deleted: !newData.deleted,
        all: false,
        searchTerm: "",
        searchButton: false,
        page: 1,
      };
    } else if (key === "all") {
      newData = {
        ...newData,
        active: false,
        deleted: false,
        all: !newData.all,
        searchTerm: "",
        searchButton: false,
        page: 1,
      };
    } else if (key === "teamContact") {
      newData = {
        ...newData,
        teamContact: !newData.teamContact,
        userContact: false,
        searchTerm: "",
        searchButton: false,
        page: 1,
      };
    } else if (key === "userContact") {
      newData = {
        ...newData,
        teamContact: false,
        userContact: !newData.userContact,
        searchTerm: "",
        searchButton: false,
        page: 1,
      };
    } else {
      newData = { ...newData, [key]: !newData[key], searchTerm: "" };
    }

    if (!newData.active && !newData.deleted && !newData.all) {
      newData = { ...newData, all: true };
    }

    if (!newData.teamContact && !newData.userContact) {
      newData = { ...newData, userContact: true };
    }

    dispatch(updateNetworkData(newData)); // Dispatch action to update Redux state
  };

  const handleInputChange = (event) => {
    const newSearchTerm = event.target.value;
    dispatch(updateSearchButton(false)); // Dispatch action to update Redux state
    dispatch(updateSearchTerm(newSearchTerm)); // Dispatch action to update Redux state
  };

  const handleSearchClick = () => {
    let newData = { ...data };
    newData = { ...newData, page: 1 };
    dispatch(updateNetworkData(newData)); // Dispatch action to update Redux state
    dispatch(updateSearchButton(true)); // Dispatch action to update Redux state
  };
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center d-md-block">
        {/* info */}
        <div className="d-none">Search</div>
        <div
          className="mb-3 w-100 d-flex align-items-center"
          style={{ position: "relative" }}
        >
          <input
            type="text"
            className="form-control mt-3"
            value={data.searchTerm}
            onChange={handleInputChange}
            placeholder="Search"
          />
          <button
            type="button"
            className="btn btn-lg mt-3"
            style={{
              height: "35px",
              width: "48px",
              position: "absolute",
              right: "1px",
              padding: "0px",
              zIndex: "100",
              background: "white",
            }}
            onClick={handleSearchClick}
          >
            <i className="bx bx-search-alt"></i>{" "}
          </button>
        </div>
        {/* status */}
        <div className="d-none d-md-block">
          <WebbDividerSmall />
        </div>{" "}
        <div
          className="btn m-0 px-2 p-0 d-flex align-items-center d-md-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          Filters
          <i className={`bx ${isOpen ? "bx-chevron-up" : "bx-chevron-down"} ps-2`}></i>
          </div>
      </div>
      <DropdownFormat isOpen={isOpen}>
        <div className="back-color-wite p-2 px-3 rounded-xd">
          <div className="">
            <label className="form-label text-small">
              Status <FormNeeded />
            </label>

            <div className="d-flex form-check form-switch m-0 p-0 mb-2">
              <div className="">
                <p className="m-0 p-0">Active</p>
              </div>
              <div className="ms-auto">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  style={{ height: "1.2rem", width: "2rem" }}
                  checked={data.active}
                  onChange={() => handleCheckboxChange("active")}
                ></input>
              </div>
            </div>

            <div className="d-flex form-check form-switch m-0 p-0 mb-2">
              <div className="">
                <p className="m-0 p-0">Inactive</p>
              </div>
              <div className="ms-auto">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  style={{ height: "1.2rem", width: "2rem" }}
                  checked={data.deleted}
                  onChange={() => handleCheckboxChange("deleted")}
                ></input>
              </div>
            </div>

            <div className="d-flex form-check form-switch m-0 p-0 mb-2">
              <div className="">
                <p className="m-0 p-0">All</p>
              </div>
              <div className="ms-auto">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  style={{ height: "1.2rem", width: "2rem" }}
                  checked={data.all}
                  onChange={() => handleCheckboxChange("all")}
                ></input>
              </div>
            </div>
          </div>
        </div>
        {/* type */}
        <div className="d-none d-md-block">
          <WebbDividerSmall />
        </div>{" "}
        <div className="back-color-wite p-2 px-3 rounded-xd d-none ">
          <div className="">
            <label className="form-label text-small">
              Type <FormNeeded />
            </label>

            <div className="d-flex form-check form-switch m-0 p-0 mb-2 ">
              <div className="">
                <p className="m-0 p-0">Users</p>
              </div>
              <div className="ms-auto">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  style={{ height: "1.2rem", width: "2rem" }}
                  checked={data.userContact}
                  onChange={() => handleCheckboxChange("userContact")}
                ></input>
              </div>
            </div>

            <div className="d-flex form-check form-switch m-0 p-0 mb-2">
              <div className="">
                <p className="m-0 p-0">Team Members</p>
              </div>
              <div className="ms-auto">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  style={{ height: "1.2rem", width: "2rem" }}
                  checked={data.teamContact}
                  onChange={() => handleCheckboxChange("teamContact")}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </DropdownFormat>
    </>
  );
}
