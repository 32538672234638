import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// import list from "../../data.static/data-transfers-list.json";
import TotalTransaction from "../webx/transfers-list";
import {
  TransferList,
  TransferSearch,
} from "../../services/srvc-transfers-realm";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { updatePage } from "../actions/transferActions";

export default function TransferListModule() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const transferData = useSelector((state) => state.transfer);
  const currentPage = useSelector((state) => parseInt(state.transfer.page, 10)); // Get currentPage from Redux store and parse it to integer

  const [loader, setLoader] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [currentItems, setCurrentItems] = useState([]);

  const handlePageChange = (page) => {
    dispatch(updatePage(page)); // Dispatch action to update page
  };
  useEffect(() => {
    navigate(`/team/transfers?page=${currentPage}`);
  }, [currentPage]);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);

      var res = await TransferSearch({
        data: {
          user: asset.item,
          search: {
            fromDate: transferData.start,
            toDate: transferData.end,
          },
          filters: {
            name: transferData.success
              ? "success"
              : transferData.pending
              ? "pending"
              : transferData.failed
              ? "failed"
              : "",
          },
          index: currentPage,
          items: itemsPerPage,
        },
      });
      if (res?.data?.list.length >= 0) {
        setCurrentItems(res?.data?.list);
        if (res.data.count > 0) {
          setTotalItems(res?.data?.count);
        }
      }
      if (res) {
        setLoader(false);
      }
    };

    if (transferData.start && transferData.end) {
      fetchData();
    }
  }, [transferData.end, currentPage]);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);

      var res = await TransferList({
        data: {
          user: asset.item,
          filters: {
            name: transferData.success
              ? "success"
              : transferData.pending
              ? "pending"
              : transferData.failed
              ? "failed"
              : "",
          },
          index: currentPage,
          items: itemsPerPage,
        },
      });
      if (res?.data?.list.length >= 0) {
        setCurrentItems(res?.data?.list);
        if (res.data.count > 0) {
          setTotalItems(res?.data?.count);
        }
      }

      setLoader(false);
    };
    if (transferData.end == "") {
      fetchData();
    }
  }, [
    currentPage,
    transferData.success,
    transferData.all,
    transferData.pending,
    transferData.failed,
  ]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  if (loader == true) {
    return <p>Please wait ....</p>;
  }
  if (!loader && currentItems.length == 0) {
    return <p>No transfers found.....</p>;
  }

  return (
    <div>
      {!loader && currentItems.length > 0 && (
        <TotalTransaction
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          currentItems={currentItems}
          itemsPerPage={itemsPerPage}
          totalItem={totalItems}
        />
      )}
    </div>
  );
}
