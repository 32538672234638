import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
const TotalTransaction = ({
  currentPage,
  totalPages,
  handlePageChange,
  currentItems,
  itemsPerPage,
  totalItem,
}) => {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  console.log(currentItems);

  useEffect(() => {
    handlePageChange(currentPage);
  }, []);
  return (
    <>
      <div className="border rounded  border-none mb-4">
        {currentItems.map((item, index) => (
          <div key={item?.item}>
            <Link
              to={`/team/transfers/${item?.item}`}
              className=" text-decoration-none"
            >
              <div className="d-flex justify-content-between bg-white p-4 flex-column flex-md-row">
                <div className="mb-3 mb-md-0">
                  <p
                    className="m-0 text-primary"
                    style={{ fontWeight: "bold" }}
                  >
                    {item?.user?.name || ""}
                  </p>
                  <p className="m-0">{item?.memo || ""}</p>
                  <p style={{ fontSize: "11px", margin: "0" }}>
                    {new Date(item?.created).toLocaleString(undefined, {
                      hour12: true,
                    })}
                  </p>
                  <div className="d-flex gap-2">
                    <p className="m-0 " style={{ fontSize: "11px" }}>
                      {item?.user?.mail || ""}
                    </p>
                    <p className="m-0 text-xs" style={{ fontSize: "11px" }}>
                      {item?.time || ""}
                    </p>
                  </div>
                </div>
                <div>
                  <div className="d-flex flex-column align-items-md-end">
                    <div className="d-flex gap-3 align-items-center mb-2">
                      <div className="d-flex gap-1">
                        <span>{item.mode === "credit" ? "+" : "-"}</span>
                        <p className="m-0" style={{ fontWeight: "bold" }}>
                          {item?.amount?.number / 1000000 || ""}{" "}
                        </p>
                        <p className="m-0">{item?.amount?.ticker?.toUpperCase() || ""}</p>
                      </div>
                      <div
                        className={`${
                          item.status == 6 || item.status == "6"
                            ? "bg-success"
                            : item?.status == 3 ||
                              item?.status == "3" ||
                              item?.status == 4 ||
                              item?.status == "4" ||
                              item?.status == 7 ||
                              item?.status == "7" ||
                              item?.status == 8 ||
                              item?.status == "8" ||
                              item?.status == 9 ||
                              item?.status == "9"
                            ? "bg-danger"
                            : "bg-warning"
                        }`}
                        style={{
                          height: "10px",
                          width: "10px",
                          borderRadius: "50%",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
            <hr className="m-0" />
          </div>
        ))}
      </div>
      <nav aria-label="Page navigation example">
        <ul
          className="pagination justify-content-between align-items-center
          "
        >
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <p
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              href="#"
            >
              <i className="bx bx-chevron-left"></i>
            </p>
          </li>

          <p className="m-0">
            {totalItem == 0
              ? totalItem
              : currentPage * itemsPerPage + 1 - itemsPerPage}{" "}
            -{" "}
            {currentPage * itemsPerPage > totalItem
              ? totalItem
              : currentPage * itemsPerPage}{" "}
            of {totalItem}
          </p>

          <li
            className={`page-item align-items-end ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <p
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              href="#"
            >
              <i className="bx bx-chevron-right"></i>
            </p>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default TotalTransaction;
