export default function UserOnboardHoldModule () {


  return (
  <>
  <div className="container">
      <div
        className="form-container d-flex  flex-column "
        style={{
          margin: "50px auto",
          backgroundColor: " #ffffff",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: " 0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2>Your account is under review</h2>
        <p className="my-4">
        Your account is currently being reviewed and we need some time to verify your info.
During this review, you won't be able to use Miniland's account. Reviews generally take a few days, so sign in every day or two to check your status.
        </p>
        <p style={{fontSize:"15px"}}>We will notify you via email as the accound reviewed...</p>
      </div>
    </div>

  </>

  )
}