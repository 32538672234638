import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TransferDetails } from "../../services/srvc-transfers-realm";
export default function TransferUserDetail() {
  const { item } = useParams();
  const [loader, setLoader] = useState(true);
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      var res = await TransferDetails({
        data: {
          item: item,
        },
        srvc: "******",
      });
      setCurrentItems(res.data);
      setLoader(false);
    };
    fetchData();
  }, []);

  if (loader == true) {
    return <p>Please wait ....</p>;
  }
  if (!loader && currentItems.length == 0) {
    return <p>No items found</p>;
  }

  return (
    <>
      <div className=" my-2 px-md-5">
        <div className="bg-white my-2  p-3 border rounded">
          <p className="fw-bold">Credit User</p>
          <div className="d-flex  gap-4  justify-content-between d-none">
            <p> Account</p>
            <p
              style={{
                minWidth: "50px",
                marginLeft: "40px",
                wordWrap: "break-word",
              }}
            >
              {/* {currentItems.credit.item} */}
              ***********
            </p>
          </div>
          <div className="d-flex  gap-4  justify-content-between">
            <p>User Id</p>
            <p
              style={{
                minWidth: "50px",
                marginLeft: "40px",
                wordWrap: "break-word",
              }}
            >
              {currentItems?.credit?.item || ""}
            </p>
          </div>{" "}
          <div className="d-flex  gap-4  justify-content-between">
            <p>Email</p>
            <p
              style={{
                minWidth: "50px",
                marginLeft: "40px",
                wordWrap: "break-word",
              }}
            >
              {currentItems?.credit?.mail || ""}
            </p>
          </div>{" "}
          <div className="d-flex  gap-4  justify-content-between">
            <p>Name</p>
            <p
              style={{
                minWidth: "50px",
                marginLeft: "40px",
                wordWrap: "break-word",
              }}
            >
              {currentItems?.credit?.name || ""}
            </p>
          </div>
          <hr />
          <p className="fw-bold">Debit User</p>
          <div className="d-flex  gap-4  justify-content-between d-none">
            <p> Account</p>
            <p
              style={{
                minWidth: "50px",
                marginLeft: "40px",
                wordWrap: "break-word",
              }}
            >
              {/* {currentItems.debt.acnt} */}
              *********
            </p>
          </div>
          <div className="d-flex  gap-4  justify-content-between">
            <p>User Id</p>
            <p
              style={{
                minWidth: "50px",
                marginLeft: "40px",
                wordWrap: "break-word",
              }}
            >
              {currentItems?.debit?.item || ""}
            </p>
          </div>{" "}
          <div className="d-flex  gap-4  justify-content-between">
            <p>Email</p>
            <p
              style={{
                minWidth: "50px",
                marginLeft: "40px",
                wordWrap: "break-word",
              }}
            >
              {currentItems?.debit?.mail || ""}
            </p>
          </div>{" "}
          <div className="d-flex  gap-4  justify-content-between">
            <p>Name</p>
            <p
              style={{
                minWidth: "50px",
                marginLeft: "40px",
                wordWrap: "break-word",
              }}
            >
              {currentItems?.debit?.name || ""}
            </p>
          </div>
          <hr />
          <div className="d-flex  gap-4  justify-content-between">
            {" "}
            <p>Amount</p>
            <p
              style={{
                minWidth: "50px",
                marginLeft: "40px",
                wordWrap: "break-word",
              }}
            >
              {currentItems?.amount?.number / 1000000 || "0"}{" "}
              {currentItems?.amount?.ticker?.toUpperCase()}
            </p>
          </div>
          <div className="d-flex  gap-4  justify-content-between">
            {" "}
            <p>Transfer Status</p>
            <p className="d-flex  gap-2 align-items-center gap-1">
              <div
                className={`${
                  currentItems.status == 6 || currentItems.status == "6"
                    ? "bg-success"
                    : currentItems?.status == 3 ||
                      currentItems?.status == "3" ||
                      currentItems?.status == 4 ||
                      currentItems?.status == "4" ||
                      currentItems?.status == 7 ||
                      currentItems?.status == "7" ||
                      currentItems?.status == 8 ||
                      currentItems?.status == "8" ||
                      currentItems?.status == 9 ||
                      currentItems?.status == "9"
                    ? "bg-danger"
                    : "bg-warning"
                }`}
                style={{
                  height: "10px",
                  width: "10px",
                  borderRadius: "50%",
                }}
              ></div>
              {`${
                currentItems.status == 6 || currentItems.status == "6"
                  ? "success"
                  : currentItems?.status == 3 ||
                    currentItems?.status == "3" ||
                    currentItems?.status == 4 ||
                    currentItems?.status == "4" ||
                    currentItems?.status == 7 ||
                    currentItems?.status == "7" ||
                    currentItems?.status == 8 ||
                    currentItems?.status == "8" ||
                    currentItems?.status == 9 ||
                    currentItems?.status == "9"
                  ? "failed"
                  : "pending"
              }`}
            </p>
          </div>
          <div className="d-flex  gap-4  justify-content-between">
            {" "}
            <p>Date</p>
            <p>
              {new Date(currentItems?.created).toLocaleString(undefined, {
                hour12: true,
              })}
            </p>
          </div>
          <div className="d-flex  gap-4  justify-content-between">
            {" "}
            <p>Transaction ID</p>
            <p
              style={{
                minWidth: "50px",
                marginLeft: "40px",
                wordWrap: "break-word",
              }}
            >
              {item}
            </p>
          </div>
        </div>

        <div className="d-flex gap-4 bg-white my-2 p-3 px-4 border rounded justify-content-center justify-content-md-start">
          <div className="d-flex flex-column align-items-center">
            <i className="bx bx-undo bx-md mb-2"></i>
            <p className="m-0">Revert</p>
          </div>
          <div className="d-flex flex-column align-items-center ">
            <i className="bx bxs-time-five bx-md mb-2"></i>
            <p className="m-0">Reschedule</p>
          </div>
        </div>
      </div>
    </>
  );
}
