import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AssetsList, {
  AssetsTransactions,
} from "../../services/srvc-assets-realm";

import TotalUserTransaction from "../webx/assets-list";
import TotalAssetTransaction from "../webx/assets-list";
import { UsersTransfer } from "../../services/srvc-transfers-realm";
import TotalMemberTransaction from "../webx/members-transfers-list";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { CreatorsUsersTransfers } from "../../services/srvc-transfers-assets-realm";
export default function TotalContactTransaction() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const { item } = useParams();
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState("");

  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      var res = await CreatorsUsersTransfers({
        data: {
          // item: item,
          index: currentPage,
          items: itemsPerPage,
          creator: asset.item,
          user: item,
        },
        srvc: "8783533",
      });
      console.log(res)
      if (res) {
        if(res?.data?.list){
          setCurrentItems(res?.data?.list);
          setTotalItems(res?.data?.count);
        }
        setLoader(false);
      }
    };

    fetchData();
  }, [currentPage]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loader == true) {
    return <p>Please wait ....</p>;
  }

  return (
    <>
      <div className="">
        {currentItems.length > 0 ? (
          <TotalMemberTransaction
            currentItems={currentItems}
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            itemsPerPage={itemsPerPage}
            totalItem={totalItems}
          />
        ) : (
          <div className="border rounded p-4 "> No Transactions found.....</div>
        )}
      </div>
    </>
  );
}
