// onboard
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoaderMedium from "../webx/webb-loader-md";
import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";

import { GetAuthUser, SetNewBusiness } from "../../services/srvc-auth-local";
import { UserAccountCreate } from "../../services/srvc-users-realm";
import {
  CreatorAccountCreate,
  TeamCreatorAccountCreate,
} from "../../services/srvc-creator-realm";

export default function TeamOnboardNameModule() {
  const asset = GetAuthUser();
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);


  const [data, setData] = useState({
    username: "",
    email: "",
    mobile: "",
  });

  

  const handleSubmit = async () => {
    setLoader(true);

    const datx = {
      name: data.username,
      mail: data.email,
      mobile: data.mobile,
    };

    const result = await TeamCreatorAccountCreate({ data: datx });


    if (result.data && result.stat) {
        navigate("/auth/next");
    } else {
     alert("Error. Please try again...")
     navigate("/auth/next");
    }

    setLoader(false);
  };

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val });
  };

  const handleBack = () => {
    navigate("/auth/next");
    // onButtonSelect("");
  };
  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isValidMobile = (mobile) => {
    // Regular expression for mobile number validation
    const mobileRegex = /^\d{10}$/;
    return mobileRegex.test(mobile);
  };

  if (loader)
    return (
      <>
        <div className="text-center">
          <WebbLoaderSmall />
        </div>
      </>
    );

  return (
    <>
      <div className={` container`}>
        <div
          className="form-container p-3"
          style={{
            margin: "50px auto",
            backgroundColor: "#ffffff",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="form-group my-3">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              className="form-control mt-2"
              id="username"
              placeholder="Enter your username"
              style={{ width: "100%" }}
              value={data.username}
              onChange={({ target }) => {handleChange("username", target.value); }}
              required
            />
          </div>
          <div className="form-group my-3">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              className="form-control mt-2"
              id="email"
              placeholder="Enter your email"
              style={{ width: "100%" }}
              value={data.email}
              onChange={({ target }) => {handleChange("email", target.value); }}
              required
            />
            {data.email !== "" && !isValidEmail(data.email) && (
              <div className="mt-2 text-small text-danger">
                Please enter a valid email address.
              </div>
            )}
          </div>
          <div className="form-group my-3">
            <label htmlFor="mobile">Mobile:</label>
            <input
              // type="number"
              className="form-control mt-2"
              id="mobile"
              placeholder="Enter your mobile"
              style={{ width: "100%" }}
              value={data.mobile}
              onChange={({ target }) => {handleChange("mobile", target.value); }}
              required
            />
             {data.mobile !== "" && !isValidMobile(data.mobile) && (
      <div className="mt-2 text-small text-primary">
        Please enter a valid 10-digit mobile number.
      </div>
    )}
          </div>

          <div className="d-flex mt-3">
            <div className="me-auto">
              <button
                type="button"
                className="btn btn-primary rounded-xx text-small  text-small"
                onClick={handleBack}
              >
                Back
              </button>
            </div>
            <div className="text-end">
              <button
                type="button"
                className="btn btn-primary rounded-xx text-small  text-small"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
