import React, { useEffect, useState } from "react";
import { CreatorAddContact, TeamCreatorMemberAdd } from "../../services/srvc-creator-realm";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { useNavigate } from "react-router-dom";
import { TeamUserMemberAdd } from "../../services/srvc-users-realm";

export default function NetworkAddMember() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [username, setUsername] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const fetchData = async () => {
    setLoader(true);

    var res = await TeamCreatorMemberAdd({
      data: {
        user: {
          name: username,
          mail: email,
          mobile: mobile,
        },
        team: asset.item,
      },
    });
    if (res.stat) {
      setLoader(false);
      alert(`${res.memo}`)
      navigate("/team/home")
    }
    else{
      setLoader(false);
      alert(`${res.memo}`)
      navigate("/team/home")

    }
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  };

    const handleSubmit = () => {
      if (username.trim() === "" || email.trim() === "" || mobile.trim() === "") {
        alert("Please fill out all fields.");
      } else if (!isValidEmail(email)) {
        alert("Please enter a valid email address.");
      } else if (!isValidMobile(mobile)) {
        alert("Please enter a valid 10-digit mobile number.");
      } else {
        fetchData();
      }
    };  
  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isValidMobile = (mobile) => {
    // Regular expression for mobile number validation
    const mobileRegex = /^\d{10}$/;
    return mobileRegex.test(mobile);
  };
  

  if (loader == true) {
    return <p className="mt-4 d-flex justify-content-center">Please wait ....</p>;
  }

  return (
    <div className="container">
      <div
        className="form-container d-flex  flex-column "
        style={{
          margin: "50px auto",
          backgroundColor: " #ffffff",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: " 0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="form-group mt-3">
          <label htmlFor="username"> Username:</label>
          <input
            type="text"
            className="form-control mt-2"
            id="username"
            placeholder="Enter your username"
            style={{ width: "100%" }}
            value={username}
            onChange={handleUsernameChange}
          />
        </div>
        <div className="form-group mt-3">
          <label htmlFor="email"> Email:</label>
          <input
            type="email"
            className="form-control mt-2"
            id="email"
            placeholder="Enter your email"
            style={{ width: "100%" }}
            value={email}
            onChange={handleEmailChange}
          />
           {email !== null && !isValidEmail(email) && (
              <div className="mt-2 text-small text-danger">
                Please enter a valid email address.
              </div>
            )}
        </div>
        <div className="form-group mt-3">
          <label htmlFor="mobile"> Mobile:</label>
          <input
            type="number"
            className="form-control mt-2"
            id="mobile"
            placeholder="Enter your mobile"
            style={{ width: "100%" }}
            value={mobile}
            onChange={handleMobileChange}
          />
           {mobile !== null && !isValidMobile(mobile) && (
      <div className="mt-2 text-small text-danger">
        Please enter a valid 10-digit mobile number.
      </div>
    )}
        </div>
        <div className="d-flex justify-content-end mt-4">
          <button
            type="button"
            className={`btn btn-primary rounded-xx ${email==null || username==null ||mobile==null?"disabled":""}`}
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
