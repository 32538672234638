// section heading

export default function HeaderSectionDark(props) {

    const data = props.data;
  
    return (
    <>
      <p className="text-normal text-bold">{data.name}</p>
      <div className="mb-3"></div>
    </>
    )
  }