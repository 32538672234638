import axios from "axios";
import services from "../data.services/data-services-main.json";


const base = services.data.find((x) => x.code === "tokens").link;

export const TokensList = async (item) => {
    const basx = base + "/tokens/list";
    const head = {
      "Content-Type": "application/json",
      Authorization: "*******",
      //  process.env.REACT_APP_WEBB_SITE_CLNT
    };
    const datx = {
      data: item.data,
      srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
      // process.env.REACT_APP_WEBB_SITE_SRVC
    };
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head });
      return { code: result.status, ...result.data };
    } catch (error) {
      return { code: error.response.status, ...error.response.data };
    }
  };
export const TokensAssetsUsersList = async (item) => {
    const basx = base + "/tokens/assets/users/list";
    const head = {
      "Content-Type": "application/json",
      Authorization: "*******",
      //  process.env.REACT_APP_WEBB_SITE_CLNT
    };
    const datx = {
      data: item.data,
      srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
      // process.env.REACT_APP_WEBB_SITE_SRVC
    };
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head });
      return { code: result.status, ...result.data };
    } catch (error) {
      return { code: error.response.status, ...error.response.data };
    }
  };

  export const TokensUnitsUsersList = async (item) => {
    const basx = base + "/tokens/units/users/list";
    const head = {
      "Content-Type": "application/json",
      Authorization: "*******",
      //  process.env.REACT_APP_WEBB_SITE_CLNT
    };
    const datx = {
      data: item.data,
      srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
      // process.env.REACT_APP_WEBB_SITE_SRVC
    };
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head });
      return { code: result.status, ...result.data };
    } catch (error) {
      return { code: error.response.status, ...error.response.data };
    }
  };