// web nav
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import UserActionsModule from "../user/user-actions";
import NetworkActionsModule from "../network/network-actions";
import ContractActionsModule from "../contracts/contract-action";
import TransfersActionsModule from "../transfers/transfers-actions";
import AssetActionsModule from "../assets/assets-actions";

const actionsMap = {
  home: <UserActionsModule />,
  assets: <AssetActionsModule />,
  contracts: <ContractActionsModule />,
  network: <NetworkActionsModule />,
  transfers: <TransfersActionsModule />,
};
const listNavs = require("../../data.static/navs-header-xw.json").data;

export default function WebbHeaderNavs(props) {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const location = useLocation();
  const linx = location.pathname.split("/")[2];
  const form = location.pathname.split("/")[1];

  const data = listNavs.filter(
    (item) => item.user.includes(form) && item.actv && item.show.main
  );

  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  return (
    <>
      {/* header-large */}
      <nav className="nav flex-column text-center d-none d-md-block">
        {data &&
          data.map((item, i) =>
            item.actv ? (
              <Link
                to={`/${asset.role}/${item.link}`}
                key={i}
                className={`nav-link m-0 p-0 ${
                  item.link === linx
                    ? "back-color-next text-color-wite"
                    : "text-dark"
                }`}
              >
                <div
                  className={`p-3 pb-2 ${
                    item.link === linx
                      ? "back-color-next text-color-wite"
                      : "hidark"
                  }`}
                >
                  <i
                    className={`${item.icon}${item.link === linx ? "" : ""}`}
                    style={{ fontSize: "1.5rem", lineHeight: "0rem" }}
                  ></i>

                  <p className="m-0 p-0 text-mini">{item.name}</p>
                </div>
              </Link>
            ) : (
              ""
            )
          )}
      </nav>

      {/* header small */}
      <nav className="nav flex-column d-block d-md-none">
        {data &&
          data.map((item, i) =>
            item.actv ? (
              <div key={i}>
                <Link
                  to={`/${asset.role}/${item.link}`}
                  className={`nav-link m-0 p-0 ${
                    item.link === linx
                      ? "back-color-next text-color-wite"
                      : "text-dark"
                  }`}
                  onClick={() => toggleDropdown(i)}
                >
                  <div
                    style={{ padding: "12px" }}
                    className={`d-flex align-items-center justify-content-between ${
                      item.link === linx
                        ? "back-color-next text-color-wite"
                        : "hidark"
                    }`}
                  >
                    <div className="d-flex align-items-center">
                      <i
                        className={`${item.icon}`}
                        style={{ fontSize: "1.5rem", marginRight: "10px" }}
                      ></i>
                      <p className="m-0 text-md">{item.name}</p>
                    </div>
                    <i
                      className="bx bx-chevron-down"
                      style={{ fontSize: "1.25rem" }}
                    ></i>
                  </div>
                </Link>
                {/* Dropdown content */}
                {activeDropdown === i && (
                  <div className="ms-3">
                    {actionsMap[item.name.toLowerCase()]}
                  </div>
                )}
              </div>
            ) : (
              ""
            )
          )}
      </nav>
    </>
  );
}
