// init
import axios from 'axios'
// const base = 'https://ap-southeast-1.aws.data.mongodb-api.com/app/tokenize-users-admin-tfusw/endpoint'

import services from '../data.services/data-services-main.json'

const base = (services.data.find(x => x.code === 'users')).link


export const SearchUserList = async (item) => {

  const basx = base + '/users/search'
  const head = {
    'Content-Type': 'application/json',
    'Authorization': process.env.REACT_APP_WEBB_SITE_CLNT
  }
  
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    // console.log(result)
    return { code: result.status, ...result.data }
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data }
  }

}



export const UserDetail = async (item) => {

  const basx = base + '/users/details'
  const head = {
    'Content-Type': 'application/json',
    'Authorization': process.env.REACT_APP_WEBB_SITE_CLNT
  }
  
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    // console.log(result)
    return { code: result.status, ...result.data }
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data }
  }

}

export const AccountHold = async (item) => {

  const basx = base + '/users/hold'
  const head = {
    'Content-Type': 'application/json',
    'Authorization': process.env.REACT_APP_WEBB_SITE_CLNT
  }
  
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    // console.log(result)
    return { code: result.status, ...result.data }
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data }
  }

}

export const AccountActivate = async (item) => {

  const basx = base + '/users/activate'
  const head = {
    'Content-Type': 'application/json',
    'Authorization': process.env.REACT_APP_WEBB_SITE_CLNT
  }
  
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    // console.log(result)
    return { code: result.status, ...result.data }
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data }
  }

}

export const GetUserSummary = async (item) => {

  const basx = base + '/dashboard/summary'
  const head = {
    'Content-Type': 'application/json',
    'Authorization': process.env.REACT_APP_WEBB_SITE_CLNT
  }
  
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    // console.log(result)
    return { code: result.status, ...result.data }
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data }
  }

}
