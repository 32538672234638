import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AssetsUnitsDetail } from "../../services/srvc-assets-realm";
export default function AssetUnitActionModules() {
  const { item } = useParams();

  const [currentItems, setCurrentItems] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      var res = await AssetsUnitsDetail({
        data: {
          item: item,
        },
      });
      setCurrentItems(res?.data);
      setLoader(false);
    };

    fetchData();
  }, []);

  if (loader == true) {
    return <p>Please wait ....</p>;
  }
  if (!loader && currentItems.length == 0) {
    return <p>No items found</p>;
  }
  return (
    <>
      <div
        key={currentItems?.item || ""}
        className="bg-white border rounded position-relative"
        style={{ width: "100%", marginBottom: "20px" }}
      >
        <img
          src={currentItems?.media?.link || ""}
          style={{
            width: "100%",
            height: "200px",
            borderTopLeftRadius: "0.375rem",
            borderTopRightRadius: "0.375rem",
            objectFit: "cover",
          }}
          className="border"
          alt="img"
        />
        <p className="px-4 m-0 mt-4">{currentItems?.creator?.name || ""}</p>
        <p
          className="px-4 fw-bold"
          style={{ fontSize: "20px", color: "slateblue" }}
        >
          {currentItems?.meta?.name || ""}
        </p>
        <p
          className="px-4 m-0 mb-4"
          style={{
            maxHeight: "100px",
            overflowY: "scroll",
          }}
        >
          {currentItems?.meta?.memo || ""}
        </p>
      </div>
      <div className=" my-2">
        <div className="bg-white my-2  p-3 border rounded">
          <p className="fw-bold">Detail</p>
          <div className="d-flex  gap-4  justify-content-between">
            <p> Rate</p>
            <p
              style={{
                minWidth: "50px",
                marginLeft: "40px",
                wordWrap: "break-word",
              }}
            >
              {currentItems.rate.number / 1000000}{" "}
              {currentItems.rate.ticker.toUpperCase()}
            </p>
          </div>{" "}
          <div className="d-flex  gap-4  justify-content-between">
            <p> Book</p>
            <p
              style={{
                minWidth: "50px",
                marginLeft: "40px",
                wordWrap: "break-word",
              }}
            >
              {currentItems.units.book}
            </p>
          </div>
          <div className="d-flex  gap-4  justify-content-between">
            <p> Mint</p>
            <p
              style={{
                minWidth: "50px",
                marginLeft: "40px",
                wordWrap: "break-word",
              }}
            >
              {currentItems.units.mint}
            </p>
          </div>
          <div className="d-flex  gap-4  justify-content-between">
            <p> Sale</p>
            <p
              style={{
                minWidth: "50px",
                marginLeft: "40px",
                wordWrap: "break-word",
              }}
            >
              {currentItems.units.sale} {currentItems.units.ticker}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
