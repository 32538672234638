import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import FormNeeded from "../webx/form-needed";
import { CreateIndividualOffers } from "../../services/srvc-offers-realm";


const listactions = require("../../data.static/data-units-asset-details.json").data;

export default function AssetUnitOptionModule () {

  const {item} = useParams()
  const navigate = useNavigate();
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState()

  const [actions, setActions] = useState()
  const [memo, setMemo] = useState('')
  const [units, setUnits] = useState([])
  const [submit, setSubmit] = useState(false)

  const [mail, setMail] = useState('')
  const [rate, setRate]  = useState('')
  const [count, setCount] = useState('')

 

  // console.log(listactions.filter(x => x.user.includes(asset.role)))

  useEffect( () => {
    if (asset){

      const fetchData = async() => {
        setLoader(true);

        const result = listactions.filter(x => x.user.includes(asset.role))
        // console.log(result);
        setData(result)

        setLoader(false);
      }
      fetchData()
    } else {}
  },[]);

  const handleClick = (live, link) => {
    //if (live) navigate(`/${asset.role}/${link}`)
  }

  const CreateOffers = async()=>{
    setSubmit(true)
    setMemo('Please Wait...')
    var datx = {
      cred: { name: '', mail:mail, item:''},
      debt: { name: asset.name, mail: asset.mail, item: asset.item },
      asset: { item: ""},
      unit : { item: item},
      rate: { number : (parseFloat(rate)*1000000).toString(), ticker: "INR"},
      size: { number: count, ticker: "BRX"}
    }
    console.log(datx)
    var res = await CreateIndividualOffers({data: datx, srvc:''})
    if(res.stat){setMemo('Offer Created')}
    else{ setMemo('Error') }
  }

  const ResetState = async() =>{
    setMail('')
    setMemo('')
    setRate('')
    setSubmit(false)
  }
  if (loader) return <></>
// console.log(listactions);

  return (
  <>
    
    <div className="back-color-wite rounded-xd p-1">
      {data && data.map((item, i) => (item.actv ?
      
      <div className={`d-flex p-2 align-middle 
        ${item.live ? 'cursor hidark rounded-wd' : 'text-color-tint'}`}
        style={{ height: '2.4rem' }} key={i}
        data-bs-toggle='modal' data-bs-target={item.link}
      >
        <div className=''>
          <i className={`m-0 p-0 ${item.icon} `} style={{ fontSize: '1.35rem' }}></i>
        </div>
        <div className='ms-2'>
          <p className={`m-0 p-0 text-nowrap`}>
            <span className='text-small align-middle'>{item.name}</span>
          </p>
        </div>
        <div className='ms-auto '>
          <i className={`m-0 p-0 bx bx-chevron-right`} style={{ fontSize: '1.35rem' }}></i>
        </div>
      </div>
      :''))}
    </div>




      {/* action modal */}
      <div
        className='modal fade'
        id='offercreate'
        tabindex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content p-0 m-0 rounded-xd'>
            <div className='modal-header border-none'>
              <p className='text-normal align-middle m-0 p-0'>Create Offer</p>
              <button
                type='button'
                className='btn-close text-small'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => ResetState()}
              ></button>
            </div>

            {/* modal content */}
            <div className='modal-body border-none'>
              <div className='mb-3'>
                <label className='form-label text-small'>
                  User (Email) <FormNeeded />
                </label>
                <input
                  type='text'
                  className='form-control height-md'
                  style={{ fontSize: '0.9rem', height: '2.7rem' }}
                  value={mail}
                  onChange={({ target }) => {
                   setMail(target.value)
                  }}
                  disabled={loader || submit}
                  placeholder='user@gmail.com'
                ></input>
              </div>

              <div className='mb-3'>
                <label className='form-label text-small'>
                  Rate <FormNeeded />
                </label>
                <input
                  type='text'
                  className='form-control height-md'
                  style={{ fontSize: '0.9rem', height: '2.7rem' }}
                  value={rate}
                  onChange={({ target }) => {
                    setRate(target.value)
                  }}
                  disabled={loader || submit}
                  placeholder='123'
                ></input>
              </div>

              <div className='mb-3'>
                <label className='form-label text-small'>
                  Sale Unit <FormNeeded />
                </label>
                <input
                  type='text'
                  className='form-control height-md'
                  style={{ fontSize: '0.9rem', height: '2.7rem' }}
                  value={count}
                  onChange={({ target }) => {
                    setCount(target.value)
                  }}
                  disabled={loader || submit}
                  placeholder='123'
                ></input>
              </div>

              <p className='text-small'>{memo}</p>
            </div>

            <div className='d-flex modal-footer border-none'>
              <div className=''>
                <button
                  className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                  data-bs-dismiss='modal'
                  onClick={() => ResetState()}
                >
                  Cancel
                </button>
              </div>
              <div className='ms-auto'>
                <button
                  className='btn btn-primary btn-sm text-small rounded-xx'
                  onClick={() => CreateOffers()}
                  disabled={!mail || !rate || submit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    
  </>

  )
}