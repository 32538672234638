// documents
import { useEffect, useState } from "react";
import {  useNavigate, useParams } from 'react-router-dom';

import WebbLoader from "../webx/webb-loader-sm";
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import { GetTemplateLists } from "../../services/srvc-template-realm";




export default function ContractEditFileModule() {


  const {id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading]=useState(true);
  const [form, setForm] = useState(true);

  const [info, setInfo] = useState({});
  const [format, setFormats] = useState([]);
  const [data, setData] = useState({
    form: ''
  });

  useEffect( () => {
    setLoading(true)
    if (id){
      const fetchData = async() => {
        


        setLoading(false);
      }
      fetchData()
    } else {}
  },[id]);

  useEffect(()=>{

    const fetchdata = async()=>{
        var res = (await GetTemplateLists({data:{}, user: ''  })).data
        // console.log(res)
        if(res!= false){setFormats(res?.list || [])}
        else {}
        setLoading(false)
    }
    fetchdata()

},[])

  // form validation
  useEffect( () => {
    setForm(false)
    if (data.form !=='') setForm(true);
  },[data]);


  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(data)
    navigate(`/team/contracts/input/fields/${id}/${data.form}`)
  
  }

  if (loading){ return ( <> <WebbLoader /> </> ) }

  return (
  <>
    {/* info */}
    <WebbDividerSmall />
    <p className="text-lead text-bold m-0">{info.name}</p>
    <p className="text-color-tone">{id}</p>
    <WebbDividerSmall />

    {/* action */}
    <p className="">Select Document Template</p>
    <div className="">
      {format && format.map((item,i) => (
        <div className={`d-flex p-2 px-3 back-color-wite cursor hitext ${i<format.length-1 ? 'border-bottom border-light': ''}`} key={i}
          onClick={()=> handleChange("form", item.item)}
        >
          <div className="me-auto">
            <p className={`m-0 ${data.form === item.item ? 'text-bold' : 'text-bold'}`}>{item.name}</p>
            <p className="text-small text-color-tone m-0">{item.item}</p>
          </div>
          <div className="text-end py-2">
            <i className={`bi text-lead m-0 p-0 ${data.form === item.item ? 'bi-check-circle-fill text-color-success' : 'bi-circle-fill text-color-lite'}`}></i>
          </div>
          
        </div>
      ))}
    </div>



    {/* form */}
    <WebbDividerMedium />
    <form onSubmit={handleSubmit}>

      <div className="">
        <div className="d-grid">
          <button className={`btn height-md btn-primary back-color-main rounded-none w-100`}
            disabled={!form}
          ><small>Select & Continue</small>
          </button>
        </div>
      </div>

    </form>
  
    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}