import React, { useEffect, useState } from "react";
import MemberUserDetail from "../network/network-member-detail";
import { MembersList } from "../../services/srvc-creator-realm";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import TotalUser from "../webx/network-user-list";

export default function UserTeam({ type }) {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);
  const [loader, setLoader] = useState(true);
  const [totalItems, setTotalItems] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      var res = await MembersList({
        data: {
          team: asset.item,

          index: currentPage,
          items: itemsPerPage,
        },
      });
      if (res) {
        setCurrentItems(res?.data?.list);
        setTotalItems(res?.data?.count);
        setLoader(false);
      }

    };
    fetchData();

  }, [currentPage]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loader == true) {
    return <p>Please wait ....</p>;
  }
  if (!loader && currentItems.length == 0) {
    return <p>No items found</p>;
  }

  return (
    <div
      className={
        type.text == "team" ? "" : "d-none"
      }
    >
      <TotalUser
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        currentItems={currentItems}
        itemsPerPage={itemsPerPage}
        totalItem={totalItems}
      />
    </div>
  );
}
