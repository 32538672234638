// user account
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from "react-jazzicon";

import { NumberFormat } from "../../services/srvc-utilities";

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

export default function AccountSearchModule({ onDataChange }) {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState();
  const [data, setData] = useState({
    start: new Date().toISOString().substring(0, 10),
    end: new Date().toISOString().substring(0, 10),
    assets: true,
    funds: true,
    active: false,
    deleted: false,
    all: true,
  });

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);

 

  useEffect(() => {
    onDataChange(data);
    onDataChange({ ...data, searchTerm: search });

  }, [data,search]);

  const handleCheckboxChange = (key) => {
    let newData;

    if (key === "active") {
      newData = { ...data, active: !data.active, wait: false, all: false };
    } else if (key === "deleted") {
      newData = { ...data, active: false, deleted: !data.deleted, all: false };
    } else if (key === "all") {
      newData = { ...data, active: false, deleted: false, all: !data.all };
    } else {
      newData = { ...data, [key]: !data[key] };
    }
    if (!newData.active && !newData.deleted && !newData.all) {
      newData = { ...newData, all: true };
    }

    setData(newData);
  };

  const handleInputChange = (event) => {
    const newSearchTerm = event.target.value;

    setSearch(newSearchTerm);

    onDataChange({ ...data, searchTerm: newSearchTerm });
  };
  return (
    <>
      {/* info */}
      <div className="">Search</div>
      <div className="mb-3">
        <input
          type="text"
          className="form-control mt-3"
          value={search}
          onChange={handleInputChange}
        />
      </div>

      {/* status */}
      <WebbDividerSmall />
      <div className="back-color-wite p-2 px-3 rounded-xd">
        <div className="">
          <label className="form-label text-small">
            Status <FormNeeded />
          </label>
          

          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Active</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.active}
                onChange={() => handleCheckboxChange("active")}
              ></input>
            </div>
          </div>

          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Deleted</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.deleted}
                // onChange={() => handleCheckboxChange("deleted")}
              ></input>
            </div>
          </div>

          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">All</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.all}
                onChange={() => handleCheckboxChange("all")}
              ></input>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
