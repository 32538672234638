// dashboard shortcuts
import ActionCardMedium from "../webx/ctas-actioncard-md";

import { useState } from "react";


const list = (require("../../data.static/data-contract-actions.json")).data.filter(item => item.actv);

export default function ContractActionsModule() {
  
  const data = (list.filter(item => item.user.includes("in")));


  const [loading,setLoading] = useState(true)

  return (
  <>
    {data && data.map((item, i) => (
    
      <ActionCardMedium data={item} key={i} />    
    
    ))}
  </>
  )
}