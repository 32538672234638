import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import AssetsList, {
  AssetsUnitEdit,
  AssetsUnitLists,
  AssetsUnitsActive,
  AssetsUnitsBook,
  AssetsUnitsLive,
} from "../../services/srvc-assets-realm";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";
export default function AssetsUnitList() {
  const navigate = useNavigate();

  const { item } = useParams();
  const [loader, setLoader] = useState(true);
  const [unitItems, setUnitItems] = useState([]);
  const [name, setName] = useState("");
  const [unit, setUnit] = useState("");
  const [price, setPrice] = useState("");
  const [modalIndex, setModalIndex] = useState(null);

  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      var res = await AssetsUnitLists({
        data: {
          asset: item,
        },
      });
      setUnitItems(res?.data?.list);
      setLoader(false);
    };

    fetchData();
  }, [refresh]);

  const saveEditData = async (item) => {
    setLoader(true);
    var res = await AssetsUnitEdit({
      data: {
        item: item,
        name: name,
        rate: price,
        unitcount: unit,
      },
    });

    setName("");
    setPrice("");
    setUnit("");
    setLoader(false);

    if (res) {
      setRefresh(!refresh);
    }
  };

  const toggleLive = async (item, value) => {
    var res = await AssetsUnitsLive({
      data: {
        item: item,
        status: value,
      },
    });
    // res && setRefresh(!refresh);
  };
  const toggleBooking = async (item, value) => {
    var res = await AssetsUnitsBook({
      data: {
        item: item,
        status: value,
      },
    });
    // res && setRefresh(!refresh);
  };
  const toggleActive = async (item, value) => {
    var res = await AssetsUnitsActive({
      data: {
        item: item,
        status: value,
      },
    });
    // res && setRefresh(!refresh);
  };

  const handleCheckboxChange = (key, index, item) => {
    const updatedItems = [...unitItems];
    const itemToUpdate = updatedItems[index];

    if (key === "live") {
      itemToUpdate.status.live = !itemToUpdate.status.live;
      if (itemToUpdate.active == false) {
        itemToUpdate.active = !itemToUpdate.active;
      }

      toggleLive(item, itemToUpdate.status.live);
    } else if (key === "booking") {
      itemToUpdate.status.book = !itemToUpdate.status.book;
      if (itemToUpdate.active == false) {
        itemToUpdate.active = !itemToUpdate.active;
      }
      toggleBooking(item, itemToUpdate.status.book);
    } else if (key === "active") {
      itemToUpdate.active = !itemToUpdate.active;
      if (itemToUpdate.status.live == true) {
        itemToUpdate.status.live = !itemToUpdate.status.live;
      }
      if (itemToUpdate.status.book == true) {
        itemToUpdate.status.book = !itemToUpdate.status.book;
      }
      toggleActive(item, itemToUpdate.active);
    }
    setUnitItems(updatedItems);
  };

  const crossClick = () => {
    setRefresh(!refresh);
    setModalIndex(null); // Close the modal
  };

  // const handleInputChange = (index, key, value) => {
  //   const updatedItems = [...unitItems];
  //   updatedItems[index][key] = value;
  //   setUnitItems(updatedItems);
  // };
  const handleEditSave = (item) => {
    saveEditData(item);
    setModalIndex(null); // Close the modal
  };

  const handleDivClick = (item) => {
    // Redirect to the new page
    navigate(`/team/assets/unit/${item}`);
  };

  if (loader == true) {
    return <p>Please wait ....</p>;
  }
  return (
    <>
      <div className="border rounded">
        {!loader && unitItems.length === 0 ? (
          <p className="border rounded p-4 m-0">No Units found.....</p>
        ) : (
          <>
            {unitItems.map((item, index) => (
              <div key={index}>
                <div className="bg-white p-4" style={{ position: "relative" }}>
                  <div
                    className="d-flex gap-3 w-100"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDivClick(item.item)}
                  >
                    <div>
                      <p
                        className="m-0 text-primary"
                        style={{ fontWeight: "bold" }}
                      >
                        {item?.meta?.name || ""}{" "}
                      </p>
                      <p className="m-0 text-small">
                        Unit: {item?.units?.mint || ""} Sale:{" "}
                        {(
                          (item?.units?.sale / item?.units?.mint) *
                          100
                        ).toFixed(2) || ""}
                        %
                      </p>
                      <p className="m-0 text-small">
                        Rate: {item?.rate?.number / 1000000 || ""}{" "}
                        {item?.rate?.ticker?.toUpperCase() || ""}{" "}
                        </p>
                      <div className="d-flex gap-2">
                        <div
                          className="d-flex align-items-center gap-1 form-check form-switch m-0 p-0"
                          style={{ width: "60%" }}
                        >
                          <p className="m-0 p-0 text-small">Status</p>
                          <div
                            className={`${
                              item?.active === true ? "bg-success" : "bg-danger"
                            }`}
                            style={{
                              height: "8px",
                              width: "8px",
                              borderRadius: "50%",
                            }}
                          ></div>
                        </div>
                        <div
                          className="d-flex align-items-center gap-1 form-check form-switch m-0 p-0"
                          style={{ width: "60%" }}
                        >
                          <p className="m-0 p-0 text-small">Live</p>
                          <div
                            className={`${
                              item?.status?.live === true
                                ? "bg-success"
                                : "bg-danger"
                            }`}
                            style={{
                              height: "8px",
                              width: "8px",
                              borderRadius: "50%",
                            }}
                          ></div>
                        </div>
                        <div
                          className="d-flex align-items-center gap-1 form-check form-switch m-0 p-0"
                          style={{ width: "60%" }}
                        >
                          <p className="m-0 p-0 text-small">Book</p>
                          <div
                            className={`${
                              item?.status?.book === true
                                ? "bg-success"
                                : "bg-danger"
                            }`}
                            style={{
                              height: "8px",
                              width: "8px",
                              borderRadius: "50%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ position: "absolute", right: "24px", top: "24px" }}
                  >
                    <button
                      type="button"
                      className="rounded-xx btn btn-primary"
                      onClick={() => setModalIndex(index)} // Open the modal
                    >
                      Edit
                    </button>

                    {modalIndex === index && (
                      <div
                        className="modal fade show"
                        style={{ display: "block" }}
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h1 className="modal-title fs-5">Actions</h1>
                              <button
                                type="button"
                                className="btn-close"
                                onClick={crossClick}
                              ></button>
                            </div>
                            <div className="modal-body">
                              <div className="border back-color-wite p-2 px-3 rounded-xd">
                                <label className="form-label text-small">
                                  Status <FormNeeded />
                                </label>

                                <div className="d-flex form-check form-switch m-0 p-0 mb-2">
                                  <div>
                                    <p className="m-0 p-0">Active</p>
                                  </div>
                                  <div className="ms-auto">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={item?.active}
                                      style={{
                                        height: "1.2rem",
                                        width: "2rem",
                                      }}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          "active",
                                          index,
                                          item.item
                                        )
                                      }
                                    ></input>
                                  </div>
                                </div>
                              </div>
                              <WebbDividerSmall />
                              <div className="border back-color-wite p-2 px-3 rounded-xd ">
                                <div>
                                  <label className="form-label text-small">
                                    Actions <FormNeeded />
                                  </label>

                                  <div className="d-flex form-check form-switch m-0 p-0 mb-2">
                                    <div>
                                      <p className="m-0 p-0">Go Live</p>
                                    </div>
                                    <div className="ms-auto">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={item?.status?.live}
                                        style={{
                                          height: "1.2rem",
                                          width: "2rem",
                                        }}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            "live",
                                            index,
                                            item.item
                                          )
                                        }
                                      ></input>
                                    </div>
                                  </div>

                                  <div className="d-flex form-check form-switch m-0 p-0 mb-2">
                                    <div>
                                      <p className="m-0 p-0">Start Booking</p>
                                    </div>
                                    <div className="ms-auto">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={item?.status?.book}
                                        style={{
                                          height: "1.2rem",
                                          width: "2rem",
                                        }}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            "booking",
                                            index,
                                            item.item
                                          )
                                        }
                                      ></input>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <WebbDividerSmall />
                              <div className="border back-color-wite p-2 px-3 rounded-xd ">
                                <div>
                                  <label className="form-label text-small">
                                    Edit <FormNeeded />
                                  </label>
                                  <div className="d-flex ">
                                    <div className="w-100">
                                      <div>
                                        <label
                                          htmlFor="nameEdit"
                                          className="form-label"
                                        >
                                          Name
                                        </label>
                                      </div>
                                      <div className="d-flex justify-content-between gap-4 align-content-between">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id={`nameEdit${item.item}`}
                                          defaultValue={item?.meta?.name}
                                          onChange={(e) =>
                                            setName(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <WebbDividerSmall />
                                  <div className="d-flex ">
                                    <div className="w-100">
                                      <div>
                                        <label
                                          htmlFor="unitEdit"
                                          className="form-label"
                                        >
                                          Unit
                                        </label>
                                      </div>
                                      <div className="d-flex justify-content-between gap-4 align-content-between">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id={`unitEdit${item.item}`}
                                          defaultValue={item?.units?.mint}
                                          onChange={({ target }) => {
                                            const sanitizedValue =
                                              target.value.replace(
                                                /[^\d]/g,
                                                ""
                                              );
                                            setUnit(sanitizedValue);
                                          }}
                                          onKeyPress={(event) => {
                                            if (
                                              event.key === "." ||
                                              event.key === ","
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <WebbDividerSmall />

                                  <div className="d-flex ">
                                    <div className="w-100">
                                      <div>
                                        <label
                                          htmlFor="priceEdit"
                                          className="form-label"
                                        >
                                          Price
                                        </label>
                                      </div>
                                      <div className="d-flex justify-content-between w-100 gap-4 align-content-between">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id={`priceEdit${item.item}`}
                                          defaultValue={
                                            item?.rate?.number / 1000000
                                          }
                                          onChange={({ target }) => {
                                            const sanitizedValue =
                                              target.value.replace(
                                                /[^\d]/g,
                                                ""
                                              );
                                            setPrice(sanitizedValue);
                                          }}
                                          onKeyPress={(event) => {
                                            if (
                                              event.key === "." ||
                                              event.key === ","
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-end mt-4">
                                    <button
                                      type="button"
                                      className="rounded-xx btn btn-primary"
                                      style={{ zIndex: "900" }}
                                      onClick={() => handleEditSave(item?.item)}
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <WebbDividerSmall />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <hr className="m-0" />
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
}
