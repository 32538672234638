

import React from "react";

export function UserMedia({ type, media }) {
  return (
    <div className={type.text === "media" ? "" : "d-none"}>
      <h5 className="">Media</h5>
      <div className={media.length === 0 ? "" : "d-none"}>No Media Found</div>
    </div>
  );
}
