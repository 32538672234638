// auth - firebase mail link
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoaderMedium from "../webx/webb-loader-md";
import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import { DashboardAssetStatistics } from "../../services/srvc-assets-realm";
const array = [
  {
    name: "Total Units",
    memo: "",
    icon: "bx bx-sort-alt-2",
    number: "",
    ticker: "***",
    rate: "",
    active: true,
  },
  {
    name: "Active Units",
    memo: "",
    icon: 'bx bx-badge-check',    number: "",
    ticker: "***",
    rate: "",
    active: true,
  },
  {
    name: "Inactive Units",
    memo: "",
    icon: 'bx bx-block',    number: "",
    ticker: "***",
    rate: "",
    active: true,
  },
];

export default function TeamAssetUnitsStatisticsModule() {
  const navigate = useNavigate();
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState();

  // console.log(listactions.filter(x => x.user.includes(asset.role)))

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        var res = await DashboardAssetStatistics({ data: { user: asset.item } });

        // const result = listactions.filter(x => x.user.includes(asset.role))
        // setData(result)
        for (let i = 0; i < array.length; i++) {
          if (array[i]["name"].toLocaleLowerCase() === "total units") {
            // Update the object with new properties
            array[i] = { ...array[i], number: res?.data?.units?.total };
            // break; // Break the loop since we found the matching object
          }
          if (array[i]["name"].toLocaleLowerCase() === "active units") {
            // Update the object with new properties
            array[i] = { ...array[i], number: res?.data?.units?.active };
            // break; // Break the loop since we found the matching object
          }
          if (array[i]["name"].toLocaleLowerCase() === "inactive units") {
            // Update the object with new properties
            array[i] = { ...array[i], number: res?.data?.units?.inactive };
            // break; // Break the loop since we found the matching object
          }

        }

        // const statx = Array.from(list, x =>{ return {
        //   ...x,
        //   number: (Math.random()*1000).toFixed(0),
        //   rate: (Math.random()*10).toFixed(0)
        // }})
        setData(array);

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);

  const handleClick = (live, link) => {
    // if (live) navigate(`/${asset.role}/${link}`)
  };

  if (loader)
    return (
      <div className="text-center">
        <div className="spinner-border text-secondary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  return (
    <>
      <div className="">
        <div className="row row-cols-3 row-cols-md-3 g-2">
          {data &&
            data.map((item, i) =>
              item.active ? (
                <div className="col" key={i}>
                  <div
                    className={`back-color-wite rounded-xd p-3 w-100 h-100 border m-0 p-0`}
                  >
                    <div className="d-flex">
                      <div className="">
                        <p className="text-small m-0">{item?.name}</p>
                        <p className="text-lead text-bold m-0">
                          {item.number || "0"}
                        </p>
                      </div>
                      <div className="ms-auto ">
                        <i
                          className={`text-icon-md text-color-tint ${item?.icon}`}
                        ></i>
                      </div>
                    </div>

                    <div className="mb-2"></div>

                    

                    {/* <p className="m-0">
              <span><i className="bx bx-up-arrow-alt text-color-success"></i></span>
              <span>{item.rate || '0'}{'%'}</span>
            </p> */}
                  </div>
                </div>
              ) : (
                ""
              )
            )}
        </div>
      </div>
    </>
  );
}
