// documents
import { useEffect, useState } from "react";
import {  useNavigate, useParams } from 'react-router-dom';



// import { ftos } from "../../services/srvc-utilities";
import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbDividerSmall from "../webx/webb-divider-sm";

import WebbDividerMedium from "../webx/webb-divider-md";
import FormNeeded from "../webx/form-needed";
// import { DocumentCreate } from "../../services/srvc-media-docs-realm";

import { GetUserForm, toBase64 } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import FileSaveFirebase from "../../services/filesave-fbsx";

const list = (require("../../data.static/data-select-vault.json")).data.filter(item => item.actv);

export default function DocumentsAddNewFileModule() {

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;  
  
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading]=useState(true);
  const [form, setForm] = useState(false);

  const [memo, setMemo] = useState("");

  const [base64, setBase64]=useState()
  const [link, setLink]=useState()

  const [data, setData] = useState({    
    name: '',
    memo: '',
    nmbr: '24'+Date.now().toString(),
    form: '',
  })


  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        
        // // basic info
        // var res = (await GetDocumentInfo(JSON.stringify({
        //   data: { document: id },
        //   user: asset.user,
        //   webb: process.env.REACT_APP_WEBB_SITE_NMBR
        // }))).data

        // // console.log(res)
        // setInfo( res );

        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.usid]);


  // form validation
  useEffect( () => {
    setForm(false)
    if (base64 && base64 !=='') setForm(true);
  },[base64]);

  const SetFileLink = async(link) => {
    // console.log (link);
    setLink(link)
    // handleChange("link", link);
  }

  const SetFileData = async(data) => {
    var str1=await toBase64(data);
    var str2=str1.split('base64')
    var b64=str2[1].split(",")
    //  console.log(b64[1])
    setBase64(str1)
    //handleChange("file", base64);
  }

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 
    setMemo("Please wait...")
    var datx = {
      user:{ name:asset.name, mail:asset.mail, item:asset.item },
      name:data.name,
      number: data.nmbr,
      format:data.form,
      sort:'file',
      file:base64
    }
    // const res = await DocumentCreate({data: datx, srvc: ''})
    // // console.log(res)
    // if(res.stat)
    // {
    //   setMemo("Document Saved.")
    //   setTimeout(()=>{
    //     navigate("/user/vault/codocs")
    //   },1000)
    // }
    // else
    // {
    //   setMemo("Error While Saving.")
    // }
    

    // const res = await SetDocumentFiles (JSON.stringify({
    //   data: { document: id, link: "", file: base64, mode: 'file', sort: 'init', kycu:kycu, kycb:kycb },
    //   user: asset.user,
    //   webb: process.env.REACT_APP_WEBB_SITE_NMBR
    // }))
    // // console.log (res)
    // setLoading(false);
    // history.push(`/${asset.form.substring(0,2)}/smart/view/${info.smart_id}`)
  
  }




  //if (loading){ return ( <> <WebbLoaderSmall /> </> ) }

  return (
  <div className="p-4">
    {/* info */}
    {/* <WebbDividerSmall />
    <p className="text-lead text-bold">{info.name}</p>
    <p className="text-color-tone">{id}</p>
    <WebbDividerSmall /> */}

    <div>

        <div className="form-group mb-3">
            <label className="form-label small">Document Type <FormNeeded/></label>
            <select 
              className="form-select rounded-none"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.form}
              onChange={({ target }) => handleChange("form", target.value)}
            >
              <option value=''>Select Document Type</option>
              {list.map((item,i) => (
                <option key={i} value={item.link}>{item.name}</option>
              ))}
            </select>
        </div>

        <div className="form-group mb-3 ">
            <label className="form-label small">Document Name <FormNeeded/></label>
            <input type="text" className="form-control rounded-none"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.name}
              onChange={({ target }) => {handleChange("name", target.value);}}
              placeholder="Document Name">
            </input>
        </div>

        <div className="mb-3">
            <label className="form-label small">File Attachment (PDF) <span className="text-danger">*</span></label>
            <FileSaveFirebase name={id} link = {SetFileLink} file={SetFileData}/>    
        </div>

        <div className="form-group mb-3">
          <label className="form-label small">Description (optional)</label>
          <textarea 
              className="form-control rounded-none" 
              style={{fontSize:'0.9rem'}}
              rows="3"
              value={data.memo}
              onChange={({ target }) => {handleChange("memo", target.value);}}
              placeholder="Description">  
          </textarea>
        </div>


    </div>
   
    <WebbDividerMedium />

    <p className="text-small text-warning">{memo}</p>

    {/* form */}
    <form onSubmit={handleSubmit}>

      <div className="">
        <div className="d-grid">
          <button style={{width:'auto'}} className={`btn height-md btn-primary back-color-main rounded-none`}
            disabled={!form || loading}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>

    </form>
  
    <WebbDividerMedium />
    <WebbDividerMedium />
  </div>
  )
}