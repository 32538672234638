import React from "react";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  UserAccountDetails,
  UserActivate,
} from "../../services/srvc-users-realm";
import { UsersTransferList } from "../../services/srvc-transfers-realm";
import TotalTransaction from "../webx/transfers-list";
import userDocuments, { UserDocuments } from "../webx/user-documents";
import userOverview from "../webx/user-overview";
import UserOverview from "../webx/user-overview";
import { UserMedia } from "../webx/user-media";
import { UserTransfers } from "../webx/user-transfers";
import { UserChats } from "../webx/user-chats";
import UserContacts from "../webx/user-contacts";
import UserAccounts from "../webx/user-accounts";
import { CreatorAccountDetails } from "../../services/srvc-creator-realm";
export default function NetworkUserDetail(props) {
  const { item, accounttype } = useParams();
  const type = props.type;

  const [loader, setLoader] = useState(true);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentState, setCurrentState] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [contact, setContacts] = useState([]);
  const [chats, setChats] = useState([]);
  const [transfers, setTransfers] = useState(null);
  const [media, setMedia] = useState([]);
  const [documents, setDocuments] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [totalItems, setTotalItems] = useState("");

  const transactionsRef = useRef(null);

  const fetchData = async () => {
    var res = await (accounttype=="user"?UserAccountDetails:CreatorAccountDetails) ({
      data: { user: item },
      srvc: "******",
    });
    if(res.data){
      setCurrentItems(() => res.data);
      setCurrentState(() => res.data.active);
    }
   

    var trxnlist = await UsersTransferList({
      data: { user: item, status: "", index: currentPage, items: itemsPerPage },
    });
    if (res.stat) {
      setTransfers(trxnlist.data);
      setTotalItems(trxnlist?.data?.count);
    }

    setLoader(false);
  };

  useEffect(() => {}, []);

  useEffect(() => {
    setLoader(true);

    fetchData();
  }, [props.refresh,currentPage]);

  const handleTransactionsClick = () => {
    // Step 3: Use scrollIntoView to scroll to the Transactions section
    transactionsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    //navigate(`/team/transfers?page=${page}`);
  };
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  if (loader == true) {
    return <p>Please wait ....</p>;
  }
  if (!loader && currentItems.length == 0) {
    return <p>No items found</p>;
  }

  return (
    <div className="my-2">
      <UserOverview
        type={type}
        currentItems={currentItems}
        currentState={currentState}
        accounttype={accounttype}
      />

      <UserAccounts type={type} item={item} accounttype={accounttype} />

      <UserContacts type={type} contact={contact} accounttype={accounttype} />

      <UserChats type={type} chats={chats} accounttype={accounttype} />

      <UserTransfers
        type={type}
        transfers={transfers}
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        accounttype={accounttype}
      />

      <UserMedia type={type} media={media} accounttype={accounttype} />

      <UserDocuments
        type={type}
        documents={documents}
        item={item}
        accounttype={accounttype}
      />
    </div>
  );
}
